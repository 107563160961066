<app-preloader *ngIf="isLoading"></app-preloader>
<app-notifications></app-notifications>

<div class="module insurance" *ngIf="showInsurance">
  <div class="title">
    <div>
      <h3>Seguro</h3>
      <div class="actions">
        <a class="delete" tp="Eliminar seguro" (click)="deleteInsurance()"
          >Eliminar</a
        >
      </div>
    </div>
  </div>
  <form action="" [formGroup]="formInsurance ? formInsurance : form">
    <div class="wrapperG5">
      <div class="selectWrapper">
        <label for="">Tipo de seguro<span class="required">*</span></label>
        <div class="customSelect">
          <select name="" id="" formControlName="insuranceType">
            <option
              *ngFor="let insurance of insuranceList"
              [value]="insurance.value"
            >
              {{ insurance.description }}
            </option>
          </select>
        </div>
      </div>
      <div class="selectWrapper">
        <label for="">Estado<span class="required">*</span></label>
        <div class="customSelect">
          <select
            name=""
            id=""
            formControlName="state"
            (ngModelChange)="getZipCode()"
          >
            <option *ngFor="let state of states" [value]="state.stateId">
              {{ state.state }}
            </option>
          </select>
        </div>
      </div>
      <div class="selectWrapper">
        <!-- (ngModelChange)="getUdis()" -->
        <label for="">Uso<span class="required">*</span></label>
        <div class="customSelect">
          <select name="" id="" formControlName="vehicleServiceType">
            <option *ngFor="let use of useList" [value]="use.value">
              {{ use.description }}
            </option>
          </select>
        </div>
      </div>

      <ng-container *ngIf="models?.modelType == modelEnum.autobuses">
        <div
          class="inputWrapper"
          style="overflow: visible"
          *ngIf="formInsurance"
        >
          <label for="">Código postal</label>
          <app-input-search
            [selectedModel]="formInsurance.controls['postalCode'].value"
            [options]="postalCodeList"
            placeholder="Buscar código postal"
            (searchSelected)="selectedPostalCode($event)"
          >
          </app-input-search>
        </div>
        <div class="inputWrapper">
          <label for=""
            >Cantidad de pasajeros<span class="required">*</span></label
          >
          <input
            type="number"
            id="quantityPassengers"
            (blur)="calculatePassenger($event)"
            formControlName="quantityPassengers"
            class="size1"
          />
          <div class="rango">
            <span class="note"
              >Min {{ minPassenger }}<br />Max {{ maxPassenger }}</span
            >
          </div>
        </div>
      </ng-container>

      <div class="selectWrapper" *ngIf="models?.modelType == modelEnum.tractos">
        <label for="">Tipo de carga</label>
        <div class="customSelect size1">
          <select name="" id="" formControlName="loadType">
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>
        </div>
      </div>
      <div class="inputWrapper" *ngIf="models?.modelType == modelEnum.tractos">
        <label for="">Número de remolques</label>
        <div class="wrapperRemolques">
          <input
            type="number"
            id="quantityTrailes"
            formControlName="quantityTrailes"
            (blur)="onChangeQuantity()"
            class="size1"
          />
          <div class="rango">
            <span class="note"
              >Min {{ minTrailers }}<br />Max {{ maxTrailers }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="actionsWrapper">
    <button
      (click)="openInsuranceFlexModal()"
      class="secondary"
      [disabled]="!isValidInsurance"
    >
      Cotizar seguro
    </button>
  </div>
</div>
<button
  class="tertiary addInsurance"
  *ngIf="!showInsurance"
  (click)="addInsurance()"
  [disabled]="!isValid"
>
  Agregar seguro
</button>
