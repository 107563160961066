import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-template-confirmation',
  templateUrl: './template-confirmation.component.html',
  styleUrls: ['./template-confirmation.component.css'],
})
export class TemplateConfirmationComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() btn1: string;
  @Input() btn2: string;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() actionBtn2: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  close() {
    this.closeModal.emit();
  }

  onSubmit() {
    this.actionBtn2.emit();
  }
}
