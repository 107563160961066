<div *ngIf="!hasData" class="noData">
  No hay información para mostrar
</div>
<div [ngClass]="role != rolesInternal.administrator ? 'customTableMobile circularesMkt' : 'customTableMobile circularesMkt admin'">

  <div *ngFor="let item of listMailshot" [ngClass]="getTableRowClass(item)">
    <div class="tituloYAcciones">
      <div class="titulo">
        {{item.name}}
      </div>
      <div class="acciones">
        <a href="#"><i class="icon"></i></a>
        <a [routerLink]="['/mailshot/admin-mailshot/details/'+item.id]" *authPermission="[];role:[rolesInternal.administrator]; user: [userType.internal]; module: module">
          <i class="icon customIcon eye">
            <img src="/assets/images/icons/eye.svg" class="normal">
            <img src="/assets/images/icons/eye_hover.svg" class="hover">
          </i>
        </a>
      </div>
    </div>

    <div class="wrapper2G">
      <div class="nombre">Destinatarios</div>
      <div class="destinatarios">{{item.recipients}}</div>
    </div>
    <div class="wrapper2G">
      <div class="nombre">Descargas</div>
      <div class="descargas">{{item.downloads}}</div>
    </div>
    <div class="wrapper2G">
      <div class="nombre">Pendientes</div>
      <div class="pendientes">{{item.downloadPending}}</div>
    </div>

    <div class="wrapper2G">
      <div class="adjunto">
        <a (click)="downloadEmit(item)"><i class="icon"></i><span class="archivos">{{getType(item.mimeType)}} ({{item.fileSize | fileSize}})</span></a>
      </div>
      <div class="fecha">{{ format(item.date)}}</div>
    </div>
  </div>
</div>