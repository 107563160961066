import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
})
export class SearchBarComponent {
  @Input() text: string = '';
  @Input() searchFunction: any;
  @Input() resetTable: any;
  searchText: string = '';

  onSearchTextChange(): void {
    if (this.searchText.length >= 4) {
      this.searchFunction(this.searchText);
    } else if (this.searchText.length == 0) {
      this.resetTable();
    }
  }
}
