import { Component, ElementRef, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@app/components/base/base.component';
import { MailshotService } from '../mailshot.service';
import { EntitiesServices } from '@app/services/entities.service';
import { NotificationService } from '@app/services/notifications.service';
import { ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-modal-mailshot',
  templateUrl: './modal-mailshot.component.html',
  styleUrls: ['./modal-mailshot.component.css'],
})
export class ModalMailshotComponent extends BaseComponent implements OnInit {
  form: FormGroup;

  isLoading: boolean = false;
  isEnabled: boolean = false;
  file: any;

  mailshotId = '';
  selectedOptionsDealers: any[] = [];
  selectedOptionsInternals: any[] = [];

  @ViewChild('dealersInput') dealersInput: ElementRef<HTMLInputElement>;
  @ViewChild('internalsInput') internalsInput: ElementRef<HTMLInputElement>;

  constructor(
    public dialogRef: MatDialogRef<ModalMailshotComponent>,
    private fb: FormBuilder,
    private mailshotService: MailshotService,
    private notificationsService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      date: [new Date(), [Validators.required]],
      fileName: ['', [Validators.required]],
      mimeType: ['', [Validators.required]],
      fileSize: ['', [Validators.required]],
    });

    this.form.valueChanges.subscribe(() => {
      this.enableSave();
    });
  }

  enableSave() {
    this.isEnabled = this.isEnabled =
      this.form.valid &&
      (this.selectedOptionsDealers.length != 0 ||
        this.selectedOptionsInternals.length != 0);
  }

  remove(profile: string, type: string): void {
    if (type == 'dealers') {
      const index = this.selectedOptionsDealers.indexOf(profile);

      if (index >= 0) {
        this.selectedOptionsDealers.splice(index, 1);
      }
    }

    if (type == 'internals') {
      const index = this.selectedOptionsInternals.indexOf(profile);

      if (index >= 0) {
        this.selectedOptionsInternals.splice(index, 1);
      }
    }
    this.enableSave();
  }

  selectedDealers(event: MatAutocompleteSelectedEvent): void {
    let isSelectedAll = this.selectedOptionsDealers.some(
      (profile) => profile.description == 'Todos'
    );
    let isSelected = this.selectedOptionsDealers.some(
      (profile) => profile.id == event.option.value.id
    );

    if (isSelectedAll && event.option.viewValue != 'Todos') {
      this.selectedOptionsDealers = [];
      this.selectedOptionsDealers.push(event.option.value);
    } else if (!isSelectedAll && event.option.viewValue == 'Todos') {
      this.selectedOptionsDealers = [];
      this.selectedOptionsDealers.push({ description: 'Todos' });
    } else if (
      !isSelectedAll &&
      event.option.viewValue != 'Todos' &&
      !isSelected
    ) {
      this.selectedOptionsDealers.push(event.option.value);
    }
    this.dealersInput.nativeElement.value = '';
    this.enableSave();
  }

  selectedInernals(event: MatAutocompleteSelectedEvent): void {
    let isSelectedAll = this.selectedOptionsInternals.some(
      (profile) => profile.description == 'Todos'
    );
    let isSelected = this.selectedOptionsInternals.some(
      (profile) => profile.id == event.option.value.id
    );

    if (isSelectedAll && event.option.viewValue != 'Todos') {
      this.selectedOptionsInternals = [];
      this.selectedOptionsInternals.push(event.option.value);
    } else if (!isSelectedAll && event.option.viewValue == 'Todos') {
      this.selectedOptionsInternals = [];
      this.selectedOptionsInternals.push({ description: 'Todos' });
    } else if (
      !isSelectedAll &&
      event.option.viewValue != 'Todos' &&
      !isSelected
    ) {
      this.selectedOptionsInternals.push(event.option.value);
    }
    this.internalsInput.nativeElement.value = '';
    this.enableSave();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  getSelectedProfiles() {
    let dealers = this.selectedOptionsDealers;
    let internals = this.selectedOptionsInternals;

    if (dealers.length == 1 && dealers[0].description == 'Todos') {
      dealers = this.data.dealers.map(
        (i: { profileCode: any; userType: any }) => {
          return { profileCode: i.profileCode, userType: i.userType };
        }
      );
    } else if (dealers) {
      dealers = this.selectedOptionsDealers.map((i) => {
        return { profileCode: i.profileCode, userType: i.userType };
      });
    }

    if (internals.length == 1 && internals[0].description == 'Todos') {
      internals = this.data.internals.map(
        (i: { profileCode: any; userType: any }) => {
          return { profileCode: i.profileCode, userType: i.userType };
        }
      );
    } else if (internals) {
      internals = this.selectedOptionsInternals.map((i) => {
        return { profileCode: i.profileCode, userType: i.userType };
      });
    }

    if (dealers && internals) {
      return dealers.concat(internals);
    } else if (dealers && !internals) {
      return dealers;
    } else if (!dealers && internals) {
      return internals;
    }
    return;
  }

  getModel() {
    let model = {
      name: this.form.controls['name'].value,
      date: this.form.controls['date'].value,
      profiles: this.getSelectedProfiles(),
      mimeType: this.form.controls['mimeType'].value,
      fileSize: this.form.controls['fileSize'].value,
      profileModule: 'ADMINISTRATOR',
    };
    return model;
  }

  save() {
    this.isLoading = true;
    let data = this.getModel();
    if (this.validForm(this.form)) {
      this.mailshotService.createMailshot(data).subscribe({
        next: (r) => {
          this.isLoading = false;
          this.mailshotId = r.data;
          this.createAttachment();
        },
        error: (error) => {
          this.isLoading = false;
          this.notificationsService.showMessage(
            ['Ha ocurrido un error. Vuelva a intentarlo.'],
            'error'
          );
        },
      });
    } else {
      this.isLoading = false;
      this.notificationsService.showMessage(
        ['Revisa los campos obligatorios antes de continuar.'],
        'error'
      );
    }
  }

  createAttachment() {
    this.isLoading = true;

    let file = this.createFormData();

    this.mailshotService.createAttachment(file).subscribe({
      next: (r: any) => {
        this.sendNotifications();
        this.isLoading = false;
        this.notificationsService.showMessage(
          ['La circular se creado correctamente.'],
          'success'
        );
        setTimeout(() => {
          this.notificationsService.clearMessages();
          this.onClose();
        }, 2000);
      },
      error: (error: any) => {
        this.isLoading = false;
        this.deleteMailshot();
        this.notificationsService.showMessage(
          ['Ha ocurrido un error al subir el archivo. Vuelva a intentarlo.'],
          'error'
        );
        setTimeout(() => {
          this.notificationsService.clearMessages();
        }, 6000);
      },
    });
  }

  deleteMailshot() {
    this.mailshotService.deleteMailshot(this.mailshotId).subscribe({
      next: (response: any) => {},
      error: (error: any) => {
        this.notificationsService.showMessage(
          [
            'Ha ocurrido un error al eliminar la circular. Vuelva a intentarlo.',
          ],
          'error'
        );
      },
    });
  }

  sendNotifications() {
    let data = {
      fileSize: this.file.size,
      profiles: this.getSelectedProfiles(),
    };

    this.mailshotService.sendNotifications(data, this.mailshotId).subscribe({
      next: (next: any) => {},
      error: (error: any) => {
        this.notificationsService.showMessage(
          [
            'Ha ocurrido un error al enviar notificaciones. Vuelva a intentarlo.',
          ],
          'error'
        );
      },
    });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    const input = event.target as HTMLInputElement;

    let maxFileSize = 10 * 1024 * 1024; // 10 MB en bytes

    if (file) {
      if (file.size <= maxFileSize) {
        this.form.controls['mimeType'].setValue(file.type);
        this.form.controls['fileSize'].setValue(file.size);
        this.form.controls['fileName'].setValue(file.name);
        this.file = file;
      } else {
        input.value = '';
        this.notificationsService.showMessage(
          ['El archivo supera el tamaño máximo permitido (10 MB).'],
          'error'
        );
        setTimeout(() => {
          this.notificationsService.clearMessages();
        }, 5000);
      }
    } else {
      this.form.controls['mimeType'].setValue('');
      this.form.controls['fileSize'].setValue('');
      this.form.controls['fileName'].setValue('');
    }
  }

  createFormData() {
    let formData = new FormData();
    formData.append('originalName', this.file.name);
    formData.append('mimeType', this.file.type);
    formData.append('elementId', this.mailshotId);
    formData.append('typeFile', 'MAIL_SHOT');
    formData.append('fileSize', this.file.size);
    formData.append('file', this.file);
    return formData;
  }
}
