// import { FormControl } from '@angular/forms';

// export function emailDomainValidator(control: FormControl) {
//   const email = control.value;
//   if (email && /@(gmail|hotmail|yahoo)\.com$/i.test(email)) {
//     return { forbiddenEmail: true };
//   }
//   return null;
// }

import { AbstractControl, ValidationErrors } from '@angular/forms';

export function emailDomainValidator(
  control: AbstractControl
): ValidationErrors | null {
  const email: string = control.value;
  const domain = email.substring(email.lastIndexOf('@') + 1).toLowerCase();
  const invalidDomains = [
    'gmail.com',
    'hotmail.com',
    'yahoo.com',
    'outlook.com',
    'live.com',
  ];
  if (invalidDomains.includes(domain)) {
    return { emailDomain: true };
  }
  return null;
}
