import { Injectable } from '@angular/core';
import { Permission } from '../models/permissions.enum';
import { Router } from '@angular/router';
import { AuthService } from '../common/auth.service';
import { PermissionModel } from '../directives/permission.model';
import { UserType } from '../models/roles.enum';
import { rolesInternalDto } from '../components/menu/models/menus.model';

@Injectable()
export class PermissionService {
  constructor(private authService: AuthService, private router: Router) {}

  permission: PermissionModel[] = [
    //#region Users
    {
      url: new RegExp('users/admin-users/edit-users/[1-9](\\d*)'),
      permissions: [Permission.usuarios1],
    },
    {
      url: new RegExp('users/admin-users'),
      permissions: [Permission.usuarios1],
    },
    //#endregion

    //#region Profile
    {
      url: new RegExp('profile/admin-profiles/edit-profile/[1-9](\\d*)'),
      permissions: [Permission.perfiles1],
    },
    {
      url: new RegExp('profile/admin-profiles/new-profile'),
      permissions: [Permission.perfiles1],
    },
    {
      url: new RegExp('profile/admin-profiles'),
      permissions: [Permission.perfiles1],
    },
    {
      url: new RegExp('profile/my-profile'),
      permissions: [],
    },
    //#endregion
    {
      url: new RegExp('notifications'),
      permissions: [],
    },
    {
      url: new RegExp('notifications/details/[1-9](\\d*)'),
      permissions: [],
    },
    //#region Plan piso
    {
      url: new RegExp('wholesale'),
      permissions: [],
    },
    {
      url: new RegExp('wholesale/transfers'),
      permissions: [],
    },
    //#endregion

    //#region Mailshot
    {
      url: new RegExp('mailshot/admin-mailshot/details/[1-9](\\d*)'),
      permissions: [Permission.circulares0],
    },
    {
      url: new RegExp('mailshot/admin-mailshot'),
      permissions: [Permission.circulares0],
    },

    //#endregion

    //#region Quote

    {
      url: new RegExp('quote/new-open-quote'),
      permissions: [Permission.cotizador0],
    },
    {
      url: new RegExp('quote/edit-quote-open/[1-9](\\d*)'),
      permissions: [Permission.cotizador0],
    },
    {
      url: new RegExp('quote/open-quote'),
      permissions: [Permission.cotizadorAbierto],
    },
    {
      url: new RegExp('quote/standard-quote'),
      permissions: [Permission.cotizadorStandard],
    },
    {
      url: new RegExp('quote/standard-quote/new-quote'),
      permissions: [Permission.cotizadorStandard],
    },
    {
      url: new RegExp('standard-quote/edit-quote/[1-9](\\d*)'),
      permissions: [Permission.cotizadorStandard],
    },
    {
      url: new RegExp('standard-quote/new-quote/new-deal'),
      permissions: [Permission.cotizadorStandard],
    },
    {
      url: new RegExp('standard-quote/new-quote/edit-deal/[1-9](\\d*)'),
      permissions: [Permission.cotizadorStandard],
    },
    {
      url: new RegExp(
        'standard-quote/edit-quote/[1-9](\\d*)/edit-deal/[1-9](\\d*)'
      ),
      permissions: [Permission.cotizadorStandard],
    },
    {
      url: new RegExp('standard-quote/edit-quote/[1-9](\\d*)/new-deal'),
      permissions: [Permission.cotizadorStandard],
    },
    //#endregion
  ];

  /*Metodo de validacion de que este logueado y que tenga permisos para acceder a la url*/

  public hasPermission(url: string) {
    if (!this.authService.currentUser) {
      return this.router.navigate(['/']);
    }

    let permissions = this.authService.currentPermissions;

    let hasPermission = this.checkPermissions(url, permissions);
   
    if (!hasPermission&&!this.authService.authDealers()) {
      this.router.navigate(['/home']);
    }

    return (hasPermission||this.authService.authDealers());
  }

  /*Metodo para validar si el permiso requerido para ingresar a la url esta otorgado al usuario */
  private checkPermissions(url: string, permissions: Permission[]): boolean {
    let permissionUrl = this.permission.find((p) =>
      p.url.test(url)
    )?.permissions;
    if (!permissionUrl) return false;

    return permissions.findIndex((i) => permissionUrl?.includes(i)) !== -1;
  }

  /*Metodo para validar si el permiso requerido esta otorgado al usuario */
  public validatePermissionKey(permissionKey: Permission[]): boolean {
    if (!permissionKey) return false;

    let userPerms = this.authService.currentPermissions;

    let valid =
      userPerms && permissionKey.findIndex((i) => userPerms.includes(i)) !== -1;

    return valid;
  }

  public validateUserAndRoles(
    user: any,
    roles: any[],
    module?: string
  ): boolean {
    if (!roles || !user) return false;

    let userType = this.authService.currentUserType; //tipo de usuario logueado

    let rolesDealer = this.authService.currentRoles; //roles del usuario logueado

    let modulesInternal = this.authService.currentModule; //modulos de usuario interno

    if (!userType) return false;

    if (userType == UserType.dealer) {
      //en caso de ser usuario distribuidor

      let valid =
        rolesDealer &&
        roles.findIndex((i: any) => rolesDealer.includes(i)) !== -1;

      return valid;
    }

    if (userType == UserType.internal) {
      //en caso de ser usuario interno
      const found = modulesInternal.some((i: rolesInternalDto) => {
        if (i.modulo === module) {
          const valid = roles.some((r: any) => r === i.role);
          return valid;
        }
        return false;
      });
      return found;
    }

    return false;
  }
}