import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../profile/services/profile.service';
import { InternalNotificationsService } from '../services/internal-notificacions.service';
import { BaseComponent } from '@app/components/base/base.component';
import { MenusServices } from '@app/services/menus.service';
import { queryParamsNotifications } from '../models/notifications.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/common/auth.service';

@Component({
  selector: 'app-view-internal-notifications',
  templateUrl: './view-internal-notifications.component.html',
  styleUrls: ['../internal-notifications.css'],
})
export class ViewInternalNotificationsComponent
  extends BaseComponent
  implements OnInit
{
  isLoading: boolean = false;
  id: any;
  message!: string;
  title!: string;
  notifications: any[] = [];
  attachments: any[] = [];
  creationDate: string = '';
  currentIndex!: number;
  userId: number = 0;

  params: queryParamsNotifications = this.authService.getCurrentPaginators;


  constructor(
    private location: Location,
    private router: Router,
    private notificationService: InternalNotificationsService,
    private routes: ActivatedRoute,
    route: ActivatedRoute,
    menuService: MenusServices,
    private authService: AuthService,

  ) {
    super(route, menuService);
    this.id = this.routes.snapshot.paramMap.get('id'); 
  }

  ngOnInit(): void {
    this.sendDataToMenu();
    this.getNotificationById();
  }

  getNotificationById(){
    this.isLoading = true;
    this.notificationService.getNotificationById(this.id).subscribe({
      next: (response) => {
        this.message = response.data.message;
        this.title = response.data.title;
        this.creationDate = response.data.creationDate;
        this.attachments = response.data.attachments;
        this.userId = response.data.userId
        this.isLoading = false;
        this.getNotifications();
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  getNotifications(){
    this.isLoading = true;
    this.notificationService.listNotificationsUser(this.params, this.userId).subscribe({
      next: (response) => {
        this.notifications = response.data;
        this.currentIndex = this.notifications.findIndex(
          (notification) => notification.notificationId == this.id
        );
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  navigateToPrevious() {
    const model = {
      list: [
        {
          notificationId: this.id,
          seenByUser: true,
        },
      ],
    };
    this.notificationService.markAsSeen(model).subscribe({
      next: (response) => {},
      error: (error) => {},
    });
    if (this.currentIndex >= 1) {
      this.currentIndex--;
      const previousNotification =
        this.notifications[this.currentIndex].notificationId;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([`notifications/details/${previousNotification}`]);
      });
    }
  }

  // Navegar a la notificación siguiente
  navigateToNext() {
    const model = {
      list: [
        {
          notificationId: this.id,
          seenByUser: true,
        },
      ],
    };
    this.notificationService.markAsSeen(model).subscribe({
      next: (response) => {},
      error: (error) => {},
    });
    if (this.currentIndex < this.notifications.length - 1) {
      this.currentIndex++;
      const nextNotification =
        this.notifications[this.currentIndex].notificationId;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([`notifications/details/${nextNotification}`]);
      });
    }
  }

  goBack() {
    this.location.back();
  }

  transformMessageToLink(message: string) {
    const urlRegex = /https?:\/\/\S+/;
    const urlMatch = message.match(urlRegex);
  
    if (urlMatch) {
      const url = urlMatch[0];
      const messageWithLink = message.replace(urlRegex, `<a href="${url}">${url}</a>`);
      return messageWithLink;
    } else {
      return message;
    }
  }
}
