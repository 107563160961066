<app-preloader *ngIf="this.isLoading"></app-preloader>
<app-notifications></app-notifications>
<main class="main" id="notificacionesInternas">
  <!-- INTRO -->
  <app-img-header title="Notificaciones" alt="Daimler Truck" image="/assets/images/notificaciones/intro.jpg" [contentTemplate]="template"></app-img-header>
  <ng-template #template>
    <div class="wrapperG3">
      <app-search-bar text="Buscar" [searchFunction]="onSearchTextChange" [resetTable]="onSearchReset">
      </app-search-bar>
    </div>
  </ng-template>
  <!-- LISTADO -->
  <div id="detail">
    <!-- LISTADO -->
    <div class="pageWidth">
      <label class="checkboxWrapper emailNotifications" for="emailNotifications">
        <input type="checkbox" id="emailNotifications" [(ngModel)]="this.enabledNotificacions" (change)="onCheckEmailNotificationChange($event)" />
        <span class="checkmark"></span>
        <span class="description">Quiero recibir las notificaciones en mi email</span>
      </label>

      <!-- Tabla para listado de notificaciones -->
      <div class="customTable listadoNotificaciones">
        <div class="header">
          <div class="cell seleccion">
            <label class="checkboxWrapper" for="allItems">
              <input type="checkbox" id="allItems" [(ngModel)]="selectAllItems" (click)="selectAllRows()" />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="cell fecha">Fecha</div>
          <div class="cell asunto">Asunto</div>
          <div class="cell mensaje">Mensaje</div>
          <div class="cell adjunto">Adjunto</div>
        </div>

        <!-- Fila real, descomentar cuando funcione el microservicio-->
        <div *ngFor="let item of notifications; let i = index" [ngClass]="item.seenByUser ? 'row' : 'row unread'">
          <div class="cell seleccion" (click)="selectRow(item.notificationId)">
            <label class="checkboxWrapper" for="item{{ i }}">
              <input type="checkbox" id="item{{ i }}" name="item" [(ngModel)]="item.selected" (change)="onCheckboxChange(item)" />
              <span class="checkmark"></span>
            </label>
          </div>
          <div (click)="selectRow(i); viewDetails(item.notificationId)" class="cell fecha">
            <a>{{ format(item.creationDate)}}</a>
          </div>
          <div (click)="selectRow(i); viewDetails(item.notificationId)" class="cell asunto">
            <a>{{ item.title }}</a>
          </div>
          <div (click)="selectRow(i); viewDetails(item.notificationId)" class="cell mensaje">
            <a>{{ item.message }}</a>
          </div>
          <div (click)="selectRow(i); viewDetails(item.notificationId)" class="cell adjunto">
            <a *ngIf="item.attachments && item.attachments.length > 0"><i class="icon"></i><span class="archivos">Ver adjuntos</span></a>
          </div>
        </div>
      </div>

      <!-- <div class="hsGuide"><span>Tabla Mobile Listado de Notificaciones</span></div> -->
      <!-- div.mobileWrapper auxiliar para testear tabla mobile -->
      <!-- <div class="mobileWrapper"> -->
      <!-- Tabla Mobile para Listado de Notificaciones -->
      <!-- <div class="customTableMobile listadoNotificaciones"> -->
      <!-- Fila con datos -->
      <!-- <div class="row">
            <div class="wrapper2G acciones">
              <div class="check">
                <label class="checkboxWrapper" for="item1">
                  <input type="checkbox" id="item1" />
                  <span class="checkmark"></span>
                  <span class="description">Seleccionar</span>
                </label>
              </div>
              <div>
                <a href="#"><i class="icon"></i></a>
              </div>
            </div>

            <div class="wrapper2G">
              <div class="nombre">Fecha</div>
              <div class="Fecha">6/14/23, 1:20 AM</div>
            </div>
            <div class="wrapper2G">
              <div class="nombre">Asunto</div>
              <div class="asunto">Registración de Usuario</div>
            </div>
            <div class="wrapper2G vertical">
              <div class="nombre">Mensaje</div>
              <div class="mensaje">El usuario: Cuenta de testnotificacion@daimlertruck.com, test y test se ha registrado en la plataforma</div>
            </div>
          </div> -->

      <!-- Fila con datos -->
      <!-- <div class="row">
            <div class="wrapper2G acciones">
              <div class="check">
                <label class="checkboxWrapper" for="item1">
                  <input type="checkbox" id="item1" />
                  <span class="checkmark"></span>
                  <span class="description">Seleccionar</span>
                </label>
              </div>
              <div>
                <a href="#"><i class="icon"></i></a>
              </div>
            </div>

            <div class="wrapper2G">
              <div class="nombre">Fecha</div>
              <div class="Fecha">6/14/23, 1:20 AM</div>
            </div>
            <div class="wrapper2G">
              <div class="nombre">Asunto</div>
              <div class="asunto">Registración de Usuario</div>
            </div>
            <div class="wrapper2G vertical">
              <div class="nombre">Mensaje</div>
              <div class="mensaje">El usuario: Cuenta de testnotificacion@daimlertruck.com, test y test se ha registrado en la plataforma</div>
            </div>
          </div> -->

      <!-- Fila con datos -->
      <!-- <div class="row unread">
            <div class="wrapper2G acciones">
              <div class="check">
                <label class="checkboxWrapper" for="item1">
                  <input type="checkbox" id="item1" />
                  <span class="checkmark"></span>
                  <span class="description">Seleccionar</span>
                </label>
              </div>
              <div>
                <a href="#"><i class="icon"></i></a>
              </div>
            </div>

            <div class="wrapper2G">
              <div class="nombre">Fecha</div>
              <div class="Fecha">6/14/23, 1:20 AM</div>
            </div>
            <div class="wrapper2G">
              <div class="nombre">Asunto</div>
              <div class="asunto">Registración de Usuario</div>
            </div>
            <div class="wrapper2G vertical">
              <div class="nombre">Mensaje</div>
              <div class="mensaje">El usuario: Cuenta de testnotificacion@daimlertruck.com, test y test se ha registrado en la plataforma</div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Acciones y paginador -->
      <div class="wrapperG3 actionsAndPager">
        <div class="actions">
          <a (click)="markNotificationNotSeen()"><i class="icon"></i></a>
          <a (click)="markNotificationSeen()"><i class="icon"></i></a>
        </div>
      </div>
      <app-paginator [total]="totalNotifications" [pageSize]="pageSize" [pageIndex]="pageIndex" (changePage)="handlePage($event)"></app-paginator>
    </div>
  </div>
</main>
