import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { procotizaDto } from '../components/menu/models/menus.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: BehaviorSubject<string | null>;
  public userId: BehaviorSubject<number | null>;
  public token: BehaviorSubject<string | null>;
  public menus: BehaviorSubject<string | null>;
  public dealers: BehaviorSubject<string | null>;
  public permissions: BehaviorSubject<string | null>;
  public roles: BehaviorSubject<string | null>;
  public userType: BehaviorSubject<string | null>;
  public enabledNotifications: BehaviorSubject<string | null>;
  public moduleUserI: BehaviorSubject<string | null>;
  public procotiza: BehaviorSubject<procotizaDto | null>;
  public name: BehaviorSubject<string | null>;
  public paginator: BehaviorSubject<any | null>;
  public dealerId: BehaviorSubject<number | null>;
  public wholeSaleId: BehaviorSubject<number | null>;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.user = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('user')!)
    );
    this.userId = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('userId')!)
    );
    this.token = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('token')!)
    );

    this.menus = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('menus')!)
    );
    this.dealers = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('dealers')!)
    );
    this.permissions = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('permissions')!)
    );
    this.enabledNotifications = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('enabledNotifications')!)
    );
    this.roles = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('roles')!)
    );

    this.userType = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('userType')!)
    );

    this.moduleUserI = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('moduleUserI')!)
    );

    this.procotiza = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('procotiza')!)
    );
    this.name = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('name')!)
    );

    this.paginator = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('paginator')!)
    );

    this.dealerId = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('dealerId')!)
    );
  }

  /* Medtodo para setear Token y Obtener */
  public set tokenLS(token: string) {
    sessionStorage.setItem('token', JSON.stringify(token));
    this.token.next(token);
  }
  public get tokenLS(): string {
    return JSON.parse(sessionStorage.getItem('token')!);
  }

  /* Metodo para setea usuario y obtener */
  public get currentUser(): string {
    return JSON.parse(sessionStorage.getItem('user')!);
  }
  public set currentUser(user: string) {
    sessionStorage.setItem('user', JSON.stringify(user));
    this.user.next(user);
    // this.user = new BehaviorSubject<string>(user);
  }

  /* Metodo para setea usuario y obtener */
  public get currentUserIds(): string {
    return JSON.parse(sessionStorage.getItem('userId')!);
  }
  public set currentUserId(userId: number) {
    sessionStorage.setItem('userId', JSON.stringify(userId));
    this.userId.next(userId);
  }

  /* Metodo para setea menus y obtener */
  public get currentMenus(): any[] {
    return JSON.parse(sessionStorage.getItem('menus')!);
  }
  public set currentMenus(menus: any) {
    sessionStorage.setItem('menus', JSON.stringify(menus));
    this.menus.next(menus);
  }

  public checkRoleFromParentMenu(parent: string, role: string): boolean {
    const menu: any = this.getParentMenu(parent);
    if(!menu) return false;
    return menu.role === role;
  }

  public getParentMenu(parent: string): any {
    const menus = this.currentMenus;
    if(!menus) return false;
    return menus.find((item) => item.menuCode === parent);
  }

  /* Metodo para setea dealers y obtener */
  public get currentDealers(): any {
    return JSON.parse(sessionStorage.getItem('dealers')!);
  }
  public set setCurrentDealers(dealers: any) {
    sessionStorage.setItem('dealers', JSON.stringify(dealers));
    this.dealers.next(dealers);
  }

  public get getCurrentDealers(){
    return JSON.parse(sessionStorage.getItem('dealers')!);
  }

  // Método para obtener el nombre del dealer por el dealerId almacenado en sessionStorage
public getDealerName(): string | null {
  const dealerId = sessionStorage.getItem('dealerId');
    if (dealerId && this.currentDealers) {
    const dealerIdNumber = parseInt(dealerId, 10);
    const dealer = this.currentDealers.find((dealer: any) => dealer.id === dealerIdNumber);

    return dealer ? dealer.name : null;
  }
  return null;
}

  /* Metodo para setea permisos y obtener */
  public get currentPermissions(): any {
    return JSON.parse(sessionStorage.getItem('permissions')!);
  }
  public set setCurrentPermissions(permissions: any) {
    sessionStorage.setItem('permissions', JSON.stringify(permissions));
    this.permissions.next(permissions);
  }
  /* Metodo para setea permisos y obtener */
  public get currentEnabledNotifications(): any {
    return JSON.parse(sessionStorage.getItem('enabledNotifications')!);
  }
  public set setCurrentEnabledNotifications(enabledNotifications: any) {
    sessionStorage.setItem(
      'enabledNotifications',
      JSON.stringify(enabledNotifications)
    );
    this.permissions.next(enabledNotifications);
  }

  /* Metodo para setear roles y obtener */
  public get currentRoles(): any {
    return JSON.parse(sessionStorage.getItem('roles')!);
  }
  public set setCurrentRoles(roles: any) {
    sessionStorage.setItem('roles', JSON.stringify(roles));
    this.roles.next(roles);
  }

  /* Metodo para setear tipo de usuario y obtener */
  public get currentUserType(): any {
    return JSON.parse(sessionStorage.getItem('userType')!);
  }
  public set setcurrentUserType(userType: any) {
    sessionStorage.setItem('userType', JSON.stringify(userType));
    this.userType.next(userType);
  }

  /* Metodo para setear tipo de modulos-UserInternal y obtener */
  public get currentModule(): any {
    return JSON.parse(sessionStorage.getItem('moduleUserI')!);
  }

  public set setCurrentModule(modules: any) {
    sessionStorage.setItem('moduleUserI', JSON.stringify(modules));
    this.moduleUserI.next(modules);
  }

  /* Metodo para setear procotiza y obtener */
  public get currentProcotiza(): any {
    return JSON.parse(sessionStorage.getItem('procotiza')!);
  }
  public set setCurrentProcotiza(data: procotizaDto) {
    sessionStorage.setItem('procotiza', JSON.stringify(data));
    this.procotiza.next(data);
  }

  /* Metodo para setear nombre y obtener */
  public get currentName(): any {
    return JSON.parse(sessionStorage.getItem('name')!);
  }
  public set setCurrentName(name: string) {
    sessionStorage.setItem('name', JSON.stringify(name));
    this.name.next(name);
  }

  /* Metodo para setear procotiza y obtener */
  public get getCurrentPaginators(): any {
    return JSON.parse(sessionStorage.getItem('paginator')!);
  }

  public set setCurrentPaginators(data: any) {
    sessionStorage.setItem('paginator', JSON.stringify(data));
    this.paginator.next(data);
  }

  /* Metodo para setear dealearId y obtener */
  public get getCurrentDealerId(): any {
    return JSON.parse(sessionStorage.getItem('dealerId')!);
  }
  public get getCurrentWholeSaleId(): any {
    return JSON.parse(sessionStorage.getItem('wholeSaleId')!);
  }

  public get getCurrentUserType(): any {
    return JSON.parse(sessionStorage.getItem('userType')!);
  }

  public set setCurrentDealerId(dealerId: any) {
    sessionStorage.setItem('dealerId', JSON.stringify(dealerId));
    this.paginator.next(dealerId);
  }

  authDealers(): boolean {
    let dealers = ['Camiones Especiales','Gomsa Camiones','Jimenez Autocamiones','Automoviles Y Camiones Rivera','Autotab','Autobuses Y Tractocamiones Del Centro']
    const dealerName = this.getDealerName()||'';
    //console.log(dealerName,dealers.includes(dealerName))
    return dealers
      .map(
        (dealer) =>
          dealer
            .normalize('NFD')
            .replace(/\s+/g, '')
            .toLowerCase()
      )
      .includes((dealerName
        .normalize('NFD')
        .replace(/\s+/g, '')
        .toLowerCase()
        )
      );
  }

public getDealerGroup(): any | null {
  const dealerId = sessionStorage.getItem('dealerId');
  if (dealerId && this.currentDealers) {
      const dealerIdNumber = parseInt(dealerId, 10);
      const dealer = this.currentDealers.find((dealer: any) => dealer.id === dealerIdNumber);
      if (dealer && dealer.group) {
          return dealer.group; // Devuelve el grupo asociado
      }
  } 
  return null; 
}
  /**
   * Metodo para desloguear el usuario
   */
  logout() {
    this.router.navigate(['/'], {
      relativeTo: this.route,
      queryParams: {
        token: this.token,
        mail: this.user,
        page: 'salir0',
      },
    });
    this.router.navigate(['/'], {
      relativeTo: this.route,
    });
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('wholeSaleId');
    sessionStorage.removeItem('menus');
    sessionStorage.removeItem('dealers');
    sessionStorage.removeItem('permissions');
    sessionStorage.removeItem('enabledNotifications');
    sessionStorage.removeItem('roles');
    sessionStorage.removeItem('userType');
    sessionStorage.removeItem('moduleUserI');
    sessionStorage.removeItem('procotiza');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('paginator');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('dealerId');
    window.sessionStorage.removeItem('upp');
    window.sessionStorage.removeItem('upm');
    sessionStorage.removeItem('lastInput');
    this.token.next(null);
    this.user.next(null);
    this.userId.next(null);
    this.enabledNotifications.next(null);
    this.menus.next(null);
    this.dealers.next(null);
    this.permissions.next(null);
    this.procotiza.next(null);
    this.name.next(null);
    this.paginator.next(null);
    this.dealerId.next(null);
    this.wholeSaleId.next(null);
  }
}
