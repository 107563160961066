<div class="form-multiselect" [ngClass]="{'form-multiselect--open': isOpen}">
  <div class="form-multiselect__input-wrap" (click)="toggleOpen()">
    <div class="form-multiselect__input">
        <!-- Cuando ninguna opción esté elegida y no se haya ingresado texto de búsqueda, mostrar el placeholder -->
      <span *ngIf="!selectedOption" class="form-multiselect__placeholder">{{placeholder}}</span> 
        <!-- Cuando se haya seleccionado una opción, mostrar la opción seleccionada -->
      <span *ngIf="selectedOption">{{ selectedOption }}</span>
    </div>  
    <span class="form-multiselect__close" [style.display]="selectedOption ? 'block' : 'none'" (click)="reset()"></span>
    <div class="button button--dark"><i class="icon"></i></div>
  </div>  
  <ul class="form-multiselect__options" [style.display]="isOpen ? 'block' : 'none'">
    <li class="form-multiselect__option" *ngFor="let option of data" (click)="selectOption(option)">
      {{ option }}
    </li>
  </ul>
</div>