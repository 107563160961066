<div id="client" class="module readOnly">
  <h3>Cliente</h3>
  <div>
    <div class="wrapperG5">
      <div>
        <label>Tipo de persona</label>
        <span class="value">{{
          customer?.personType == "NATURAL_PERSON"
            ? "Persona física"
            : "Persona moral"
        }}</span>
      </div>
      <div>
        <label>Nombre</label>
        <span class="value">{{ customer?.firstName }}</span>
      </div>
      <ng-container *ngIf="customer?.personType == 'NATURAL_PERSON'">
        <div>
          <label>Segundo nombre</label>
          <span class="value">{{ customer?.secondName }}</span>
        </div>
        <div>
          <label>Apellido paterno</label>
          <span class="value">{{ customer?.lastName }}</span>
        </div>
        <div>
          <label>Apellido materno</label>
          <span class="value">{{ customer?.mothersLastName }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
