<main class="main">
  <app-notifications></app-notifications>
  <!-- INTRO -->
  <figure class="hero hero--has-caption-right">
    <picture class="hero__picture">
      <img
        class="hero__image"
        title=""
        alt="Daimler Truck"
        src="assets/images/home/intro_02.jpg"
        width=""
        height=""
        fetchpriority=" high"
      />
    </picture>

    <figcaption
      class="hero__caption hero__caption--slider hero__caption--right-top hero__caption--grey-background"
    >
      <div class="hero__header">
        <h1 class="title title--2 title--bold">
          <span class="title__first-line"
            >Bienvenido al sitio de distribuidores</span
          >
        </h1>
      </div>
    </figcaption>
  </figure>

  <!-- AREAS DESTACADAS -->
  <section
    class="section"
    *authPermission="[
      permissions.cotizador0,
      permissions.planPiso0,
      permissions.loyalty0
    ]"
  >
    <img
      class="section__background"
      src="assets/images/home/road.png"
      width="1916"
      height="1078"
      alt=""
    />
    <div class="container">
      <!-- <h2 class="welcome">Bienvenido a Daimler Trucks Financial Services México</h2> -->
      <!-- <div class="row">
        <div class="col">
          <header>
            <h3 class="title title--3 title--bold">
              <span class="title__first-line"
                >Bienvenido a Daimler Trucks Financial Services México</span
              >
            </h3>
          </header>
        </div>
      </div> -->

      <div class="row">
        <div
          class="col col--md-2-6 u-mb--4 u-mb--md-0"
          *authPermission="[permissions.cotizador0]"
        >
          <figure class="card">
            <a
              (click)="redirectionProcotiza()"
              target="_self"
              class="card__inner"
            >
              <picture class="card__picture">
                <img
                  class="card__image"
                  title=""
                  alt="Cotizador"
                  src="assets/images/home/cotizador.jpg"
                  width=""
                  height=""
                  loading="lazy"
                />
              </picture>
            </a>
            <figcaption class="card__caption u-align--right">
              <a
                (click)="redirectionProcotiza()"
                target="_self"
                class="card__inner"
              ></a>
              <a
                (click)="redirectionProcotiza()"
                target="_self"
                class="button button--arrow"
                ><span>Cotizador</span>
              </a>
            </figcaption>
          </figure>
        </div>
        <div
          class="col col--md-2-6 u-mb--4 u-mb--md-0"
          *authPermission="[permissions.planPiso0]"
        >
          <figure class="card">
            <a
              [href]="
                dealerSite +
                'token=' +
                authService.tokenLS +
                '&pagina=planPiso0'
              "
              target="_self"
              class="card__inner"
            >
              <picture class="card__picture">
                <img
                  class="card__image"
                  title=""
                  alt="Plan piso"
                  src="assets/images/home/plan_piso.jpg"
                  width=""
                  height=""
                  loading="lazy"
                />
              </picture>
            </a>
            <figcaption class="card__caption u-align--right">
              <a
                [href]="
                  dealerSite +
                  'token=' +
                  authService.tokenLS +
                  '&pagina=planPiso0'
                "
                target="_self"
                class="card__inner"
              ></a>
              <a
                [href]="
                  dealerSite +
                  'token=' +
                  authService.tokenLS +
                  '&pagina=planPiso0'
                "
                target="_self"
                class="button button--arrow"
                ><span>Plan piso</span></a
              >
            </figcaption>
          </figure>
        </div>
        <div class="col col--md-2-6" *authPermission="[permissions.loyalty0]">
          <figure class="card">
            <a
              [href]="
                dealerSite + 'token=' + authService.tokenLS + '&pagina=loyalty0'
              "
              target="_self"
              class="card__inner"
            >
              <picture class="card__picture">
                <img
                  class="card__image"
                  title=""
                  alt="Loyalty"
                  src="assets/images/home/loyalty.jpg"
                  width=""
                  height=""
                  loading="lazy"
                />
              </picture>
            </a>
            <figcaption class="card__caption u-align--right">
              <a
                [href]="
                  dealerSite +
                  'token=' +
                  authService.tokenLS +
                  '&pagina=loyalty0'
                "
                target="_self"
                class="card__inner"
              ></a>
              <a
                [href]="
                  dealerSite +
                  'token=' +
                  authService.tokenLS +
                  '&pagina=loyalty0'
                "
                target="_self"
                class="button button--arrow"
                ><span>Loyalty</span></a
              >
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </section>

  <!-- AREAS GENERALES -->
  <section
    class="section section--bright"
    *authPermission="[
      permissions.administracion0,
      permissions.videoCampaign0,
      permissions.efilesAutos0,
      permissions.propuestaValor0,
      permissions.circulares0
    ]"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <ng-container *ngFor="let area of generalAreas; let i = index">
            <ng-container *ngIf="i % 2 === 0">
              <figure
                class="card card--30-70"
                *authPermission="area.permissions"
              >
                <a
                  [href]="area.href"
                  class="card__inner"
                  [target]="area.target ? area.target : '_blank'"
                >
                  <figcaption class="card__caption u-background--grey-dark">
                    <header class="u-align--left">
                      <h4 class="title title--4 title--bold">
                        <span class="title__first-line">{{ area.label }}</span>
                      </h4>
                    </header>
                  </figcaption>

                  <picture class="card__picture">
                    <img
                      class="card__image"
                      title=""
                      alt="{{ area.label }}"
                      src="assets/images/home/{{ area.image }}.jpg"
                      width=""
                      height=""
                      loading="lazy"
                    />
                  </picture>
                </a>
              </figure>
            </ng-container>
            <ng-container *ngIf="i % 2 != 0">
              <figure
                class="card card--70-30"
                *authPermission="area.permissions"
              >
                <a
                  [href]="area.href"
                  [target]="area.target ? area.target : '_blank'"
                  class="card__inner"
                >
                  <picture class="card__picture">
                    <img
                      *ngIf="area.image && area.image !== ''"
                      class="card__image"
                      title=""
                      alt="{{ area.label }}"
                      src="assets/images/home/{{ area.image }}.jpg"
                      width=""
                      height=""
                      loading="lazy"
                    />
                  </picture>

                  <figcaption class="card__caption u-background--grey-bright">
                    <header class="u-align--left">
                      <h4 class="title title--4 title--bold">
                        <span class="title__first-line">{{ area.label }}</span>
                      </h4>
                    </header>
                  </figcaption>
                </a>
              </figure>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </section>

  <!-- Más accesos -->
  <section
    class="section section--grey-background u-pb--4 section--decreased-mb"
    *authPermission="[
      permissions.seguros0,
      permissions.dealercontact0,
      permissions.pbl0,
      permissions.descargas0,
      permissions.directorio0,
      permissions.ltv
    ]"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <header>
            <h3 class="title title--3 title--bold">
              <span class="title__first-line">Más accesos</span>
            </h3>
          </header>
        </div>
      </div>
      <div class="row">
        <ng-container *ngFor="let area of moreAccesses; let i = index">
          <ng-container *ngIf="i % 2 === 0">
            <div
              class="col col--md-2-6 u-mb--4 u-mb--md-0"
              *authPermission="area.permissions"
            >
              <figure class="card card--headline-teaser">
                <a href="{{ area.href }}" target="_self" class="card__inner">
                  <picture class="card__picture">
                    <img
                      *ngIf="area.image && area.image !== ''"
                      class="card__image"
                      title=""
                      alt="{{ area.label }}"
                      src="/assets/images/home/{{ area.image }}.jpg"
                      width=""
                      height=""
                      loading="lazy"
                    />
                  </picture>

                  <figcaption
                    class="card__caption u-background--bright u-align--left"
                  >
                    <header>
                      <h5 class="title title--5 title--bold">
                        <span class="title__first-line">{{ area.label }}</span>
                      </h5>
                    </header>
                  </figcaption>
                </a>
              </figure>
            </div>
          </ng-container>

          <ng-container *ngIf="i % 2 != 0">
            <div
              class="col col--md-2-6 u-mb--4 u-mb--md-0"
              *authPermission="area.permissions"
            >
              <div class="card card--square u-background--grey-dark">
                <a href="{{ area.href }}" target="_self" class="card__inner">
                  <div
                    class="card__caption u-background--grey-dark u-align--left"
                  >
                    <header class="u-align--left">
                      <h4 class="title title--5 title--bold">
                        <span class="title__first-line">{{ area.label }}</span>
                      </h4>
                    </header>
                  </div>
                </a>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </section>
</main>
