import {
  ElementRef,
  HostListener,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.css'],
})
export class InputSearchComponent implements OnInit, OnChanges {
  @Input() options: any[];
  @Input() placeholder: string;
  @Input() selectedModel: any;
  @Output() searchSelected: EventEmitter<any> = new EventEmitter();

  isOpen: boolean = false;
  searchInput: string = '';
  selectedOption: string = '';
  optionsSearch: any[] = [];
  data: any[] = [];

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.data = this.options.map((i) => {
        return i.label;
      });
      // if (this.options.length != 0 && this.selectedModel) {
      //   console.log(this.selectedModel)
      //   console.log(this.options);
      //   console.log( this.options.find(
      //     (i) => i.value == this.selectedModel
      //   ));
      //   this.searchInput = this.options.find(
      //     (i) => i.value == this.selectedModel
      //   ).label;
      // }
    }

    if (changes['selectedModel']) {
      if (!this.selectedModel) {
        this.reset();
      } else if (!this.selectedOption) {
        this.selectOptionSearch(this.selectedModel);
      }
    }
  }

  ngOnInit() {
    this.data = this.options.map((i) => {
      return i.label;
    });
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  toggleOpen() {
    this.optionsSearch = this.data;
    this.isOpen = !this.isOpen;
  }

  filterOptions() {
    if (this.searchInput.length >= 3) {
      let searchTerm = this.searchInput.toLowerCase();
      this.optionsSearch = this.data.filter((option) => {
        let lowercasedOption = option.toLowerCase();
        return (
          lowercasedOption === searchTerm ||
          lowercasedOption.includes(searchTerm)
        );
      });
      this.isOpen = true;
    }

    if (this.searchInput == '') {
      this.optionsSearch = this.data;
    }
  }

  selectOptionSearch(option: string) {
    this.searchInput = option;
    this.selectedOption = option;
    let selected = this.options.filter((i) => i.label == option);
    if (selected.length == 0) return;
    this.searchSelected.emit(selected[0].id);
    this.isOpen = false;
  }

  reset() {
    this.selectedOption = '';
    this.searchInput = '';
    this.searchSelected.emit(null);
  }
}
