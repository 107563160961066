import { Component, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from '@app/services/notifications.service';
import { LoginService } from '../services/login.service';
import { environment } from '@env/environment.local';
import { BaseComponent } from '@app/components/base/base.component';
import { ActivatedRoute } from '@angular/router';
import { MenusServices } from '../../../services/menus.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent extends BaseComponent {
  form: FormGroup;
  email = environment.email;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    route: ActivatedRoute,
    private translateService: TranslateService,
    menuService: MenusServices,
    notificationsService: NotificationService
  ) {
    super(route, menuService, notificationsService);
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  formResetPassword: boolean = true;
  resetPasswordSent: boolean = false;
  isLoading: boolean = false;
  userName!: string;

  get f() {
    return this.form.controls;
  }

  onSubmit(): void {
    this.isLoading = true;
    this.userName = this.form.controls['email'].value;
    const userName = this.userName;
    const email = userName.replace(/@/g, '%40');
    if (this.form.valid) {
      this.loginService.resetPassword(email).subscribe({
        next: (r) => {
          if (r.status){
            this.formResetPassword = false;
            this.resetPasswordSent = true;
          }else {
            this.translateService.get(r.errors[0]).subscribe((res: string) => {
              this.showNotificationError(
                res
              );
            })
          }
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.showNotificationError(
            'Ha ocurrido un error. Vuelva a intentarlo'
          );
        },
      });
    } else if (!this.form.valid) {
      this.isLoading = false;
      this.showNotificationError(
        'Debe ingresar su email o usuario para continuar.'
      );
    }
  }
}