import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {StandardQuoteService} from '../../standard-quote-service.service';
import {
  configuration,
  insurers,
  coverages,
  quoteDto,
  financialSetup,
  insurance,
} from '../../model/standard-quote.model';
import {FormControl, FormGroup} from '@angular/forms';
import {
  CreateCoverage,
  GetCoverage,
} from '../../model/standard-insurance.model';
import { BaseComponent } from '@app/components/base/base.component';
import { ActivatedRoute } from '@angular/router';
import { MenusServices } from '@app/services/menus.service';
import { NotificationService } from '@app/services/notifications.service';
import {error} from "@angular/compiler-cli/src/transformers/util";

interface InsuranceConfig {
  [key: string]: {
    logo: string;
    id: number;
  };
}

const insuranceConfig: InsuranceConfig = {
  "Qualitas": {
    "logo": "./assets/images/insurance/logo_qualitas.png",
    "id": 222
  },
  "Grupo Nacional Provincial": {
    "logo": "./assets/images/insurance/logo_gnp_seguros.png",
    "id": 14625
  },
  "HDI SEGUROS, S. A. DE C. V.": {
    "logo": "./assets/images/insurance/logo_hdi_seguros.png",
    "id": 240819
  }
}


@Component({
  selector: 'app-insurance-flex',
  templateUrl: './insurance-flex.component.html',
  styleUrls: ['./insurance-flex.component.css'],
})
export class InsuranceFlexComponent extends BaseComponent implements OnInit {
  @Input() financialSetup: financialSetup;
  @Input() insurance: insurance;

  isLoading: boolean = false;

  configuration: configuration; //listado de configuracion para generar cotizacion
  quotes: any[] = []; //listado de cotizaciones
  coverages: coverages[] = []; //listado de coberturas
  insurers: insurers[] = [];

  selectedInsuranceSubject: Subject<any> = new Subject<any>();

  currentStep: number = 1;

  forms: FormGroup[] = [];
  modelQuoterPanel: any;
  requestId: number;
  formData: quoteDto | null = this.quoteService.formData;

  constructor(
    public quoteService: StandardQuoteService,
    public dialogRef: MatDialogRef<InsuranceFlexComponent>,
    route: ActivatedRoute,
    menu: MenusServices,
    notification: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(route, menu, notification);
  }

  ngOnInit() {
    this.getCoverage();
  }

  //#region Get-Coverage
  createForm() {
    this.configuration.coverages.map((coverage) => {
      const formGroup = new FormGroup({
        coverageId: new FormControl(coverage.coverageId),
        filterValueRangeSum: new FormControl({
          value:
            coverage.modelRange.rangesSums.length == 1 &&
            !coverage.special &&
            !coverage.isMaskingSumsInsured
              ? coverage.modelRange.rangesSums[0]
              : coverage.filterValueRangeSum,
          disabled: !coverage.selected,
        }),
        filterValueRangeDeductible: new FormControl({
          value:
            coverage.modelRange.deductibleRanges.length == 1 &&
            !coverage.special
              ? coverage.modelRange.deductibleRanges[0]
              : coverage.filterValueRangeDeductible,
          disabled: !coverage.selected,
        }),
        selected: new FormControl(coverage.selected),
      });
      if (!coverage.fixedProfile) formGroup.controls['selected'].disable();
      this.forms.push(formGroup);
    });
  }

  getCoverage() {
    //Obtengo y formo resultado de coberturas
    if (!this.formData) return;
    this.isLoading = true;
    let body = new GetCoverage(
      this.data.financialSetup,
      this.formData.model,
      this.data.insurance,
      this.data.standardQuoteModel,
      this.formData.companyName
    );
    this.quoteService.getCoverage(body).subscribe({
      next: (response) => {
        if (response.status) {
          this.configurationModel(response);
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.showNotificationError(
            'Ha ocurrido un error. Vuelva a intentarlo.'
          );
          this.onClose();
        }
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  configurationModel(response: any) {
    //console.log(" get coverage response", JSON.stringify(response.data));
    let modelquoterPanel = response.data.modelQuoterPanel;
    modelquoterPanel.stateId = this.data.insurance.state;
    let model = {
      productId: modelquoterPanel.productId,
      insurers: modelquoterPanel.insurers,
      coverages: modelquoterPanel.coverages.map((i: any) => ({
        coverageId: i.coverageId,
        coverageName: i.coverageName,
        modelRange: {
          deductibleRanges: i.modelRange.deductibleRanges,
          rangesSums: i.modelRange.rangesSums,
        },
        insurersCoverage: i.insurersCoverage.map((i: any) => ({
          insurerId: i.insurerId,
          insurerName: i.insurerName,
          coverageId: i.coverageId,
          coverageName: i.coverageName,
          coverageIndicator: i.coverageIndicator,
          modelRanges: i.modelRanges,
          filterValueRangeSum: i.filterValueRangeSum,
          filterValueRangeDeductible: i.filterValueRangeDeductible,
        })),
        filterValueRangeSum: i.filterValueRangeSum,
        filterValueRangeDeductible: i.filterValueRangeDeductible,
        sumInsuredDefault: i.sumInsuredDefault,
        maskingSumsInsured: i.maskingSumsInsured,
        defaultDeductible: i.defaultDeductible,
        fixed: i.fixed, // saber si la cobertura es opcional o no
        fixedProfile: i.fixedProfile, //Saber si el checkbox esta deshabilitado
        selected: i.selected, //Saber si estara seleccionado el checkbox
        isMaskingSumsInsured: i.isMaskingSumsInsured, //Booleano para saber si es de tipo Amparado
        special: i.special, //booleano para saber si es un campo especial
        isMaskingDeductibles: i.isMaskingDeductibles, //boolean utilizado para saber si es de tip S/D
        dependency: i.dependency,
        relatedCoverage: i.relatedCoverage,
      })),
    };
    this.modelQuoterPanel = modelquoterPanel;
    this.configuration = model;
    this.insurers = this.configuration.insurers;

    //search by key from insurance name
    this.insurers.map((insurer) => {
      insurer.src = insuranceConfig[insurer.insurerName] ? insuranceConfig[insurer.insurerName].logo : "";
      insurer.alt = insurer.insurerName;
    });


    this.coverages = this.configuration.coverages;
    this.createForm();
  }

  isNumberString(value: string): boolean {
    // Expresión regular para verificar si la cadena es un número
    const numberPattern = /^[0-9]*$/;
    return numberPattern.test(value);
  }

  getModel() {
    //Obtengo valores del formulario para cada cobertura
    const model: any[] = [];
    this.forms.map((form) => {
      model.push(form.getRawValue());
    });
    let coverages = this.modelQuoterPanel.coverages.map((i: any) => {
      let coverageValue = model.find((m) => m.coverageId == i.coverageId);
      return {
        ...i,
        filterValueRangeSum:
          i.coverageId == coverageValue.coverageId
            ? coverageValue.filterValueRangeSum
            : i.filterValueRangeSum,
        filterValueRangeDeductible:
          i.coverageId == coverageValue.coverageId
            ? coverageValue.filterValueRangeDeductible
            : i.filterValueRangeDeductible,
        selected:
          i.coverageId == coverageValue.coverageId
            ? coverageValue.selected
            : i.selected,
      };
    });
    this.modelQuoterPanel.coverages = coverages;
    return this.modelQuoterPanel;
  }
  
  getCoverageIndicator(coverageId: any, insurerId: any): string {
    let coverageIndicator = this.coverages
      .find((coverage: any) => coverage.coverageId === coverageId)
      ?.insurersCoverage.find(
        (i) => i.insurerId == insurerId
      ).coverageIndicator;

    return coverageIndicator;
  }

  //#endregion

  updateFilterRangesSums(event: any, coverageId: number, insurerId: number) {
    if (event) {
      const value = (event.target as HTMLSelectElement).value;
      let panel = this.getModel();
      panel = {
        ...panel,
        coverages: panel.coverages.map((i: any) => {
          return {
            ...i,
            insurersCoverage:
              i.coverageId == coverageId
                ? i.insurersCoverage.map((i: any) => {
                  return {
                    ...i,
                    filterValueRangeSum:
                      i.insurerId == insurerId
                        ? value
                        : i.filterValueRangeSum,
                  };
                })
                : i.insurersCoverage,
          };
        }),
      };

      this.recalculateCoverage(panel);
    }
  }

  updateFilterDeductibleRanges(
    event: any,
    coverageId: number,
    insurerId: number
  ) {
    if (event) {
      const value = (event.target as HTMLSelectElement).value;
      let panel = this.getModel();

      panel = {
        ...panel,
        coverages: panel.coverages.map((i: any) => {
          return {
            ...i,
            insurersCoverage:
              i.coverageId == coverageId
                ? i.insurersCoverage.map((i: any) => {
                  return {
                    ...i,
                    filterValueRangeDeductible:
                      i.insurerId == insurerId
                        ? value
                        : i.filterValueRangeDeductible,
                  };
                })
                : i.insurersCoverage,
          };
        }),
      };

      this.recalculateCoverage(panel);
    }
  }

  getRecalculateCoverage(coverage: any = null, index: number) {
    if (coverage != null && coverage.special && coverage.dependency != '1') {
    } else {
      const bodyRecalculate = this.getModel();
      this.recalculateCoverage(bodyRecalculate);
    }
  }

  recalculateCoverage(panel: any) {
    this.isLoading = true;
    this.forms = [];
    this.quoteService.getRecalculateCoverage(panel).subscribe({
      next: (response) => {
        if (response.status) {
          this.configurationModel(response);
          this.isLoading = false;
        } else {
          this.showNotificationError(
            'Ha ocurrido un error. Vuelva a intentarlo.'
          );
          this.onClose();
          this.isLoading = false;
        }
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  validateCoverage() {
    let errors = new Array<any>();
    this.coverages.forEach((coverage, index) => {
      if (coverage.dependency != '1' && coverage.special) {
        const form = this.forms[index];
        if (this.forms[index].controls['selected'].value) {
          if (!form.controls['filterValueRangeDeductible'].value) {
            errors.push({
              coverage: coverage.coverageName,
              field: 'Deducible',
              message: 'Favor de validar el campo Deducible'
            });
          }else{
            if(!this.isNumberString(form.controls['filterValueRangeDeductible'].value)){
              errors.push({
                coverage: coverage.coverageName,
                field: 'Deducible',
                message: 'Favor de ingresar un valor numérico'
              });
            }
          }
          if (!form.controls['filterValueRangeSum'].value) {
            errors.push({
              coverage: coverage.coverageName,
              field: 'Sumas Aseguradas',
              message: 'Favor de validar el campo Sumas Aseguradas'
            });
          }
        }
      }
    });
    return errors;
  }

  showErrorCoverage(errors: any[]) {
    errors.forEach((error: any) => {
      this.showNotificationError( 'Verifique panel de Coberturas/Aseguradoras, requiere la captura de: \n' +
        'Cobertura :' + error.coverage + ' ' +  error.field + ' ' + ' ' +error.message );
    });
  }

  //#region Create-Quote
  createQuote() {
    if (!this.formData) return;
    let errors = this.validateCoverage();
    if (errors.length > 0) {
      this.showErrorCoverage(errors);
      return;
    }
    const panel = this.getModel();
    const body = new CreateCoverage(
      this.data.financialSetup,
      this.data.financialCondition,
      this.formData.model,
      panel,
      this.formData.customer,
      this.data.insurance,
      this.data.dealerBranch,
      this.quoteService.formData?.companyName,
      this.quoteService.formData?.standardQuoteModel
    );

   // console.log("create coverage body", JSON.stringify(body));

    body.panel.coverages.forEach((coverage: any) => {
      coverage.insurersCoverage.forEach((insurer: any) => {
        insurer.fixedCoverage = false;
        insurer.filterValueRangeSum = coverage.filterValueRangeSum;
        insurer.filterValueRangeDeductible = coverage.filterValueRangeDeductible;
      });
    });
    //console.log("acreate coverage body", JSON.stringify(body));
    this.isLoading = true;
    this.quoteService.createQuote(body).subscribe({
      next: (response) => {
        if (response.status) {
          this.currentStep = 2;
          this.requestId = response.data.applicationId;
          this.getCoverageQuote(response.data.applicationId);
        } else {
          if (response.error) {
            const errors = response.error[0];
            this.showNotificationError(
              'Ha ocurrido un error. Vuelva a intentarlo. Error Marsh: ' +
              errors[0].Message
            );
          } else {
            this.showNotificationError(
              'Ha ocurrido un error. Vuelva a intentarlo.'
            );
          }
          this.onClose();
          this.isLoading = false;
        }
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  //#endregion

  //#region Get-Quote

  getCoverageQuote(id: number) {
    this.isLoading = true;
    this.quoteService.getQuoteCoverage(id).subscribe({
      next: (response) => {
        if (response.status) {
          this.quotes = response.data.productInsurers.map((i: any) => ({
            insuranceId: i.insuranceId,
            productId: i.productId,
            packageList: i.packageList[0],
          }));
          this.isLoading = false;
        } else {
          this.showNotificationError(
            'Ha ocurrido un error. Vuelva a intentarlo.'
          );
          this.onClose();
          this.isLoading = false;
        }
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  getListCoverage() {
    this.quotes.forEach((i) => {
      if (i.packageList && i.packageList.coverageListModel) {
        i.packageList.coverageListModel.map((coverage: any) => {
          const existingCoverage = this.coverages.find(
            (c: any) => c.coverageId === coverage.coverageId
          );
          if (!existingCoverage) {
            this.coverages.push(coverage);
          }
        });
      }
    });
  }

  getByCoverageId(id: any, coverageId: any): string {
    if (this.quotes.length <= 0) return "N/A";
  
    const insurance = this.quotes.find(
      (insurance: any) => insurance.insuranceId == id
    );
    if (!insurance?.packageList?.coverageListModel) return "N/A";
  
    let coverage = insurance.packageList.coverageListModel.find(
      (coverage: any) =>
        coverage.coverageId === coverageId ||
        (coverage.name === "GASTOS MÉDICOS CONDUCTOR" && coverageId === 446) ||
        (coverage.name === "GASTOS MÉDICOS" && coverageId === 2441) 
    );
  
    return coverage
      ? coverage.sumAssured + " " + coverage.deductible
      : "N/A";
  }

  selectedInsurance(insuranceId: number) {
    const packageSelected = this.quotes.find(
      (insurance: any) => insurance.insuranceId == insuranceId
    );
    const insuranceSelected = this.configuration.insurers.find(
      (insurance: any) => insurance.insurerId == insuranceId
    );
    let model = {
      insuranceId: insuranceSelected?.insurerId,
      insurerName: insuranceSelected?.insurerName,
      packageList: packageSelected.packageList,
      requestId: this.requestId,
    };
    this.selectedInsuranceSubject.next(model);
    this.onClose();
  }

  //#endregion

  goToPreviousStep() {
    this.currentStep = 1;
  }

  onClose() {
    this.dialogRef.close();
  }

  existPackageList(insurerId: number) {
    const insurances = this.quotes.find(
      (insurance: any) => insurance.insuranceId == insurerId
    );
    return insurances && insurances.packageList;
  }

  getPackageLisAmount(insurerId: number) {
    const insurances = this.quotes.find(
      (insurance: any) => insurance.insuranceId == insurerId
    );
    if (!insurances) return;
    return insurances.packageList.amount;
  }

  getPackageListNumber(insurerId: number) {
    const insurances = this.quotes.find(
      (insurance: any) => insurance.insuranceId == insurerId
    );
    if (!insurances) return;
    return insurances.packageList.number;
  }
  getPackageListName(insurerId: number) {
    const insurances = this.quotes.find(
      (insurance: any) => insurance.insuranceId == insurerId
    );
    if (!insurances) return;
    return insurances.packageList.name;
  }
}