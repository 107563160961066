<div class="form-multiselect form-multiselect--open">
  <div class="form-multiselect__input-wrap">
    <input
      [placeholder]="placeholder"
      class="form-multiselect__input"
      [ngClass]="{ 'form-multiselect__input--empty': searchInput === '' }"
      [(ngModel)]="searchInput"
      (ngModelChange)="filterOptions()"
      (click)="toggleOpen()"
      [readonly]="selectedOption ? 'readonly' : null"
    />
    <span
      class="form-multiselect__close"
      [style.display]="selectedOption ? 'block' : 'none'"
      (click)="reset()"
    ></span>
  </div>

  <ul
    class="form-multiselect__options"
    [style.display]="isOpen ? 'block' : 'none'"
  >
    <li
      class="form-multiselect__option form-multiselect__option--small"
      *ngFor="let option of optionsSearch"
      (click)="selectOptionSearch(option)"
    >
      {{ option }}
    </li>
  </ul>
</div>
