import { Component, OnInit } from '@angular/core';
import { MailshotService } from '../mailshot.service';
import { BaseComponent } from '@app/components/base/base.component';
import { NotificationService } from '../../../services/notifications.service';
import { ActivatedRoute } from '@angular/router';
import { MenusServices } from '@app/services/menus.service';
import { PageEvent } from '@angular/material/paginator';
import { EntitiesServices } from '@app/services/entities.service';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';
import { AuthService } from '@app/common/auth.service';
import { environment } from '@env/environment.local';

@Component({
  selector: 'app-details-mailshot',
  templateUrl: './details-mailshot.component.html',
  styleUrls: ['./details-mailshot.component.css'],
})
export class DetailsMailshotComponent extends BaseComponent implements OnInit {
  users: any[] = [];
  select: any[] = this.service.select;
  input: any[] = [{ value: '', placeholder: 'Buscar usuario' }];
  module: string = '';
  isLoading: boolean = false;
  id: any = '';
  hasData: boolean = true;
  listUSer: any[] = [];

  pages: any[] = [];
  total: number = 0;
  pageSize: number = environment.pageSize;
  pageIndex: number = 0;

  params: any = {
    page: this.pageIndex,
    pageSize: this.pageSize,
  };

  filterName: string = '';
  isDownload: any = null;
  profiles: any[] = [];
  data: any;

  constructor(
    route: ActivatedRoute,
    menuService: MenusServices,
    private service: MailshotService,
    private notifications: NotificationService,
    private entitiesService: EntitiesServices,
    private authService: AuthService
  ) {
    super(route, menuService);
    this.module = this.sendDataToMenu();
    this.id = route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.isLoading = true;
    this.service.getDetailsMailshot(this.id).subscribe({
      next: (r: any) => {
        this.data = r.data;
        this.users = r.data.users;
        this.isLoading = false;
        this.listUSer = this.users;
        this.hasData = r.data.users.length > 0;
        if (this.hasData) {
          this.createPages(r.data.users);
        }
        this.getProfiles();
      },
      error: (error: any) => {
        this.isLoading = false;
        this.notifications.showMessage(this.msgFailedConnection, 'error');
      },
    });
  }

  getProfileName(id: number) {
    let profile = this.profiles.find((profile) => profile.id === id);
    return profile ? profile.description : '';
  }

  getProfiles() {
    this.isLoading = true;
    this.entitiesService.listProfiles().subscribe({
      next: (response) => {
        this.isLoading = false;
        this.profiles = response.data;
      },
      error: (error) => {
        this.notifications.showMessage(this.msgFailedConnection, 'error');
        this.isLoading = false;
      },
    });
  }

  createPages(list: any[]) {
    const itemsPerPage = this.pageSize; // Establece la cantidad de destinatarios por página
    this.pages = [];
    for (let i = 0; i < list.length; i += itemsPerPage) {
      this.pages.push(list.slice(i, i + itemsPerPage));
    }
    this.total = list.length;
    this.hasData = list.length > 0;
    this.users = this.pages[this.pageIndex]; // Mostrar la primera página inicialmente
  }

  filter(value: any, typeSelect: string) {
    switch (typeSelect) {
      case 'user':
        this.filterName = value;
        break;
      case 'download':
        this.isDownload = value;
        break;
    }
    this.filterList();
  }

  filterList() {
    this.isLoading = true;
    this.pageIndex = 0;

    setTimeout(() => {
      this.isLoading = false;
    }, 1500);

    let results: any[] = [];

    if (this.filterName != '' && this.isDownload == null) {
      // solo filtro por nombre
      results = this.listUSer.filter((i) =>
        i.name.toLowerCase().includes(this.filterName)
      );
    } else if (
      this.filterName != '' &&
      this.isDownload != null &&
      this.isDownload
    ) {
      // filtro por nombre  y por descargado
      results = this.listUSer.filter(
        (i) =>
          i.name.toLowerCase().includes(this.filterName) && !!i.downloadDate
      );
    } else if (
      this.filterName != '' &&
      this.isDownload != null &&
      !this.isDownload
    ) {
      // filtro por nombre  y sin descargar
      results = this.listUSer.filter(
        (i) => i.name.toLowerCase().includes(this.filterName) && !i.downloadDate
      );
    } else if (this.filterName == '' && this.isDownload == null) {
      // muestro todos
      results = this.listUSer;
    } else if (
      this.filterName == '' &&
      this.isDownload != null &&
      this.isDownload
    ) {
      // por descargado
      results = this.listUSer.filter((i) => !!i.downloadDate);
    } else if (
      this.filterName == '' &&
      this.isDownload != null &&
      !this.isDownload
    ) {
      // filtro sin descargar
      results = this.listUSer.filter((i) => !i.downloadDate);
    }

    this.createPages(results);
  }

  handlePage(e: PageEvent) {
    if (e.pageIndex != this.pageIndex) this.users = this.pages[e.pageIndex];

    if (e.pageSize != this.pageSize) {
      this.pageSize = e.pageSize;
      this.createPages(this.listUSer);
    }
  }

  isOpen: boolean = false;

  openActions() {
    this.isOpen = !this.isOpen;
  }

  exportToExcel(): void {
    let date = formatDate(this.data.date, 'dd-MMM-yyyy, HH:mm', 'en-Us');

    const rows: any[] = [];
    const nameAndDateRow = [this.data.name, '', '', date];
    const headers = ['Nombre', 'Perfil', 'Distribuidor', 'Fecha de descarga'];

    this.data.users.forEach(
      (user: {
        name: any;
        profileId: number;
        dealerId: number;
        downloadDate: string | number | Date;
      }) => {
        const row = [
          user.name,
          this.getProfileName(user.profileId),
          this.getDealersName(user.dealerId),
          user.downloadDate
            ? formatDate(user.downloadDate, 'dd-MMM-yyyy', 'en-Us')
            : '',
        ];
        rows.push(row);
      }
    );

    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([]);

    // Establecer ancho mínimo para cada columna
    const columnWidths = [{ wch: 40 }, { wch: 40 }, { wch: 40 }, { wch: 40 }];

    worksheet['!cols'] = columnWidths;

    XLSX.utils.sheet_add_aoa(worksheet, [nameAndDateRow], { origin: 'A2' });
    XLSX.utils.sheet_add_aoa(worksheet, [['', '', '']], { origin: 'A3' });
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A4' });
    XLSX.utils.sheet_add_aoa(worksheet, rows, { origin: 'A5' });

    const titleMailshot = { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } };
    worksheet['!merges'] = [titleMailshot];

    const workbook: XLSX.WorkBook = {
      Sheets: { Circular: worksheet },
      SheetNames: ['Circular'],
    }; // Crear libro de trabajo
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    }); // Convierto el libro a un archivo binario
    this.saveAsExcelFile(excelBuffer, `detalle_${this.data.name}`);
  }

  getDealersName(id: number) {
    let profile = this.authService.currentDealers.find(
      (dealer: { id: number }) => dealer.id === id
    );
    return profile ? profile.name : '';
  }
}
