import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewStandardQuoteComponent } from './standard-quote/new-standard-quote.component';
import { AuthGuard } from '@app/common/auth.guard';
import { ListQuoteStandardComponent } from './standard-quote/list-quote-standard/list-quote-standard.component';
import { NewDealStandardQuoteComponent } from './standard-quote/deals-standard-quote/new-deal-standard-quote/new-deal-standard-quote.component';

const routes: Routes = [

  {
    canActivate: [AuthGuard],
    path: 'standard-quote',
    component: ListQuoteStandardComponent,
    data: {
      breadcrumb: {
        title: 'COTIZADOR',
        parent: 'Cotizador estándar',
        path: 'quote/standard-quote',
      },
      module: 'Cotizador',
    },
  },
  {
    canActivate: [AuthGuard],
    path: 'standard-quote/new-quote',
    component: NewStandardQuoteComponent,
    data: {
      breadcrumb: {
        title: 'COTIZADOR',
        parent: 'Cotizador estándar',
        children: 'Nueva cotización',
        path: 'quote/standard-quote',
      },
      module: 'Cotizador',
    },
  },
  {
    canActivate: [AuthGuard],
    path: 'standard-quote/edit-quote/:id',
    component: NewStandardQuoteComponent,
    data: {
      breadcrumb: {
        title: 'COTIZADOR',
        parent: 'Cotizador estándar',
        children: 'Editar cotización',
        path: 'quote/standard-quote',
      },
      module: 'Cotizador',
    },
  },

  {
    canActivate: [AuthGuard],
    path: 'standard-quote/new-quote/new-deal',
    component: NewDealStandardQuoteComponent,
    data: {
      breadcrumb: {
        title: 'COTIZADOR',
        parent: 'Cotizador estándar',
        children: 'Editar cotización',
        path: 'quote/standard-quote',
      },
      module: 'Cotizador',
    },
  },
  {
    canActivate: [AuthGuard],
    path: 'standard-quote/new-quote/edit-deal/:idDeal',
    component: NewDealStandardQuoteComponent,
    data: {
      breadcrumb: {
        title: 'COTIZADOR',
        parent: 'Cotizador estándar',
        children: 'Editar cotización',
        path: 'quote/standard-quote',
      },
      module: 'Cotizador',
    },
  },
  {
    canActivate: [AuthGuard],
    path: 'standard-quote/edit-quote/:id/edit-deal/:idDeal',
    component: NewDealStandardQuoteComponent,
    data: {
      breadcrumb: {
        title: 'COTIZADOR',
        parent: 'Cotizador estándar',
        children: 'Editar cotización',
        path: 'quote/standard-quote',
      },
      module: 'Cotizador',
    },
  },
  {
    canActivate: [AuthGuard],
    path: 'standard-quote/edit-quote/:id/new-deal',
    component: NewDealStandardQuoteComponent,
    data: {
      breadcrumb: {
        title: 'COTIZADOR',
        parent: 'Cotizador estándar',
        children: 'Editar cotización',
        path: 'quote/standard-quote',
      },
      module: 'Cotizador',
    },
  },
  

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuoteRoutingModule {}
