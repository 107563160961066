import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.base.service';
import { environment } from '@env/environment.local';

@Injectable({
  providedIn: 'root',
})
export class EntitiesServices {
  constructor(public api: ApiService) {}

  public listDealers(): Observable<any> {
    return this.api.get(`${environment.API_DEALEARS_SERVICE}/dealers`);
  }

  public listProfiles(): Observable<any> {
    return this.api.get(`${environment.API_USER_SERVICE}/users/profiles`);
  }
}
