import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MailshotComponent } from './mailshot/mailshot.component';
import { MailshotStaticComponent } from './mailshot static/mailshot-static.component';
import { AuthGuard } from '@app/common/auth.guard';
import { DetailsMailshotComponent } from './details-mailshot/details-mailshot.component';

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    path: 'admin-mailshot',
    component: MailshotComponent,
    data: {
      breadcrumb: {
        title: 'CIRCULARES',
        parent: 'Administración de circulares',
        path: 'mailshot/admin-mailshot',
      },
      module: 'Circulares'
    },
  },
  {
    canActivate: [AuthGuard],
    path: 'admin-mailshot/details/:id',
    component: DetailsMailshotComponent,
    data: {
      breadcrumb: {
        title: 'CIRCULARES',
        parent: 'Administración de circulares',
        path: 'mailshot/admin-mailshot',
        children: 'Detalle',
      },
      module: 'Circulares'
    },
  },
  {
    path: 'static',
    component: MailshotStaticComponent,
    data: {
      breadcrumb: {
        title: 'CIRCULARES',
        parent: 'CIRCULARES',
        path: 'mailshot',
      },
      module: 'Circulares'
    },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MailshotRoutingModule {}
