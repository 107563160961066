import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {

  @Input() total: number;
  @Input() pageSize: number;
  @Input() pageIndex: number = 0;
  @Output() changePage: EventEmitter<any> = new EventEmitter();
  @Input() label: string = 'Líneas por página';
  @Input() pageSizeOptions: number[] = [20, 50, 100, 150]; 


  
  page = this.pageIndex + 1;

  ngOnInit() {
  }

  onPageChange(page: number) {
    this.pageIndex = page - 1; 
    this.changePage.emit({ pageIndex: this.pageIndex, pageSize: this.pageSize });
  }

  onPageSizeChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.pageSize = Number(selectElement.value);
    this.pageIndex = 0;
    this.page = 1;
    this.changePage.emit({ pageIndex: this.pageIndex, pageSize: this.pageSize });
  }
}
