import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { MenusServices } from '@app/services/menus.service';
import { NotificationService } from '@app/services/notifications.service';
import { OpenQuoteService } from '../../open-quote/open-quote-service.service';
import { AuthService } from '@app/common/auth.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomerComponent),
      multi: true,
    },
  ],
})
export class CustomerComponent
  extends BaseComponent
  implements OnInit, ControlValueAccessor, Validator, OnChanges
{
  formCustomer: FormGroup;
  checked: boolean = false;
  btnDisabled: boolean = true;
  @Input() creationUser: any;
  @Output() requestOpenModal: EventEmitter<void> = new EventEmitter<void>();



  constructor(
    route: ActivatedRoute,
    menu: MenusServices,
    notification: NotificationService,
    private fb: FormBuilder,
    public authService: AuthService
  ) {
    super(route, menu, notification);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['creationUser']) {
      if (this.creationUser) {
        if (this.creationUser != this.authService.currentUserIds) {
          this.formCustomer.controls['personType'].disable();
          this.formCustomer.controls['firstName'].disable();
          this.formCustomer.controls['secondName'].disable();
          this.formCustomer.controls['lastName'].disable();
          this.formCustomer.controls['mothersLastName'].disable();
        }
      }
    }
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.formCustomer = this.fb.group({
      personType: ['LEGAL_PERSON', []],
      firstName: [, [Validators.required]],
      secondName: [, []],
      lastName: [, []],
      mothersLastName: [, []],
    });

    this.formCustomer.get('personType')?.valueChanges.subscribe(() => {
      this.validatorChange();
    });

    this.formCustomer.valueChanges.subscribe((value) => {
      this.onChange(value);
      this.onTouched();
      this.btnDisabled = !this.formCustomer.valid;
    });
  }

  validatorChange() {
    if (this.formCustomer.controls['personType'].value == 'LEGAL_PERSON') {
      this.formCustomer.controls['lastName'].clearValidators();
      this.formCustomer.controls['mothersLastName'].clearValidators();

      this.formCustomer.controls['secondName'].setValue(null);
      this.formCustomer.controls['lastName'].setValue(null);
      this.formCustomer.controls['mothersLastName'].setValue(null);

      this.formCustomer.controls['secondName'].setErrors(null);
      this.formCustomer.controls['lastName'].setErrors(null);
      this.formCustomer.controls['mothersLastName'].setErrors(null);
    } else {
      this.formCustomer.controls['lastName'].setValidators(Validators.required);
      this.formCustomer.controls['mothersLastName'].setValidators(
        Validators.required
      );
    }
    this.formCustomer.controls['lastName'].updateValueAndValidity();
    this.formCustomer.controls['mothersLastName'].updateValueAndValidity();
    this.formCustomer.updateValueAndValidity;
  }

  save() {
    if (this.validForm(this.formCustomer)) {
      this.checked = true;
    } else {
      this.showNotificationError(
        'Revisa los campos obligatorios antes de continuar.'
      );
    }
  }

  //#region CVA
  writeValue(value: any): void {
    if (value) {
      this.formCustomer.patchValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  private onChange = (_: any) => {};
  private onTouched = () => {};

  //#endregion

  //#region Validator
  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    return this.formCustomer.valid ? null : { invalidFormCustomer: true };
  }

  //#endregion

  onInputFocus() {
    this.requestOpenModal.emit();
  }

}
