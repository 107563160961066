import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '@app/components/base/base.component';
import { ModalService } from '@app/components/modal/modal.service';
import { StandardQuoteService } from '../../standard-quote-service.service';
import { MatDialog } from '@angular/material/dialog';
import {
  grace,
  payments,
  specialPayments,
} from '../../model/standard-quote.model';
import { Payment } from '../../model/standard-payments.model';
import { ActivatedRoute } from '@angular/router';
import { MenusServices } from '@app/services/menus.service';
import { NotificationService } from '@app/services/notifications.service';

@Component({
  selector: 'app-list-special-payments-grace-period',
  templateUrl: './list-special-payments-grace-period.component.html',
  styleUrls: ['./list-special-payments-grace-period.component.css'],
})
export class ListSpecialPaymentsGracePeriodComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @ViewChild('templateConfirmation') template!: TemplateRef<any>;
  @ViewChild('modalPayments') templateModalPayments! : TemplateRef<any>;

  @Input() specialPayments: specialPayments[];
  @Input() grace: grace[];
  @Input() payments: payments[];
  @Input() idQuote: any;
  @Input() idDeal: any;
  @Input() isEdit: boolean;
  @Input() result: any;
  @Output() updateSpecialPayments: EventEmitter<any> = new EventEmitter();
  @Output() updateGrace: EventEmitter<any> = new EventEmitter();

  period: any;
  periodList: any[];
  isLoading: boolean = false;
  listPayments: any[] = [];
  title: string = 'Eliminar pago';
  subtitle: string = '';
  btn1: string = 'Cancelar';
  btn2: string = 'Eliminar';

  constructor(
      route: ActivatedRoute,
      menuService: MenusServices,
      notification: NotificationService,
    private modalService: ModalService,
    private quoteService: StandardQuoteService,
    public dialog: MatDialog
  ) {
    super(route, menuService, notification);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['specialPayments'] || changes['grace']) {
      this.listPayments = [];
      if (this.specialPayments.length > 0) {
        this.specialPayments;
        this.specialPayments.map((i) => {
          this.listPayments.push({ ...i, paymentDate: i.specialPaymentDate });
        });
        this.listPayments.sort((a, b) => a.period - b.period);
      }
      if (this.grace.length > 0) {
        this.grace.map((i) => {
          this.listPayments.push({ ...i, paymentDate: i.gracePeriodDate });
        });
      }
      this.listPayments.sort((a, b) => a.period - b.period);
    }
    this.periodList = this.payments.filter((period) => {
      return (
        !this.grace.some((g) => g.period == period.period) &&
        !this.specialPayments.some((sp) => sp.period == period.period)
      );
    });
  }

  ngOnInit() {}

  getApplyTo(applyTo: string): string {
    if (applyTo == 'CAPITAL') {
      return 'Capital';
    } else if (applyTo == 'INTEREST') {
      return 'Interés';
    } else if (applyTo == 'TOTAL') {
      return 'Total';
    }
    return '';
  }

  getPaymentType(paymentType: string) {
    if (paymentType == 'SPECIAL_PAYMENT') {
      return 'Pago especial';
    } else if (paymentType == 'GRACE_PERIOD') {
      return 'Período de gracia';
    }
    return '';
  }

  closeModal() {
    this.modalService.closeModal();
  }

  openModalConfirmation(payment: any) {
    this.subtitle = `¿Confirma la eliminación del ${
      payment.paymentType == 'SPECIAL_PAYMENT'
        ? 'pago especial'
        : 'período de gracia'
    }?`;
    console.log(this.subtitle)
    this.btn1 = 'Cancelar';
    this.btn2 = 'Eliminar';
    this.modalService.component = this.template;
    this.modalService.openModal();
    this.period = payment;
  }

  edit(period: any) {
    let periodSelected: any;

    if (period.paymentType == 'SPECIAL_PAYMENT') {
      periodSelected = this.specialPayments.find(
        (i) => i.period == period.period
      );
    } else if (period.paymentType == 'GRACE_PERIOD') {
      periodSelected = this.grace.find((i) => i.period == period.period);
    }

    let model = {};

    if (periodSelected) {
      model = new Payment(periodSelected, period.paymentDate);
    } else {
      model = {
        id: null,
        period: period.period,
        paymentDate: period.paymentDate,
        paymentType: null,
      };
    }
    this.period = model;
    this.modalService.component = this.templateModalPayments;
    this.modalService.openModal();
  }

  newSpecialPayment() {
    const model = {
      id: null,
      period: null,
      paymentDate: null,
    };
    this.period = model;
    this.periodList = this.periodList
      .filter((i) => i.period != 0)
      .map((i) => {
        return { period: i.period, paymentDate: i.paymentDate };
      });
    this.modalService.component = this.templateModalPayments;
    this.modalService.openModal();
  }

  update(payment: any) {
    if (payment.paymentType == 'SPECIAL_PAYMENT') {
      this._updateSpecialPayment(payment);
    } else if (payment.paymentType == 'GRACE_PERIOD') {
      this._updateGrace(payment);
    }
  }

  _updateGrace(grace: any) {
    let graceSelected = {
      gracePeriodDate: grace.paymentDate,
      ...grace,
    };
    if (graceSelected.id) {
      const index = this.grace.findIndex((i) => i.id == grace.id);
      this.grace[index] = graceSelected;
    } else {
      this.grace.push(graceSelected);
    }

    this.updateGrace.emit(this.grace);
  }

  _updateSpecialPayment(specialPayment: any) {
    let specialPaymentSelected = {
      ...specialPayment,
      specialPaymentDate: specialPayment.paymentDate,
    };
    let amount : number = 0;
    this.specialPayments.map((i) => amount += i.amount);
    amount = Number(amount) + Number(specialPayment.amount);

    if(amount > this.result.financialAmount ){
      this.showNotificationError('La suma de los montos ingresados excede el monto a financiar.');
    }else{
      if (specialPaymentSelected.id) {
        const index = this.specialPayments.findIndex(
          (i) => i.id == specialPaymentSelected.id
        );
        this.specialPayments[index] = specialPaymentSelected;
      } else {
        this.specialPayments.push(specialPaymentSelected);
      }

      this.updateSpecialPayments.emit(this.specialPayments);
    }
  }

  delete(period: any) {
    if (period.paymentType == 'SPECIAL_PAYMENT') {
      this.deleteSpecialPayment(period);
    } else if (period.paymentType == 'GRACE_PERIOD') {
      this.deleteGrace(period);
    }
  }

  deleteSpecialPayment(period: any) {
    if (!period.id) {
      this.specialPayments = this.specialPayments.filter(
        (i) => i.id != period.id
      );
      this.updateSpecialPayments.emit(this.specialPayments);
    }
    this.modalService.component = null;
    this.modalService.closeModal();
    this.isLoading = true;
    this.quoteService
      .deleteSpecialPayments(this.idQuote, this.idDeal, period.id)
      .subscribe({
        next: (response) => {
          this.specialPayments = this.specialPayments.filter(
            (i) => i.id != period.id
          );
          this.updateSpecialPayments.emit(this.specialPayments);
          this.showNotificationSuccess(
            'Se ha eliminado con éxtio el pago especial.'
          );
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.showNotificationError(
            'Ha ocurrido un error al eliminar pago especial.'
          );
        },
      });
  }

  deleteGrace(period: any) {
    if (!period.id) {
      this.grace = this.grace.filter((i) => i.id != period.id);
      this.updateGrace.emit(this.grace);
    }
    this.modalService.component = null;
    this.modalService.closeModal();
    this.isLoading = true;
    this.quoteService
      .deleteGrace(this.idQuote, this.idDeal, period.id)
      .subscribe({
        next: (response) => {
          this.grace = this.grace.filter((i) => i.id != period.id);
          this.updateGrace.emit(this.grace);
          this.showNotificationSuccess(
            'Se ha eliminado con éxtio el período de gracia.'
          );
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.showNotificationError(
            'Ha ocurrido un problema al eliminar período de gracia.'
          );
        },
      });
  }

  ngOnDestroy() {
    this.modalService.component = null;
    this.modalService.closeModal();
  }
}
