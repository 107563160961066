import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FinancialProductEnum } from '../../model/standard-quote.model';
import { AuthService } from '@app/common/auth.service';
import {Util} from "@app/core/util/Util";
import { BaseComponent } from '@app/components/base/base.component';
import { NotificationService } from '@app/services/notifications.service';
import { ActivatedRoute } from '@angular/router';
import { MenusServices } from '@app/services/menus.service';


@Component({
  selector: 'app-column-deal-standard',
  templateUrl: './column-deal-standard.component.html',
  styleUrls: ['./column-deal-standard.component.css'],
})
export class ColumnDealStandardComponent extends BaseComponent implements OnInit  {
  @Input() deals: any[];
  @Input() creationUser: any;

  @Output() isSelected: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() duplicate: EventEmitter<any> = new EventEmitter();

  constructor(public authService: AuthService, notification: NotificationService, route: ActivatedRoute, menuService: MenusServices,) {
    super(route, menuService, notification);
  }

  ngOnInit() {}

  getFinancialProduct(financialProductType: FinancialProductEnum) {
    if (financialProductType == FinancialProductEnum.directCredit) {
      return 'Crédito directo';
    } else if (financialProductType == FinancialProductEnum.financialLeasing) {
      return 'Arrendamiento financiero';
    } else if (financialProductType == FinancialProductEnum.pureLease) {
      return 'Arrendamiento puro';
    } else {
      return '';
    }
  }

  showInsuranceVehicle(deal: any) {
    return Util.showInsuranceVehicle(deal);
  }
  
  toggleSelection(event: MouseEvent, index: number,id:number): void {
    const selectedCount = this.deals.filter(deal => deal.isSelected).length;
    if (this.deals[index].isSelected) {
      this.deals = this.deals.map((i)=>{return{...i,isSelected: i.id == id ? !this.deals[index].isSelected : i.isSelected}})
      this.isSelected.emit(id)
    } else {
      if (selectedCount >= 6) {
        this.showNotificationError('Ha excedido el número máximo de propuestas permitidas.');
        event.preventDefault();
      } else {
        this.deals = this.deals.map((i)=>{return{...i,isSelected: i.id == id ? !this.deals[index].isSelected : i.isSelected}})
        this.isSelected.emit(id)
      }
    }
  }
}
