import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, finalize, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, filter, Observable, of, throwError } from 'rxjs';
import { NotificationService } from '../services/notifications.service';
import { SessionStorageService } from '../services/session-storage.service';
import { LoginService } from '../pages/login/services/login.service';
import { environment } from '@env/environment.local';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  messages!: string[];
  private refreshTokenInProgress: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private notificationService: NotificationService,
    private loginService: LoginService,
    private sessionStorageService: SessionStorageService
  ) {
    this.messages = [];
  }

  /**
   * @description Intercepta todos los HttpErrorResponse del sistema
   * y muestra el mensaje correspondiente al usuario segun su status.
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    req = this.addAuthenticationToken(req);
    return next.handle(req).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        this.sessionStorageService.removeItem(environment.TOKEN);
        if (
          errorResponse &&
          (errorResponse.status === 401 || errorResponse.status === 403)
        ) {
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter((result) => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(req)))
            );
          } else {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            return this.refreshAccessToken().pipe(
              switchMap((data: any) => {
                this.refreshTokenSubject.next(data.data.freshToken);
                return next.handle(this.addAuthenticationToken(req));
              }),
              finalize(() => (this.refreshTokenInProgress = false))
            );
          }
        } else if (errorResponse && errorResponse.status === 500) {
          let errorMessage: any;
          errorMessage = `Error: ${errorResponse.error.message}`;
          this.messages.push(
            'Hubo un error de conexión con nuestro servidor. Por favor, intente nuevamente o solicite soporte.'
          );
          this.notificationService.showMessage(this.messages, 'error');
          setTimeout(() => {
            this.messages = [];
            this.notificationService.clearMessages();
          }, 4000);
          return throwError(() => errorMessage);
        } else {
          return throwError(() => errorResponse);
        }
      })
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    if (!this.sessionStorageService.getItem(environment.TOKEN)) {
      return request;
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.sessionStorageService.getItem(
          environment.TOKEN
        )}`,
      },
    });
  }

  private refreshAccessToken(): Observable<any> {
    const setHeaders = {
      Authorization: `Bearer ${this.sessionStorageService.getItem(
        environment.REFRESH_TOKEN
      )}`,
    };
    return this.loginService.refreshToken(setHeaders).pipe(
      switchMap((data: any) => {
        this.sessionStorageService.setItem(environment.TOKEN, data.data.jwt);
        this.sessionStorageService.setItem(
          environment.REFRESH_TOKEN,
          data.data.refreshToken
        );
        this.refreshTokenInProgress = false;
        return of(data);
      }),
      catchError((err) => {
        this.refreshTokenInProgress = false;
        return of('error');
      })
    );
  }
}
