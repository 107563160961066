<div id="financialService" class="module readOnly" *ngIf="financialService">
  <h3>Servicio financiero</h3>
  <div>
    <!-- Incluir clase "simple", "conSucursal" o "conDistribuidor" en el div.wrapper según corresponda -->
    <div class="wrapper conDistribuidor">
      <div>
        <label>Compañía</label>
        <span class="value">{{ financialService.companyName }}</span>
      </div>

      <!-- Mostrar solo si se seleccionó Distribuidor -->
      <div>
        <label>Distribuidor</label
        ><span class="value">
          <app-label-dealer
            [dealerId]="dealerId"
          ></app-label-dealer
        ></span>
      </div>

      <!-- Mostrar solo si se seleccionó Sucursal -->
      <div>
        <label>Sucursal</label><span class="value">
          {{ dealerBranchName ? dealerBranchName : 'No se encuentra sucursal' }}
        </span>
      </div>

      <div>
        <label>Nombre de la cotización</label>
        <span class="value">{{ financialService.quoteName }}</span>
      </div>

      <div *ngIf="financialService.id">
        <label>Número</label>
        <span class="value">{{ financialService.id }}</span>
      </div>
    </div>
  </div>
</div>
