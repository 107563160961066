import {customerDto, financialSetup, models} from './standard-quote.model';
import {WholeSale} from "../../../../core/model/CoreModel";
import {DealerBranch} from "@app/pages/quote/standard-quote/model/dealer.model";
import {Constants} from "@app/constants/Constants";

export class CreateCoverage {
  financialProduct: string;
  classification: boolean;
  modelType: string;
  subBrandName: string;
  modelCode: string;
  brandName: string;
  modelName: string;
  modelYear: number;
  udi: string;
  customer: customerDto;
  panel: any;
  Estado: string;
  EstadoId: number;
  loadType: string;
  term: number;
  quantityPassengers: number;
  quantityTrailers: number;
  companyName: string;
  vehicleServiceType: string;
  price: number;
  insuranceType: string;
  financialSetupId: number;
  marshAgencyId : number;
  marshAgencyName: string;

  constructor(
    financialSetup: financialSetup,
    financialCondition: any,
    model: models,
    panel: any,
    customer: customerDto,
    insurance: any,
    dealerBranch: DealerBranch,
    companyName: any,
    standardQuoteModel: models | undefined
  ) {
    (this.financialProduct = financialSetup.financialProduct),
      (this.classification = model.classification);
    this.modelType = model.modelType;
    this.subBrandName = model.subBrand.name;
    this.modelCode = model.modelCode;
    this.price = standardQuoteModel? standardQuoteModel.price : model.price;
    this.brandName = model.name;
    this.modelName = model.name;
    this.modelYear = model.year;
    this.customer = customer;
    this.panel = panel;
    this.vehicleServiceType = insurance.vehicleServiceType
    this.udi = '20';
    this.Estado = insurance.stateName;
    this.EstadoId = insurance.state;
    this.quantityPassengers = insurance.quantityPassengers
    this.quantityTrailers = insurance.quantityTrailes
    // this.loadType = insurance.loadType;
    this.loadType = 'A';
    this.companyName = companyName;
    this.term = financialCondition.term;
    this.financialSetupId = financialSetup.id;
    this.insuranceType = insurance.insuranceType;
    this.marshAgencyId = dealerBranch.dtfsMarshId || dealerBranch.sofomMarshId ; // TODO: Check if this is the correct value sofomMarshId or dtfsMarshId
    this.marshAgencyName = dealerBranch.name;
  }
}

export class GetCoverage {
  financialProduct: string;
  classification: boolean;
  modelType: string;
  modelCode: string;
  udi: string;
  subBrandName: string;
  vehicleServiceType: string;
  insuranceType: string;
  price: number;
  stateId: number;
  companyName: string;

  constructor(financialSetup: financialSetup, model: models, insurance: any, standardQuoteModel: models, companyName: string) {
    this.financialProduct = financialSetup.financialProduct;
    this.classification = model.classification;
    this.modelType = model.modelType;
    this.modelCode = model.modelCode;
    this.price = standardQuoteModel? standardQuoteModel.price :  model.price;
    this.udi = Constants.UDI_DEFAULT;
    this.subBrandName = model.subBrand.name;
    this.vehicleServiceType = insurance.vehicleServiceType;
    this.stateId= insurance.state;
    this.insuranceType = insurance.insuranceType;
    this.companyName = companyName;
  }
}

export class GetUdi {
  financialProduct: string;
  classification: boolean;
  modelType: string;

  constructor(financialSetup: financialSetup, model: models) {
    this.financialProduct = financialSetup.financialProduct;
    this.classification = model.classification;
    this.modelType = model.modelType;
  }
}
