<!-- Incluir en el div id="notificacions" la clase "error", "success" según corresponda -->
<!-- dejar sin ninguna clase si se quiere mostrar un mensaje genérico -->
<div id="notifications">
  <div
    class="notification"
    *ngFor="let notification of notificationService.notifications"
    [ngClass]="{
      error: notificationService.type === 'error',
      success: notificationService.type === 'success'
    }"
  >
    <div class="wrapper">
      <div class="pageWidth">
        <div class="messages">
          <img
            *ngIf="notificationService.type === 'error'"
            src="/assets/images/icons/notification_error.svg"
          />
          <img
            *ngIf="notificationService.type === 'success'"
            src="/assets/images/icons/notification_success.svg"
          />
          <p>
            {{ notification }}
          </p>
        </div>
        <a (click)="notificationService.clearMessages()" class="close"
          >Cerrar</a
        >
      </div>
    </div>
  </div>
</div>
