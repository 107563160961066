<!-- FORM MULTISELECT - Con input para buscar  -->
<div class="form-multiselect form-multiselect--open">
  <div class="form-multiselect__input-wrap">
    <!-- Incluir <input> para ingresar texto de búsqueda, si está vacío incluir la clase "form-multiselect__input--empty" -->
    <input
      [placeholder]="data.placeholder" 
      [(ngModel)]="data.value"
      class="form-multiselect__input "
      [ngClass]="{'form-multiselect__input--empty': data.value}" 
      (keydown.enter)="search();$event.preventDefault();"
      (ngModelChange)="searchChange()"
    />
    <span class="form-multiselect__close" [style.display]="data.value ? 'block' : 'none'" (click)="reset()"></span>
    <div class="button button--dark" (click)="search()" *ngIf="showIcon"><i class="icon"></i></div>
  </div>
</div>
