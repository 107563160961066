<section>
  <app-preloader *ngIf="this.isLoading"></app-preloader>
  <app-notifications></app-notifications>
  <div id="login">
    <div class="wrapper">
      <!-- Primer paso -->
      <ng-container *ngIf="this.step1">
        <h1>{{ "Change password title" | translate }}</h1>
        <p>
          {{ "Change password text" | translate }}
        </p>
        <form
          [formGroup]="form"
          (ngSubmit)="onSubmit()"
          [ngClass]="{ 'disable-enter': disabledButton }"
        >
          <div class="inputWrapper">
            <input type="password" placeholder=" " formControlName="password" />
            <label class="floatingLabel"
              >{{ "Currently password" | translate }}
              <span class="required">*</span></label
            >
          </div>

          <div class="sendWrapper">
            <button
              #sendButton
              (click)="onSubmit()"
              [disabled]="disabledButton"
            >
              {{ "Continue" | translate }}
            </button>
          </div>
        </form>
      </ng-container>
      <!-- Segundo paso -->
      <ng-container *ngIf="this.step2">
        <h1>{{ "New password" | translate }}</h1>
        <p>{{ "Create new password text" | translate }}</p>
        <form
          [formGroup]="formChangePassword"
          (ngSubmit)="onSubmitChangePassword()"
          [ngClass]="{ 'disable-enter': disabledButton }"
        >
          <div class="strongPassword">
            <p>{{ "Password rules" | translate }}</p>
            <ul>
              <li>
                <span
                  class="icon"
                  [ngClass]="{
                    confirmation: passwordValidation['uppercase'],
                    error: !passwordValidation['uppercase']
                  }"
                  >{{ passwordValidation['uppercase'] ? "" : "" }}</span
                >{{ "Uppercase rule" | translate }}
              </li>
              <li>
                <span
                  class="icon"
                  [ngClass]="{
                    confirmation: passwordValidation['digit'],
                    error: !passwordValidation['digit']
                  }"
                  >{{ passwordValidation['digit'] ? "" : "" }} </span
                >{{ "Digit rule" | translate }}
              </li>
              <li>
                <span
                  class="icon"
                  [ngClass]="{
                    confirmation: passwordValidation['length'],
                    error: !passwordValidation['length']
                  }"
                  >{{ passwordValidation['length'] ? "" : "" }}</span
                >{{ "Character rule" | translate }}
              </li>
              <li>
                <span
                  class="icon"
                  [ngClass]="{
                    confirmation: passwordValidation['validCharacters'],
                    error: !passwordValidation['validCharacters']
                  }"
                  >{{ passwordValidation['validCharacters'] ? "" : "" }}</span
                >{{ "Character valid" | translate }}
              </li>
              <li>
                <span
                  class="icon"
                  [ngClass]="{
                    confirmation: passwordValidation['lowercase'],
                    error: !passwordValidation['lowercase']
                  }"
                  >{{ passwordValidation['lowercase'] ? "" : "" }}</span
                >{{ "Lowercase" | translate }}
              </li>
              <li>
                <span
                  class="icon"
                  [ngClass]="{
                    confirmation: passwordValidation['special'],
                    error: !passwordValidation['special']
                  }"
                  >{{ passwordValidation['special'] ? "" : "" }}</span
                >{{ "Character special" | translate }}
              </li>

              <li
                *ngIf="
                  formChangePassword.controls['confirmNewPassword'].errors
                    ?.['mustMatch']
                "
              >
                <span class="icon error"></span
                >{{ "Passwords not agree" | translate }}
              </li>
              <li
                *ngIf="
                  !formChangePassword.controls['confirmNewPassword'].errors
                    ?.['mustMatch']
                "
              >
                <span class="icon confirmation"></span
                >{{ "Passwords agree" | translate }}
              </li>
            </ul>
          </div>

          <div class="controls">
            <div class="inputWrapper">
              <input
                type="password"
                placeholder=""
                formControlName="newPassword"
                [ngClass]="{
                  error:
                    formChangePassword.controls['newPassword'].invalid &&
                    formChangePassword.controls['newPassword'].touched
                }"
              />
              <label class="floatingLabel"
                >{{ "User input password" | translate
                }}<span class="required">*</span></label
              >
            </div>
            <div class="inputWrapper">
              <input
                type="password"
                placeholder=" "
                formControlName="confirmNewPassword"
                [ngClass]="{
                  error:
                    formChangePassword.controls['confirmNewPassword'].invalid &&
                    formChangePassword.controls['confirmNewPassword'].touched
                }"
              />
              <label class="floatingLabel"
                >{{ "Repeat password" | translate }}
                <span class="required">*</span></label
              >
            </div>
          </div>
          <div class="sendWrapper">
            <button
              #sendButton2
              (click)="onSubmitChangePassword()"
              [disabled]="disabledButton"
            >
              {{ "Continue" | translate }}
            </button>
          </div>
        </form>
      </ng-container>
      <!-- Tercer paso -->
      <ng-container *ngIf="this.step3">
        <h1>{{ "New password create title" | translate }}</h1>
        <p>{{ "New password create text" | translate }}</p>
        <a [routerLink]="['/']" (click)="authService.logout()">{{
          "Back to init" | translate
        }}</a>
      </ng-container>
    </div>
  </div>
</section>