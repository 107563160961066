import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../services/api.base.service';
import { environment } from '@env/environment.local';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(public api: ApiService) {}

  public login(model: any): Observable<any> {
    return this.api.post(
      `${environment.API_AUTH_SERVICE}/auth/authenticate`,
      model
    );
  }
  public refreshToken(headers: any): Observable<any> {
    return this.api.refreshToken(
      `${environment.API_AUTH_SERVICE}/auth/refresh/token`,
      null, true, headers
    );
  }

  public verifyOtp(model: any): Observable<any> {
    return this.api.post(
      `${environment.API_AUTH_SERVICE}/auth/verify/otp`,
      model
    );
  }

  public resetPassword(email: any): Observable<any> {
    return this.api.put(
      `${environment.API_USER_SERVICE}/users/${email}/password`
    );
  }
}