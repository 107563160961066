import { OnInit, Component, Input, HostListener } from '@angular/core';
import { AuthService } from '@app/common/auth.service';
import { InternalNotificationsService } from '@app/pages/internal-notifications/services/internal-notificacions.service';
import { UsersService } from '@app/pages/users/services/users.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['headers.component.css'],
})
export class HeadersComponent extends BaseComponent implements OnInit{
  constructor(
    public authService: AuthService,
    private userService: UsersService,
    public notificationService: InternalNotificationsService
  ) {
    super();
  }
  isComponentOpen: boolean = false;
  notificationsWithoutSeen: number = 0;
  userId: any = '';

  ngOnInit() {
    this.userId = this.authService.currentUserIds;
    if (this.userId != '' && this.userId != null) {
      this.notificationService.notificationsWithoutSeen(this.userId);
    }
  }

  @Input('hideLinks') hideLinks: boolean = false;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const profileToggleElement = document.getElementById('profile-toggle');

    if (!profileToggleElement?.contains(clickedElement)) {
      this.isComponentOpen = false;
    }
  }

  toggleComponent() {
    this.isComponentOpen = !this.isComponentOpen;
  }
}
