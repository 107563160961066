<footer>
  <div id="actions">
    <div class="pageWidth">
      <ul class="general">
        <li>
          <a (click)="openModalHelp()"><img src="./assets/images/icons/help.svg" alt="Ícono ayuda" />Ayuda</a>
        </li>
        <!-- <li>
          <a href="#"
            ><img
              src="./assets/images/icons/faq.svg"
              alt="Ícono preguntas frecuentes"
            />Preguntas frecuentes</a
          >
        </li>
        <li>
          <a href="#"
            ><img
              src="./assets/images/icons/map.svg"
              alt="Ícono mapa del sitio"
            />Mapa del sitio</a
          >
        </li> -->
      </ul>

      <div class="wrapper">
        <ul class="legal">
          <li><a href="/info/directory">Directorio</a></li>
          <li><a href="/info/tyc">Términos de uso</a></li>
          <li><a href="/info/cookies">Cookies</a></li>
          <li>
            <a href="/info/privacy-statment">Declaración de privacidad</a>
          </li>
        </ul>

        <!-- <div class="profile" *ngIf="!isLoggedIn" id="profile">
        <img src="./assets/images/icons/profile.svg" alt="Ícono perfil" />
        <a href="/" id="login">Ingresar</a>
        <span>|</span>
        <a href="/register" id="signup">Registrarse</a>

        <div
          class=""
          *ngIf="isLoggedIn"
          id="profile"
          (click)="toggleMenu()"
        ></div>
        <div
          class="popup profile-formular"
          *ngIf="isLoggedIn"
          style="height: 82px; z-index: 100"
          [ngStyle]="{ visibility: isMenuOpen ? 'visible' : 'hidden' }"
        >
          <a class="popup-close" style="display: none"></a>
          <a href="/profile" routerLink="profile">Mi perfil</a>
          <a href="" (click)="logout()">Salir</a>
        </div>
      </div> -->
        <div class="toTop">
          <a (click)="scrollToTop()"
            ><img src="./assets/images/icons/top.svg" alt="Ícono subir" /><span
              >Subir</span
            ></a
          >
        </div>
      </div>
    </div>
  </div>
  <div id="copy">
    <div class="pageWidth">
      <p class="dtfs">© 2024 Daimler Truck Financial Services.</p>
      <p class="version">Version {{ environment.version }}</p>
    </div>
  </div>
</footer>
<ng-template #templateConfirmation>
  <div class="modal ayuda">
    <div class="toolbar">
      <div class="title">{{ "Help title" | translate }}</div>
      <button mat-icon-button mat-dialog-title (click)="closeModal()" class="close">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="content">
      <h2>{{ "Dear user" | translate }}</h2>
      <p>{{ "Help text" | translate }}</p>
      <p>{{ "Help text 1" | translate }}</p>
      <p>{{ "Help text 2" | translate }}</p>
      <div class="support">
        <div>
          <h2>Erika Canales</h2>
          <a href="mailto:erika.canales@daimlertruck.com">{{"erika.canales&#64;daimlertruck.com"}}</a>
          <span>(55) 6966-6739</span>
        </div>
        <div>
          <h2>Ana Lopez</h2>
          <a href="mailto:ana.a.lopez@daimlertruck.com">{{mail}}</a>
          <span>(55) 6961-0939</span>
        </div>
      </div>
      <div class="actions">
        <app-button [text]="'Cerrar'" (click)="closeModal()"></app-button>
      </div>
    </div>
  </div>
</ng-template>
