import packageJson from '../../package.json';

export const environment = {
  production: false,
  version: packageJson.version,
  pageSize: 50,
  email: 'ana.a.lopez@daimlertruck.com',
  TOKEN: 'jwt',
  REFRESH_TOKEN: 'refreshToken',
  DEALERS_SITE:
    'https://qa-dealers.daimlertruck-financialservices.mx/SitioDistribuidoresB/app/home/home.zul?',
  API_AUTH_SERVICE: 'https://dealers-back-qa.bshouse.io/auth-service/api/v1',
  API_DEALEARS_SERVICE:
    'https://dealers-back-qa.bshouse.io/dealer-service/api/v1',
  API_NOTIFICATION_SERVICE:
    'https://dealers-back-qa.bshouse.io/notification-service/api/v1',
  API_USER_SERVICE: 'https://dealers-back-qa.bshouse.io/user-service/api/v1',
  API_WHOLESALE_SERVICE:
    'https://dealers-back-qa.bshouse.io/wholesale-service/api/v1',
  API_PROCOTIZA:
    'https://qa-ms.daimlertruck-financialservices.mx/middleware/telepro/v1/refresh-token',
  API_MAILSHOT_SERVICE:
    'https://dealers-back-qa.bshouse.io/mailshot-service/api/v1',
  API_FILE_SERVICE: 'https://dealers-back-qa.bshouse.io/file-service/api/v1',
  API_QUOTE_SERVICE: 'https://dealers-back-qa.bshouse.io/quote-service/api/v1',
  activarDiro:true,
  testDiro:false,
  API_DIRO_SERVICE: 'https://dealers-back-dev.bshouse.io/diro-service/api/v1',
  downloadJson: true
};