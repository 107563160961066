<div id="quotation" class="module">
  <h3>Cotización</h3>
  <div class="noData" *ngIf="!quoteResult && !coverageResult">
    No hay información para mostrar
  </div>

  <ng-container *ngIf="quoteResult || coverageResult">
    <div class="tableWrapper">
      <div>
        <ng-container *ngIf="quoteResult">
          <div class="title">
            <h4>Producto financiero</h4>
          </div>
          <!-- Tabla Cotizacion -->
          <div class="customTable cotizacion">
            <div class="header">
              <div class="cell concepto">Concepto</div>
              <div class="cell unidad">Por unidad</div>
              <div class="cell total">
                Total ({{ quantity != 0 ? quantity + " unidades" : "" }})
              </div>
            </div>

            <div class="row">
              <div class="cell concepto">Precio de la unidad con IVA</div>
              <div class="cell unidad">
                {{ quoteResult.unitPrice | currency : "" : "" }}
              </div>
              <div class="cell total">
                {{ quoteResult.unitPrice * quantity | currency : "" : "" }}
              </div>
            </div>

            <div class="row">
              <div class="cell concepto">Tasa al cliente</div>
              <div class="cell unidad">
                {{
                (quoteResult.annualPercentageRate).toFixed(2) + " %"
                }}
              </div>
              <div class="cell total">
                {{
                (quoteResult.annualPercentageRate).toFixed(2) + " %"
                }}
              </div>
            </div>

            @if (this.financialProduct != 'PURE_LEASING'){
            <div class="row">
              <div class="cell concepto">Monto enganche</div>
              <div class="cell unidad">
                {{ quoteResult.downpaymentAmount | currency : "" : "" }}
              </div>
              <div class="cell total">
                {{ quoteResult.downpaymentAmount * quantity | currency : "" : "" }}
              </div>
            </div>
            }

            <div class="row">
              <div class="cell concepto">Comisión por apertura</div>
              <div class="cell unidad">
                {{ quoteResult.openingfeeAmount | currency : "" : "" }}
              </div>
              <div class="cell total">
                {{
                quoteResult.openingfeeAmount * quantity | currency : "" : ""
                }}
              </div>
            </div>
            <div class="row">
              <div class="cell concepto">Depósito en garantia</div>
              <div class="cell unidad">
                {{ quoteResult.guaranteeDepositAmount | currency : "" : "" }}
              </div>
              <div class="cell total">
                {{
                quoteResult.guaranteeDepositAmount * quantity | currency : "" : ""
                }}
              </div>
            </div>
            <div class="row">
              <div class="cell concepto">Seguro de vehículo</div>
              <div class="cell unidad">
                {{ showAmount(coverageResult) | currency : "" : "" }}
              </div>
              <div class="cell total">
                {{
                showAmount(coverageResult) * quantity | currency : "" : ""
                }}
              </div>
            </div>
            <div class="row">
              <div class="cell concepto">Seguro de vida</div>
              <div class="cell unidad">
                {{ quoteResult.insuranceAmount | currency : "" : "" }}
              </div>
              <div class="cell total">
                {{
                quoteResult.insuranceAmount * quantity | currency : "" : ""
                }}
              </div>
            </div>
            <div class="row">
              <div class="cell concepto">Gastos de ratificación</div>
              <div class="cell unidad">
                {{
                quoteResult.confirmationExpensesAmount | currency : "" : ""
                }}
              </div>
              <div class="cell total">
                {{
                  quoteResult.confirmationExpensesAmount | currency : "" : ""
                }}
              </div>
            </div>
            <div class="row">
              <div class="cell concepto">Pago inicial</div>
              <div class="cell unidad">
                {{ quoteResult.initialPayment | currency : "" : "" }}
              </div>
              <div class="cell total">
                {{ quoteResult.initialPayment * quantity | currency : "" : "" }}
              </div>
            </div>
            <div class="row">
              <div class="cell concepto">Monto a financiar</div>
              <div class="cell unidad">
                {{ quoteResult.financialAmount | currency : "" : "" }}
              </div>
              <div class="cell total">
                {{
                quoteResult.financialAmount * quantity | currency : "" : ""
                }}
              </div>
            </div>
            <!-- Opción de compra -->
            @if (this.financialProduct == 'FINANCIAL_LEASING'){
            <div class="row">
              <div class="cell concepto">Opción de compra</div>
              <div class="cell unidad">
                {{ quoteResult.purchaseOptionAmount | currency : "" : "" }}
              </div>
              <div class="cell total">
                {{ quoteResult.purchaseOptionAmount * quantity | currency : "" : "" }}
              </div>
            </div>
            }

            <div class="row">
              <div class="cell concepto">Pago mensual</div>
              <div class="cell unidad">
                {{ quoteResult.monthlyPayment | currency : "" : "" }}
              </div>
              <div class="cell total">
                {{ quoteResult.monthlyPayment * quantity | currency : "" : "" }}
              </div>
            </div>
            @if (this.financialProduct == 'PURE_LEASING'){
              <div class="row">
                <div class="cell concepto">Valor residual</div>
                <div class="cell unidad">
                  {{ quoteResult.residualValue | currency : "" : "" }}
                </div>
                <div class="cell total">
                  {{ quoteResult.residualValue * quantity | currency : "" : "" }}
                </div>
              </div>
              }
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="coverageResult && coverageResult.packageList">
        <div>
          <div class="title">
            <h4>Seguro</h4>
            <div class="aseguradora">
              <img src="./assets/images/insurance/logo_qualitas.png" alt="Qualitas"
                *ngIf="coverageResult.insuranceId == insuranceEnum.qualitas" />
              <img src="./assets/images/insurance/logo_gnp_seguros.png" alt="GNP Seguros"
                *ngIf="coverageResult.insuranceId == insuranceEnum.gnp_seguros" />
              <img src="./assets/images/insurance/logo_hdi_seguros.png" alt="HDI Seguros"
                *ngIf="coverageResult.insuranceId == insuranceEnum.hdi_seguros" />
            </div>
          </div>
          <!-- Tabla Pagos especiales -->
          <div class="customTable seguro">
            <div class="header">
              <div class="cell concepto">Coberturas</div>
              <div class="cell unidad">Unidad</div>
              <div class="cell total">
                Total ({{ quantity != 0 ? quantity + " unidades" : "" }})
              </div>
            </div>

            <div class="row" *ngFor="
                let coverage of coverageResult.packageList.coverageListModel
              ">
              <div class="cell concepto">
                {{ coverage.name }}
                <span class="note">{{ coverage.deductible }}</span>
              </div>
              <div class="cell unidad">{{ coverage.sumAssured }}</div>
              <!-- <div class="cell total"></div> -->
            </div>
            <!-- <div class="row totales">
              <div class="cell concepto">Total</div>
              <div class="cell unidad">
                {{
                  coverageResult.packageList.amount
                    | currency : "" : "" : "0.0-8"
                }}
              </div>
              <div class="cell total"></div>
            </div> -->
            <!-- <app-coverage-result
          [coverageResult]="coverageResult"
        ></app-coverage-result> -->
            <!-- </div> -->
          </div>
        </div>
      </ng-container>
    </div>
    <div class="additional">
      <p class="note">
        Los precios presentados aplican para una sola unidad y no consideran
        pagos programados.<br />Cuando el plazo comparado se muestre en $0.00 es
        debido a que la aseguradora seleccionada no presenta oferta para el
        mismo.
      </p>
    </div>
  </ng-container>
</div>
