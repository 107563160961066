import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-mailshot-mobile',
  templateUrl: './mailshot-mobile.component.html',
  styleUrls: ['./mailshot-mobile.component.css']
})
export class MailshotMobileComponent extends BaseComponent implements OnInit {

  @Input() listMailshot: any[];
  @Input() role: string;
  @Input() module: string;
  @Input() hasData: boolean;
  @Output() download: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  getType(type: string){
    switch (type){
    case 'application/pdf':
      return 'PDF'
    case 'application/vnd.ms-excel':
        return 'XLS'
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'XLSX' 
    case 'application/zip':
      return 'ZIP'
    default:
      return ''
    }
  }

  downloadEmit(data:any){
    this.download.emit(data)
  }
  getTableRowClass(item: any): string {
    if (!item.downloadedByUser && this.role !== this.rolesInternal.administrator) {
      return 'row unread';
    } else if (this.role === this.rolesInternal.administrator) {
      return 'row';
    }
    return 'row'; // Si ninguna condición se cumple, no se aplica ninguna clase adicional
  }
}
