<app-preloader *ngIf="isLoading"></app-preloader>
<app-notifications></app-notifications>

<div class="modal" *ngIf="currentStep == 1">
  <a class="close" (click)="onClose()">Cerrar</a>
  <h1>Consultar coberturas</h1>
  <mat-dialog-content class="customTable consultarCoberturas">
    <div class="headerSticky">
      <div class="header">
        <div class="cell check"></div>
        <div class="cell cobertura">Coberturas</div>
        <div class="cell suma">Suma asegurada</div>
        <div class="cell deducibles">Deducibles</div>
        <div class="cell aseguradora" *ngFor="let insurer of insurers">
          <ng-container *ngIf="insurer.insurerId === 222">
            <img
              src="./assets/images/insurance/logo_qualitas.png"
              alt="Qualitas"
            />
          </ng-container>
          <ng-container *ngIf="insurer.insurerId === 14625">
            <img
              src="./assets/images/insurance/logo_gnp_seguros.png"
              alt="GNP Seguros"
            />
          </ng-container>
          <ng-container *ngIf="insurer.insurerId === 240819">
            <img
              src="./assets/images/insurance/logo_hdi_seguros.png"
              alt="HDI Seguros"
            />
          </ng-container>
        </div>
      </div>
    </div>

    <form
      [formGroup]="coverage"
      *ngFor="let coverage of forms; let i = index"
      class="row"
    >
      <div class="cell check">
        <label class="checkboxWrapper">
          <input
            type="checkbox"
            formControlName="selected"
            (ngModelChange)="getRecalculateCoverage(coverages[i].special, i)"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="cell cobertura">{{ coverages[i].coverageName }}</div>
      <div class="cell suma">
        <div *ngIf="coverages[i].isMaskingSumsInsured && !coverages[i].special">
          {{
            isNumberString(coverages[i].maskingSumsInsured)
              ? (coverages[i].maskingSumsInsured | currency : "" : "")
              : coverages[i].maskingSumsInsured
          }}
        </div>
        <div
          *ngIf="
            coverages[i].modelRange.rangesSums.length > 1 &&
            !coverages[i].special &&
            !coverages[i].isMaskingSumsInsured
          "
        >
          <div class="selectWrapper">
            <div class="customSelect">
              <select
                formControlName="filterValueRangeSum"
                (ngModelChange)="
                  getRecalculateCoverage(coverages[i].special, i)
                "
              >
                <option
                  *ngFor="let range of coverages[i].modelRange.rangesSums"
                  [value]="range"
                >
                  {{ range | currency : "" : "" }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            coverages[i].modelRange.rangesSums.length == 1 &&
            !coverages[i].special &&
            !coverages[i].isMaskingSumsInsured
          "
        >
          {{ coverages[i].modelRange.rangesSums[0] }}
        </div>
        <div *ngIf="coverages[i].special && coverages[i].dependency != '1'">
          <div class="inputWrapper">
            <input
              type="text"
              formControlName="filterValueRangeSum"
              placeholder="Descripción"
              maxlength="50"
              (blur)="getRecalculateCoverage(coverages[i].special, i)"
            />
          </div>
        </div>
      </div>
      <div class="cell deducibles">
        <div *ngIf="coverages[i].isMaskingDeductibles && !coverages[i].special">
          {{
            isNumberString(coverages[i].filterValueRangeDeductible)
              ? (coverages[i].filterValueRangeDeductible | currency : "" : "")
              : coverages[i].filterValueRangeDeductible
          }}
        </div>
        <div
          *ngIf="
            coverages[i].modelRange.deductibleRanges.length > 1 &&
            coverages[i].dependency != '1' &&
            coverages[i].dependency != '2' &&
            !coverages[i].special &&
            !coverages[i].isMaskingDeductibles
          "
        >
          <div class="selectWrapper">
            <div class="customSelect">
              <select
                formControlName="filterValueRangeDeductible"
                (ngModelChange)="
                  getRecalculateCoverage(coverages[i].special, i)
                "
              >
                <option
                  *ngFor="let range of coverages[i].modelRange.deductibleRanges"
                  [value]="range"
                >
                  {{ range | currency : "" : "" }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            coverages[i].modelRange.deductibleRanges.length == 1 &&
            coverages[i].dependency != '1' &&
            coverages[i].dependency != '2' &&
            !coverages[i].special &&
            !coverages[i].isMaskingDeductibles
          "
        >
          {{ coverages[i].modelRange.deductibleRanges[0] }}
        </div>
        <div
          *ngIf="
            coverages[i].special &&
            coverages[i].dependency != '1' &&
            coverages[i].dependency != '2' &&
            !coverages[i].isMaskingDeductibles
          "
        >
          <div class="inputWrapper">
            <input
              type="text"
              formControlName="filterValueRangeDeductible"
              placeholder="Valor"
              maxlength="13"
              (blur)="getRecalculateCoverage(coverages[i].special, i)"
            />
          </div>
        </div>
        <div
          *ngIf="
            !coverages[i].special &&
            (coverages[i].dependency == '1' ||
              coverages[i].dependency == '2') &&
            !coverages[i].isMaskingDeductibles &&
            coverages[i].relatedCoverage == 1
          "
        >
          <div class="selectWrapper">
            <div class="customSelect">
              <select
                formControlName="filterValueRangeDeductible"
                (ngModelChange)="
                  getRecalculateCoverage(coverages[i].special, i)
                "
              >
                <option
                  *ngFor="let range of coverages[i].modelRange.deductibleRanges"
                  [value]="range"
                >
                  {{ range | currency : "" : "" }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            !coverages[i].special &&
            (coverages[i].dependency == '1' ||
              coverages[i].dependency == '2') &&
            !coverages[i].isMaskingDeductibles &&
            coverages[i].relatedCoverage != 1
          "
        >
          {{ coverages[i].filterValueRangeDeductible }}
        </div>
      </div>
      <!-- }}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}} -->
      <div
        class="cell aseguradora"
        *ngFor="
          let insurerCoverage of coverages[i].insurersCoverage;
          let i = index
        "
      >
        <i
          class="customIcon confirmation"
          *ngIf="insurerCoverage?.coverageIndicator == 'CHECKED'"
        ></i
        >
        <span
        *ngIf="
        insurerCoverage?.coverageIndicator == 'NA' ||
        insurerCoverage?.coverageIndicator == 'UNCHECKED' &&
        insurerCoverage?.modelRanges == null
        "
        >
          N/A
        </span>
        <span
          *ngIf="
            insurerCoverage?.coverageIndicator == 'NA' &&
            insurerCoverage?.modelRanges != null
          "
        >
          <div
            class="selectWrapper"
            *ngIf="
              insurerCoverage?.modelRanges.rangesSums &&
              insurerCoverage?.modelRanges.rangesSums.length > 1
            "
          >
            <div class="customSelect">
              <select
                (change)="
                  updateFilterRangesSums(
                    $event,
                    insurerCoverage.coverageId,
                    insurerCoverage.insurerId
                  )
                "
              >
                <option
                  *ngFor="let range of insurerCoverage?.modelRanges.rangesSums"
                  [value]="range"
                  [selected]="range === insurerCoverage?.filterValueRangeSum"
                >
                  {{ range }}
                </option>
              </select>
            </div>
          </div>
          <!-- <span *ngIf="insurerCoverage?.modelRanges.rangesSums.length == 1">{{
            insurerCoverage?.modelRanges.rangesSums[0]
          }}</span> -->
          <div
            class="selectWrapper"
            *ngIf="
                insurerCoverage?.modelRanges.deductibleRanges &&
                insurerCoverage?.modelRanges.deductibleRanges.length > 1 &&
                insurerCoverage?.modelRanges.deductibleRanges == 'S/D'
                  "
          >
            <div class="customSelect">
              <select
                (change)="
                  updateFilterDeductibleRanges(
                    $event,
                    insurerCoverage.coverageId,
                    insurerCoverage.insurerId
                  )
                "
              >
                <option
                  *ngFor="
                    let range of insurerCoverage?.modelRanges.deductibleRanges
                  "
                  [value]="range"
                  [selected]="
                    range === insurerCoverage?.filterValueRangeDeductible
                  "
                >
                  {{ range | currency : "" : "" }}
                </option>
              </select>
            </div>
          </div>
        </span>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="modalActions index">
    <button class="secondary" (click)="onClose()">Cancelar</button>
    <button class="primary" (click)="createQuote()">Continuar</button>
  </mat-dialog-actions>
</div>

<div class="modal" *ngIf="currentStep == 2">
  <a class="close" (click)="onClose()">Cerrar</a>
  <h1>Selección de aseguradora</h1>
  <mat-dialog-content class="customTable seleccionSeguro" *ngIf="quotes">
    <div class="headerSticky">
      <div class="logos">
        <div class="cell cobertura"></div>
          @for (insurer of insurers; track insurer) {
            <div class="cell suma">
              <img
                [src]="insurer.src"
                [alt]="insurer.alt"
              />
            </div>
          }
        </div>
      <div class="header">
        <div class="cell cobertura">Coberturas</div>

        @for (insurer of insurers; track insurer) {
          <div class="cell suma">
            <div class="resume">
              @if (existPackageList(insurer.insurerId)) {
                <span>{{ getPackageListName(insurer.insurerId) }} </span>
                <span>${{ getPackageLisAmount(insurer.insurerId) }}</span>
                <span>Nº {{ getPackageListNumber(insurer.insurerId) }}</span>
              } @else {
                <span class="na">N/A</span>
              }
            </div>
            Suma asegurada
          </div>
        }
        <!--    <div class="cell suma">
              <div class="resume">
                <span>Personalizado</span>
                <span>$888.888.888,88</span>
                <span>Nº 12345678</span>
              </div>
              Suma asegurada
            </div>
            <div class="cell suma">
              <div class="resume">
                <span>Personalizado</span>
                <span>$888.888.888,88</span>
                <span>Nº 12345678</span>
              </div>
              Suma asegurada
            </div>
            <div class="cell suma">
              <div class="resume">
                &lt;!&ndash; Incluir clase "na" cuándo no haya información disponible &ndash;&gt;
                <span class="na">N/A</span>
              </div>
              Suma asegurada
            </div>-->
      </div>
    </div>

    <div class="row" *ngFor="let item of coverages">
      <div class="cell cobertura">
        {{ item.coverageName }}
      </div>
      <div class="cell suma" *ngFor="let insurer of configuration.insurers">
        {{ getByCoverageId(insurer.insurerId, item.coverageId) }}
      </div>
    </div>

    <div class="row acciones">
      <div class="cell cobertura"></div>
      <div class="cell suma" *ngFor="let insurer of quotes">
        <button
          class="tertiary"
          (click)="selectedInsurance(insurer.insuranceId)"
          *ngIf="insurer.packageList"
        >
          Seleccionar
        </button>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="modalActions index">
    <button (click)="goToPreviousStep()" class="secondary">Volver</button>
    <button (click)="onClose()" class="primary">Cancelar</button>
  </mat-dialog-actions>
</div>
