import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TokenStorageService } from '../../common/token-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './services/login.service';
import { AuthService } from '../../common/auth.service';
import { NotificationService } from '@app/services/notifications.service';
import { RegisterService } from '../register/services/register.service';
import { EntitiesServices } from '@app/services/entities.service';
import { Permission } from '@app/models/permissions.enum';

import { RolesDealer, UserType } from '@app/models/roles.enum';
import {
  ChildrenDto,
  MenuDto,
  otpDto,
  profilesDto,
  rolesInternalDto,
} from '../../components/menu/models/menus.model';
import { SessionStorageService } from '../../services/session-storage.service';
import { InternalNotificationsService } from '../internal-notifications/services/internal-notificacions.service';
import { encodingBase64 } from '@app/components/base/encodingB64';
import { environment } from '../../../environments/environment.local';
const UPP = 'upp';
const UPM = 'upm';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  @ViewChild('sendButton', { static: false }) sendButtonRef!: ElementRef;
  @ViewChild('sendButton2', { static: false }) sendButton2Ref!: ElementRef;
  form: FormGroup;
  formOtp: FormGroup;

  isLoading = false;
  isSaving!: boolean;
  isLoggedIn = false;
  isLoginFailed = false;
  secondStep: boolean = false;
  firstStep: boolean = true;
  messages!: string[];
  currentProfiles!: string[];
  userName: string = '';
  password: string = '';
  profiles: any[] = [];
  menus: any[] = [];
  permissions: Permission[] = [];
  roles: RolesDealer[] = [];
  userType: UserType[] = [];
  notificationType!: string;
  errors: any = [];
  disabledButton: boolean;
  disabledButtonOtp: boolean = true;
  formDisabled: boolean = false;
  formOtpDisabled: boolean = false;
  modulesInternal: rolesInternalDto[] = [];
  userTypeE = UserType;

  constructor(
    private loginService: LoginService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private notificationsService: NotificationService,
    private registerService: RegisterService,
    private entitiesService: EntitiesServices,
    private sessionStorageService: SessionStorageService,
    public notificationService: InternalNotificationsService
  ) {
    this.messages = [];
    this.currentProfiles = [];
    this.form = this.fb.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this.formOtp = this.fb.group({
      otpNumber: ['', [Validators.required]],
    });

    this.form.valueChanges.subscribe(() => {
      this.disabledButton = this.form.invalid;
    });
    this.formOtp.valueChanges.subscribe(() => {
      this.disabledButtonOtp = this.formOtp.invalid;
    });
  }

  ngOnInit(): void {}

  // Primer paso
  // Se envia usuario y contraseña
  // Si la respuesta es true, pasa al segundo paso

  reSendOtp(): void {
    this.isLoading = true;
    const modelLogin = {
      userName: this.userName,
      password: this.password,
    };
    this.loginService.login(modelLogin).subscribe({
      next: (response) => {
        if (response.status === true) {
          this.isLoading = false;
          this.messages.push(
            'Se ha enviado un nuevo código otp. Revise su correo electrónico.'
          );
          this.notificationsService.showMessage(this.messages, 'success');
          setTimeout(() => {
            this.messages = [];
            this.notificationsService.clearMessages();
          }, 5000);
        }
      },

      error: (error) => {
        this.isLoading = false;
        this.messages.push('Ha ocurrido un error. Vuelva a iniciar sesión.');
        this.notificationsService.showMessage(this.messages, 'error');
        setTimeout(() => {
          this.messages = [];
          this.notificationsService.clearMessages();
        }, 5000);
      },
    });
  }

  onSubmitOtp(): void {
    if (this.formDisabled) return;
    this.isLoading = true;
    // Deshabilita el botón "Send" al hacer clic
    // this.disabledButton = true;
    this.userName = this.form.controls['userName'].value;
    this.password = encodingBase64(this.form.controls['password'].value);
    window.sessionStorage.removeItem(UPP);
    window.sessionStorage.removeItem(UPM);
    const modelLogin = {
      userName: this.userName,
      password: this.password,
    };
    if (this.form.valid) {
      this.formDisabled = true;
      this.loginService.login(modelLogin).subscribe({
        next: (response) => {
          if (response.status === true) {
            this.firstStep = false;
            this.secondStep = true;
            this.isLoading = false;
            this.formDisabled = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          // this.disabledButton = false;
          if (error.ok === false) {
            this.errors = error.error?.errors ?? {};
            Object.values(this.errors).forEach((error) => {
              if (error == 'ERROR_USER_INACTIVE') {
                this.messages.push('Su cuenta no se encuentra activa. ');
              } else if (error == 'ERROR_USER_BLOCKED') {
                this.messages.push('Por seguridad su cuenta está bloqueada. ');
              } else {
                this.messages.push(
                  'La información proporcionada es incorrecta. Verifique por favor.'
                );
              }
            });
            this.notificationsService.showMessage(this.messages, 'error');
          }
          setTimeout(() => {
            this.messages = [];
            this.notificationsService.clearMessages();
            // this.disabledButton = false;
            this.sendButtonRef.nativeElement.disabled = false;
            this.formDisabled = false;
          }, 5000);
        },
      });
    } else if (!this.form.valid) {
      this.isLoading = false;
      this.formDisabled = false;
      // this.disabledButton = true;
      this.messages.push('Debes ingresar tu email o usuario para continuar.');
      this.notificationsService.showMessage(this.messages, 'error');
      setTimeout(() => {
        this.messages = [];
        this.notificationsService.clearMessages();
        // this.disabledButton = false;
        this.sendButtonRef.nativeElement.disabled = false;
      }, 5000);
    }
  }

  // Segundo paso
  // Envio usuario ,contraseña y codigo de otp
  // Si la respuesta es ok

  onSubmit(): void {
    if (this.formOtpDisabled) return;
    this.isLoading = true;
    this.sendButton2Ref.nativeElement.disabled = true;
    const otpNumber = this.formOtp.controls['otpNumber'].value;
    const model = {
      userName: this.userName,
      password: this.password,
      otpNumber: otpNumber,
    };

    if (this.form.valid) {
      this.loginService.verifyOtp(model).subscribe({
        next: (response) => {
          //response
          this.formOtpDisabled = false;
          this.isLoading = false;
          this.profiles = response.data.profiles;
          this.sessionStorageService.setItem(
            environment.TOKEN,
            response.data.jwt
          );
          this.sessionStorageService.setItem(
            environment.REFRESH_TOKEN,
            response.data.refreshToken
          );
          this.profiles.forEach((profile) => {
            //itero profiles
            this.getProfileConfiguration(profile);
          });

          this.entitiesService.listDealers().subscribe({
            next: (response) => {
              this.authService.setCurrentDealers = response.data;
            },
            error: (error) => {
              console.error(error);
            },
          });

          this.setSessionStorage(response.data);

          if (response.data.forcePasswordUpdate) {
            this.router.navigate(['/change-password'], {
              queryParams: { uP: '1' },
            });
          } else {
            this.router.navigate(['/home']);
          }

          this.notificationService.notificationsWithoutSeen(response.data.id);
        },

        error: (error) => {
          this.isLoading = false;
          this.formOtpDisabled = false;

          // this.disabledButton = true;
          this.messages.push(
            'El código ingresado es inválido. Vuelva a intentarlo'
          );
          this.notificationsService.showMessage(this.messages, 'error');
          setTimeout(() => {
            this.messages = [];
            this.notificationsService.clearMessages();
            // this.disabledButton = false;
            this.sendButton2Ref.nativeElement.disabled = false;
          }, 5000);
        },
      });
    } else if (!this.form.valid) {
      this.isLoading = false;
      this.formOtpDisabled = false;
      // this.disabledButton = true;
      this.messages.push('Debes ingresar el código para continuar.');
      this.notificationsService.showMessage(this.messages, 'error');
      setTimeout(() => {
        this.messages = [];
        this.notificationsService.clearMessages();
        // this.disabledButton = false;
        this.sendButton2Ref.nativeElement.disabled = false;
      }, 5000);
    }
  }

  getProfileConfiguration(profile: profilesDto) {
    this.userType = this.userType.concat(profile.userType);

    if (profile.userType == this.userTypeE.dealer) {
      this.roles = this.roles.concat(profile.profileCode);
    }

    this.getMenu(profile.menus, profile.userType);
  }

  getMenu(menu: MenuDto[], userType: UserType) {
    //Armo array de menus y modulos-UserInternal

    const parentMenu = menu
      .filter((menu) => !menu.parent)
      .map((menu) => {
        menu.children = [];
        return menu;
      });

    const itemsChildren = menu.filter((menu) => menu.parent);

    itemsChildren.forEach((menuItem) => {
      const parent = parentMenu.find((menu) => menu.id == menuItem.parent.id);
      if (parent) {
        parent.children.push(menuItem);
      }
    });

    parentMenu.map((i) => {
      if (this.menus.indexOf(i) === -1) {
        this.menus.push(i);
        i.children.forEach((child: ChildrenDto) => {
          this.permissions.push(child.menuCode);
        });

        this.permissions.push(i.menuCode);
      }

      if (userType == this.userTypeE.internal) {
        let model: rolesInternalDto = {
          modulo: i.description,
          role: i.role,
        };
        this.modulesInternal.push(model);
      }
    });
  }

  setSessionStorage(otp: otpDto) {
    this.authService.currentMenus = this.menus;
    this.authService.setCurrentModule = this.modulesInternal;
    this.authService.setCurrentPermissions = this.permissions;
    this.authService.setCurrentRoles = this.roles;
    this.authService.setcurrentUserType = this.userType;
    this.authService.tokenLS = otp.jwt;
    this.authService.currentUser = this.userName;
    this.authService.currentUserId = otp.id;
    this.authService.setCurrentEnabledNotifications = otp.enabledNotification;
    this.authService.setCurrentProcotiza = otp.procotiza ? otp.procotiza : null;
    this.authService.currentUser = this.userName;
    this.authService.setCurrentName = `${otp.firstName} ${otp.lastName}`;
    this.authService.setCurrentPaginators = {
      page: 1,
      pageSize: environment.pageSize,
      filter: '',
    };
    this.authService.setCurrentDealerId =
      otp.dealerId || otp.dealerId != undefined ? otp.dealerId : null;
  }
}