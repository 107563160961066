import { Pipe, PipeTransform } from '@angular/core';
import { humanFileSize } from './size';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(bytes: number, si: boolean = false, dp: number = 1): string {
    return humanFileSize(bytes, si, dp);
  }

}
