import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '@app/services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  @Input('containerClass') containerClass!: string;
  @Output('onCloseValidation') onCloseValidation: EventEmitter<any> =
    new EventEmitter<any>();

  constructor(public notificationService: NotificationService) {}
  ngOnInit() {
    setTimeout(() => {
      this.notificationService.clearMessages();
    }, 5000);
  }
}
