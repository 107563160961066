import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { MenusServices } from '@app/services/menus.service';

@Component({
  selector: 'app-mailshot',
  templateUrl: './mailshot-static.component.html',
  styleUrls: ['./mailshot-static.component.css']
})
export class MailshotStaticComponent extends BaseComponent implements OnInit {

  constructor(route: ActivatedRoute, menuService: MenusServices) {
    super(route, menuService);
  }
  ngOnInit(): void {
    this.sendDataToMenu();
  }
}
