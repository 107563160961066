import {
  Router,
  CanActivate,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthReverseGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(): boolean {
      if (this.authService.currentUser) {
        this.router.navigate(['/home']);
        return false;
      } else {
        return true;
      }
    }
}