import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-img-header',
  templateUrl: './img-header.component.html',
  styleUrls: [
    '../../pages/internal-notifications/internal-notifications.css',
  ],
})
export class ImgHeaderComponent {
  @Input('image') image: string = '';
  @Input('alt') alt: string = '';
  @Input('title') title: string = '';
  @Input('hasSubtitle') hasSubtitle: boolean = false;
  @Input('subTitle') subTitle?: string = '';
  @Input('date') date?: Date | string;
  @Input() contentTemplate: TemplateRef<any>;
}
