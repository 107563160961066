import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-quote-mobile',
  templateUrl: './quote-mobile.component.html',
  styleUrls: ['./quote-mobile.component.css']
})
export class QuoteMobileComponent implements OnInit {
  
  @Input() listQuote: any[];
  @Output() download: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

}
