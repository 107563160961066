<section>
  <app-notifications></app-notifications>
  <app-preloader *ngIf="this.isLoading"></app-preloader>
  <!-- El proceso de login tiene 2 pasos -->
  <div id="login">
    <div class="wrapper">
      <!--Primer paso -->
      <ng-container *ngIf="this.firstStep">
        <h1>{{ "Login title" | translate }}</h1>
        <p>{{ "Login text" | translate }}</p>
        <form [formGroup]="form">
          <div class="inputWrapper">
            <input type="text" placeholder=" " formControlName="userName" />
            <label class="floatingLabel">{{ "User input" | translate }}</label>
          </div>

          <div class="inputWrapper">
            <input type="password" placeholder=" " formControlName="password" />
            <label class="floatingLabel">{{
              "User input password" | translate
              }}</label>
          </div>

          <div class="sendWrapper">
            <button #sendButton (click)="onSubmitOtp()" [disabled]="disabledButton">
              {{ "Send" | translate }}
            </button>
            <a href="/reset-password">{{ "Forgot password" | translate }}</a>
          </div>

          <div class="create">
            <p>
              {{ "Not register" | translate }}
              <a href="/register">{{ "Create user account" | translate }}</a>
            </p>
          </div>
        </form>
      </ng-container>
      <!-- Segundo paso -->
      <ng-container *ngIf="this.secondStep">
        <h1>{{ "Enter otp" | translate }}</h1>
        <p>
          {{ "Text otp" | translate }}
        </p>
        <form [formGroup]="formOtp">
          <div class="inputWrapper">
            <input type="number" placeholder=" " formControlName="otpNumber" />
            <label class="floatingLabel">{{ "Code" | translate }}</label>
          </div>

          <div class="sendWrapper">
            <button #sendButton2 (click)="onSubmit()" [disabled]="disabledButtonOtp">
              {{ "Send" | translate }}
            </button>
            <a (click)="reSendOtp()">Enviar un nuevo código</a>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</section>