import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, concatMap, delay, from, of } from 'rxjs';
import { ApiService } from '@app/services/api.base.service';
import { environment } from '@env/environment.local';
import { queryParamsQuote } from './model/open-quote.model';
@Injectable({
  providedIn: 'root',
})
export class OpenQuoteService {
  constructor(public api: ApiService, public httpClient: HttpClient) { }

  getListQuotesOpen(queryParams: queryParamsQuote): Observable<any> {
    const objectSanity = Object.fromEntries(Object.entries(queryParams.filter).filter(([_, v]) => v != null));
    const params = new HttpParams({ fromObject: objectSanity });
    return this.api.getWithOptions(`${environment.API_QUOTE_SERVICE}/open-quote/all`, { params });
  }

  getQuoteOpenById(idQuote: any): Observable<any> {
    return this.api.get(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}`
    );
  }

  getTypeClient(): Observable<any> {
    return this.api.get('');
  }

  generateQuoteOpen(): Observable<any> {
    return this.api.post('', '');
  }

  calculateQuoteOpen(data: any): Observable<any> {
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/open-quote/calculate`,
      data
    );
  }

  calculateQuoteOpenJson(data: any): Observable<any> {
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/open-quote/calculate/download`,
      data
    );
  }

  saveQuoteOpenDraft(data?: any): Observable<any> {
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/open-quote/draft`,
      data
    );
  }

  updateQuoteOpen(idQuote: number, data: any): Observable<any> {
    return this.api.put(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}`,
      data
    );
  }

  deleteSpecialPayment(
    idQuote: number,
    idProposal: number,
    idSpecialPayment: number
  ): Observable<any> {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}/deals/${idProposal}/special-payments/${idSpecialPayment}`,
      ''
    );
  }

  deleteProposal(idQuote: number, idProposal: number): Observable<any> {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}/deals/${idProposal}`,
      ''
    );
  }

  deleteQuoteOpen(idQuote: number): Observable<any> {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}`,
      ''
    );
  }

  deleteInsuranceOpen(
    idQuote: number,
    idProposal: number,
    idInsurance: number
  ): Observable<any> {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}/deals/${idProposal}/insurance/${idInsurance}`,
      ''
    );
  }

  deletePaymentsDeal(idQuote: number, idProposal: number): Observable<any> {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}/deals/${idProposal}/payments`,
      ''
    );
  }

  deletePaymentsInsurance(
    idQuote: number,
    idProposal: number,
    idInsurance: number
  ): Observable<any> {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}/deals/${idProposal}/insurances/${idInsurance}/payments`,
      ''
    );
  }

  deleteGrace(idQuote: number, idDeal: any, gracePeriodId: any) {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}/deals/${idDeal}/grace-period/${gracePeriodId}`,
      false
    );
  }

  downloadPdf(idQuote: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.httpClient.post(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}/pdf`,
      null,
      { headers: headers, responseType: 'blob' }
    );
  }

  deleteAllDeals(openQuoteId: number): Observable<any> {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/open-quote/${openQuoteId}/deals`,
      {}
    );
  }

  deleteAllInsurance(openQuoteId: number, dealsId: number): Observable<any> {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/open-quote/${openQuoteId}/deals/${dealsId}/insurances`,
      {}
    );
  }

  deleteAllGracePeriod(idQuote: number, idDeal: any) {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}/deals/${idDeal}/grace-period`,
      false
    );
  }

  deleteAllSpecialPayments(idQuote: number, idDeal: any) {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/open-quote/${idQuote}/deals/${idDeal}/special-payments`,
      false
    );
  }
}
