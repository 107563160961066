import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit, OnChanges {

  @Input() options: any[];
  @Input() placeholder: string;
  @Output() optionSelected: EventEmitter<any> = new EventEmitter();
  @Input() resetSelect?: boolean;

  isOpen: boolean = false;
  selectedOption: string = '';
  data : any[];

  constructor(private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['resetSelect']){
      this.reset();
    }
    if(changes['options']){
      this.data = this.options.map((i)=>{return i.label})
    }
  }

  ngOnInit() {
    this.data = this.options.map((i)=>{return i.label})
  }

  @HostListener('document:click', ['$event'])

  handleClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  selectOption(option: string) {
    this.selectedOption = option;
    let selected = this.options.filter((i)=> i.label == option);
    this.optionSelected.emit(selected[0].value);
    this.isOpen = false;
  }

  reset(){
    this.selectedOption = '';
    this.optionSelected.emit(null);
  }

}
