import { OnInit, Component, Input } from '@angular/core';

@Component({
  selector: 'app-register-confirmation',
  templateUrl: './register-confirmation.component.html',
  styleUrls: ['register-confirmation.component.css'],
})
export class RegisterConfirmationComponent implements OnInit {
  ngOnInit() {}
}
