<main class="main" id="circulares">
  <!-- INTRO -->
  <!-- <app-img-header [title]="Circulares de marketing" alt="Daimler Truck" image="/assets/images/marketing/intro"></app-img-header> -->

  <div class="profileGuide distribuidor">
    <span>Perfil - Distribuidor</span>
  </div>
  <!-- INTRO -->
  <div id="intro">
    <figure class="hero hero--has-caption-right">
      <picture class="hero__picture">
        <img
          title=""
          width=""
          height=""
          fetchpriority=" high"
          class="hero__image"
          alt="Daimler Truck"
          src="assets/images/marketing/intro.jpg"
        />
      </picture>
    </figure>
    <div class="filters">
      <div class="container">
        <div class="location-search-hero__caption">
          <h1 class="title title--1 title--bold u-mb--4">Circulares</h1>
          <div class="location-search-hero__filters">
            <div class="wrapperG3">
              <!-- FORM MULTISELECT - Con input para buscar  -->
              <div class="form-multiselect form-multiselect--open">
                <div class="form-multiselect__input-wrap">
                  <!-- Incluir <input> para ingresar texto de búsqueda, si está vacío incluir la clase "form-multiselect__input--empty" -->
                  <input
                    placeholder="Buscar circular"
                    class="form-multiselect__input form-multiselect__input--empty"
                  />
                  <span
                    class="form-multiselect__close"
                    style="display: none"
                  ></span>
                  <div class="button button--dark"><i class="icon"></i></div>
                </div>

                <ul class="form-multiselect__options" style="display: none">
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 1
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 2
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 3
                  </li>
                </ul>
              </div>

              <!-- FORM MULTISELECT - Con input para buscar  -->
              <div class="form-multiselect form-multiselect--open">
                <div class="form-multiselect__input-wrap">
                  <!-- Incluir <input> para ingresar texto de búsqueda, si está vacío incluir la clase "form-multiselect__input--empty" -->
                  <input
                    placeholder="Buscar año"
                    class="form-multiselect__input form-multiselect__input--empty"
                  />
                  <span
                    class="form-multiselect__close"
                    style="display: none"
                  ></span>
                  <div class="button button--dark">
                    <i class="icon icon-down"></i>
                  </div>
                </div>

                <ul class="form-multiselect__options" style="display: none">
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 1
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 2
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 3
                  </li>
                </ul>
              </div>

              <!-- FORM MULTISELECT - Con opción seleccionada -->
              <div class="form-multiselect form-multiselect--open">
                <div class="form-multiselect__input-wrap">
                  <div class="form-multiselect__input">
                    <span class="form-multiselect__placeholder"
                      >Selecciona una</span
                    >
                  </div>
                  <span
                    class="form-multiselect__close"
                    style="display: none"
                  ></span>
                  <div class="button button--dark">
                    <i class="icon icon-down"></i>
                  </div>
                </div>
                <ul class="form-multiselect__options" styles="">
                  <li class="form-multiselect__option">Todas</li>
                  <li class="form-multiselect__option">Sin descargar</li>
                  <li class="form-multiselect__option">Descargadas</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="detail">
    <div class="pageWidth">
      <!-- Recuadro para búsqueda sin resultados -->
      <div class="hsGuide">
        <span>Recuadro para búsqueda sin resultados</span>
      </div>
      <div class="noData">No hay información para mostrar</div>

      <!-- LISTADO -->
      <div class="hsGuide"><span>Tabla Circulares - Distribuidor</span></div>
      <!-- Tabla para listado de notificaciones -->
      <div class="customTable circulares">
        <div class="header">
          <div class="cell titulo">Título</div>
          <div class="cell fecha">Fecha</div>
          <div class="cell adjunto">Adjunto</div>

          <!-- Componente con acciones generales de la tabla-->
          <!-- Incluir después del último div.cell del header -->
          <!-- Al hacer clic en a.control incluir la clase active en div.tableActions -->
          <div class="tableActions active">
            <a href="#" class="control">Acciones</a>
            <ul>
              <li>
                <a href="#">
                  <i class="icon customIcon">
                    <img src="/assets/images/icons/export.svg" class="normal" />
                    <img
                      src="/assets/images/icons/export_hover.svg"
                      class="hover"
                    />
                  </i>
                  Exportar tabla</a
                >
              </li>
            </ul>
          </div>
        </div>

        <!-- Fila con datos -->
        <!-- Incluir clase "unread" en div.row según corresponda-->
        <div class="row unread">
          <div class="cell titulo">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eros
            nisl
          </div>
          <div class="cell fecha">30-01-2023</div>
          <div class="cell adjunto">
            <a href="#"
              ><i class="icon"></i
              ><span class="archivos">PDF (0,42 MB)</span></a
            >
          </div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell titulo">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eros
            nisl
          </div>
          <div class="cell fecha">30-01-2023</div>
          <div class="cell adjunto">
            <a href="#"
              ><i class="icon"></i
              ><span class="archivos">XLS (0,87 MB)</span></a
            >
          </div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell titulo">
            Vivamus in dui a sapien volutpat pellentesque at
          </div>
          <div class="cell fecha">30-01-2023</div>
          <div class="cell adjunto">
            <a href="#"
              ><i class="icon"></i
              ><span class="archivos">PDF (1,05 MB)</span></a
            >
          </div>
        </div>

        <!-- Fila con datos -->
        <div class="row unread">
          <div class="cell titulo">
            Curabitur ac diam id erat tincidunt pharetra vel id felis
          </div>
          <div class="cell fecha">30-01-2023</div>
          <div class="cell adjunto">
            <a href="#"
              ><i class="icon"></i
              ><span class="archivos">ZIP (4,31 MB)</span></a
            >
          </div>
        </div>

        <!-- Fila con datos -->
        <div class="row unread">
          <div class="cell titulo">
            Ut nulla tellus, congue ac massa feugiat
          </div>
          <div class="cell fecha">30-01-2023</div>
          <div class="cell adjunto">
            <a href="#"
              ><i class="icon"></i
              ><span class="archivos">PDF (2,25 MB)</span></a
            >
          </div>
        </div>
      </div>

      <div class="hsGuide">
        <span>Tabla Mobile Circulares - Distribuidor</span>
      </div>
      <!-- div.mobileWrapper auxiliar para testear tabla mobile -->
      <div class="mobileWrapper">
        <!-- Tabla mobile para listado de notificaciones -->
        <div class="customTableMobile circulares">
          <!-- Fila con datos -->
          <!-- Incluir clase "unread" en div.row según corresponda-->
          <div class="row unread">
            <div class="titulo">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eros
              nisl
            </div>
            <div class="adjuntoYFecha">
              <div class="adjunto">
                <a href="#"
                  ><i class="icon"></i
                  ><span class="archivos">PDF (0,42 MB)</span></a
                >
              </div>
              <div class="fecha">30-01-2023</div>
            </div>
          </div>

          <!-- Fila con datos -->
          <div class="row">
            <div class="titulo">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eros
              nisl
            </div>
            <div class="adjuntoYFecha">
              <div class="adjunto">
                <a href="#"
                  ><i class="icon"></i
                  ><span class="archivos">PDF (0,42 MB)</span></a
                >
              </div>
              <div class="fecha">30-01-2023</div>
            </div>
          </div>

          <!-- Fila con datos -->
          <div class="row">
            <div class="titulo">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eros
              nisl
            </div>
            <div class="adjuntoYFecha">
              <div class="adjunto">
                <a href="#"
                  ><i class="icon"></i
                  ><span class="archivos">PDF (0,42 MB)</span></a
                >
              </div>
              <div class="fecha">30-01-2023</div>
            </div>
          </div>

          <!-- Fila con datos -->
          <div class="row unread">
            <div class="titulo">
              Curabitur ac diam id erat tincidunt pharetra vel id felis
            </div>
            <div class="adjuntoYFecha">
              <div class="adjunto">
                <a href="#"
                  ><i class="icon"></i
                  ><span class="archivos">PDF (0,42 MB)</span></a
                >
              </div>
              <div class="fecha">30-01-2023</div>
            </div>
          </div>

          <!-- Fila con datos -->
          <div class="row unread">
            <div class="titulo">Ut nulla tellus, congue ac massa feugiat</div>
            <div class="adjuntoYFecha">
              <div class="adjunto">
                <a href="#"
                  ><i class="icon"></i
                  ><span class="archivos">PDF (0,42 MB)</span></a
                >
              </div>
              <div class="fecha">30-01-2023</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="profileGuide admin"><span>Perfil - Administrador</span></div>
  <!-- INTRO -->
  <div id="intro">
    <figure class="hero hero--has-caption-right">
      <picture class="hero__picture">
        <img
          title=""
          width=""
          height=""
          fetchpriority=" high"
          class="hero__image"
          alt="Daimler Truck"
          src="assets/images/marketing/intro.jpg"
        />
      </picture>
    </figure>
    <div class="filters">
      <div class="container">
        <div class="location-search-hero__caption">
          <h1 class="title title--1 title--bold u-mb--4">Circulares</h1>
          <div class="location-search-hero__filters">
            <div class="wrapperG3">
              <!-- FORM MULTISELECT - Con input para buscar  -->
              <div class="form-multiselect form-multiselect--open">
                <div class="form-multiselect__input-wrap">
                  <!-- Incluir <input> para ingresar texto de búsqueda, si está vacío incluir la clase "form-multiselect__input--empty" -->
                  <input
                    placeholder="Buscar circular"
                    class="form-multiselect__input form-multiselect__input--empty"
                  />
                  <span
                    class="form-multiselect__close"
                    style="display: none"
                  ></span>
                  <div class="button button--dark"><i class="icon"></i></div>
                </div>

                <ul class="form-multiselect__options" style="display: none">
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 1
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 2
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 3
                  </li>
                </ul>
              </div>

              <!-- FORM MULTISELECT - Con input para buscar  -->
              <div class="form-multiselect form-multiselect--open">
                <div class="form-multiselect__input-wrap">
                  <!-- Incluir <input> para ingresar texto de búsqueda, si está vacío incluir la clase "form-multiselect__input--empty" -->
                  <input
                    placeholder="Buscar año"
                    class="form-multiselect__input form-multiselect__input--empty"
                  />
                  <span
                    class="form-multiselect__close"
                    style="display: none"
                  ></span>
                  <div class="button button--dark">
                    <i class="icon icon-down"></i>
                  </div>
                </div>

                <ul class="form-multiselect__options" style="display: none">
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 1
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 2
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 3
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- LISTADO -->
  <div id="detail">
    <div class="pageWidth">
      <div class="wrapperCustomActions">
        <div class="wrapperG3">
          <div class="sendWrapper">
            <a class="primary">Nueva circular</a>
          </div>
        </div>
      </div>

      <div class="hsGuide admin"><span>Tabla Circulares - Admin</span></div>
      <!-- Tabla para listado de notificaciones (versión admin)-->
      <div class="customTable circulares admin">
        <div class="header">
          <div class="cell titulo">Título</div>
          <div class="cell fecha">Fecha</div>
          <div class="cell adjunto">Adjunto</div>
          <div class="cell destinatarios">Destinatarios</div>
          <div class="cell descargas">Descargas</div>
          <div class="cell pendientes">Pendientes</div>
          <div class="cell acciones"></div>
          <!-- Componente con acciones generales de la tabla-->
          <!-- Incluir después del último div.cell del header -->
          <div class="tableActions active">
            <a href="#" class="control">Acciones</a>
            <ul>
              <li>
                <a href="#">
                  <i class="icon customIcon">
                    <img src="/assets/images/icons/export.svg" class="normal" />
                    <img
                      src="/assets/images/icons/export_hover.svg"
                      class="hover"
                    />
                  </i>
                  Exportar tabla</a
                >
              </li>
              <li>
                <a href="#">
                  <i class="icon customIcon">
                    <img src="/assets/images/icons/eye.svg" class="normal" />
                    <img
                      src="/assets/images/icons/eye_hover.svg"
                      class="hover"
                    />
                  </i>
                  Ver detalle</a
                >
              </li>
            </ul>
          </div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell titulo">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eros
            nisl
          </div>
          <div class="cell fecha">30-01-2023</div>
          <div class="cell adjunto">
            <a href="#"
              ><i class="icon"></i
              ><span class="archivos">PDF (0,42 MB)</span></a
            >
          </div>
          <div class="cell destinatarios">98</div>
          <div class="cell descargas">21</div>
          <div class="cell pendientes">77</div>
          <div class="cell acciones">
            <a href="#"><i class="icon"></i></a>
            <a href="#"><i class="icon"></i></a>
          </div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell titulo">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eros
            nisl
          </div>
          <div class="cell fecha">30-01-2023</div>
          <div class="cell adjunto">
            <a href="#"
              ><i class="icon"></i
              ><span class="archivos">XLS (0,87 MB)</span></a
            >
          </div>
          <div class="cell destinatarios">98</div>
          <div class="cell descargas">21</div>
          <div class="cell pendientes">77</div>
          <div class="cell acciones">
            <a href="#"><i class="icon"></i></a>
            <a href="#"><i class="icon"></i></a>
          </div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell titulo">
            Vivamus in dui a sapien volutpat pellentesque at
          </div>
          <div class="cell fecha">30-01-2023</div>
          <div class="cell adjunto">
            <a href="#"
              ><i class="icon"></i
              ><span class="archivos">PDF (1,05 MB)</span></a
            >
          </div>
          <div class="cell destinatarios">98</div>
          <div class="cell descargas">21</div>
          <div class="cell pendientes">77</div>
          <div class="cell acciones">
            <a href="#"><i class="icon"></i></a>
            <a href="#"><i class="icon"></i></a>
          </div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell titulo">
            Curabitur ac diam id erat tincidunt pharetra vel id felis
          </div>
          <div class="cell fecha">30-01-2023</div>
          <div class="cell adjunto">
            <a href="#"
              ><i class="icon"></i
              ><span class="archivos">ZIP (4,31 MB)</span></a
            >
          </div>
          <div class="cell destinatarios">98</div>
          <div class="cell descargas">21</div>
          <div class="cell pendientes">77</div>
          <div class="cell acciones">
            <a href="#"><i class="icon"></i></a>
            <a href="#"><i class="icon"></i></a>
          </div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell titulo">
            Ut nulla tellus, congue ac massa feugiat
          </div>
          <div class="cell fecha">30-01-2023</div>
          <div class="cell adjunto">
            <a href="#"
              ><i class="icon"></i
              ><span class="archivos">PDF (2,25 MB)</span></a
            >
          </div>
          <div class="cell destinatarios">98</div>
          <div class="cell descargas">21</div>
          <div class="cell pendientes">77</div>
          <div class="cell acciones">
            <a href="#"><i class="icon"></i></a>
            <a href="#"><i class="icon"></i></a>
          </div>
        </div>
      </div>

      <div class="hsGuide admin">
        <span>Tabla Mobile Circulares - Admin</span>
      </div>
      <!-- div.mobileWrapper auxiliar para testear tabla mobile -->
      <div class="mobileWrapper">
        <!-- Tabla mobile para listado de notificaciones -->
        <div class="customTableMobile circulares admin">
          <!-- Fila con datos -->
          <!-- Incluir clase "unread" en div.row según corresponda-->
          <div class="row unread">
            <div class="tituloYAcciones">
              <div class="titulo">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                eros nisl
              </div>
              <a href="#"><i class="icon"></i></a>
            </div>

            <div class="adjuntoYFecha">
              <div class="adjunto">
                <a href="#"
                  ><i class="icon"></i
                  ><span class="archivos">PDF (0,42 MB)</span></a
                >
              </div>
              <div class="fecha">30-01-2023</div>
            </div>
          </div>

          <!-- Fila con datos -->
          <div class="row">
            <div class="tituloYAcciones">
              <div class="titulo">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                eros nisl
              </div>
              <a href="#"><i class="icon"></i></a>
            </div>
            <div class="adjuntoYFecha">
              <div class="adjunto">
                <a href="#"
                  ><i class="icon"></i
                  ><span class="archivos">PDF (0,42 MB)</span></a
                >
              </div>
              <div class="fecha">30-01-2023</div>
            </div>
          </div>

          <!-- Fila con datos -->
          <div class="row">
            <div class="tituloYAcciones">
              <div class="titulo">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                eros nisl
              </div>
              <a href="#"><i class="icon"></i></a>
            </div>
            <div class="adjuntoYFecha">
              <div class="adjunto">
                <a href="#"
                  ><i class="icon"></i
                  ><span class="archivos">PDF (0,42 MB)</span></a
                >
              </div>
              <div class="fecha">30-01-2023</div>
            </div>
          </div>

          <!-- Fila con datos -->
          <div class="row unread">
            <div class="tituloYAcciones">
              <div class="titulo">
                Curabitur ac diam id erat tincidunt pharetra vel id felis
              </div>
              <a href="#"><i class="icon"></i></a>
            </div>
            <div class="adjuntoYFecha">
              <div class="adjunto">
                <a href="#"
                  ><i class="icon"></i
                  ><span class="archivos">PDF (0,42 MB)</span></a
                >
              </div>
              <div class="fecha">30-01-2023</div>
            </div>
          </div>

          <!-- Fila con datos -->
          <div class="row unread">
            <div class="tituloYAcciones">
              <div class="titulo">Ut nulla tellus, congue ac massa feugiat</div>
              <a href="#"><i class="icon"></i></a>
            </div>
            <div class="adjuntoYFecha">
              <div class="adjunto">
                <a href="#"
                  ><i class="icon"></i
                  ><span class="archivos">PDF (0,42 MB)</span></a
                >
              </div>
              <div class="fecha">30-01-2023</div>
            </div>
          </div>
        </div>
      </div>

      <div class="hsGuide admin"><span>Modal Nueva Circular - Admin</span></div>
      <!-- div.modalWrapper auxiliar para testear tabla mobile -->
      <div class="modalWrapper">
        <div class="modal">
          <a href="#" class="close"><span>Cerrar</span></a>
          <h3>Nueva circular</h3>

          <div class="modalContent">
            <div class="inputWrapper">
              <input type="text" placeholder=" " />
              <label class="floatingLabel">Título</label>
            </div>

            <div class="attachWrapper">
              <label>Adjuntar circular</label>
              <div class="attachComponent">
                Incluir componente para adjuntar archivos
              </div>
            </div>

            <div class="recipientsWrapper">
              <label>Destinatarios</label>
              <div class="tagsComponent">
                Incluir componente con etiquetas para elegir perfiles de
                distribuidor
              </div>
              <div class="gapSm"></div>
              <div class="tagsComponent">
                Incluir componente con etiquetas para elegir perfiles de
                personal interno
              </div>
            </div>
          </div>

          <div class="modalActions">
            <button>Cancelar</button>
            <button disabled>Crear</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="profileGuide admin">
    <span>Perfil - Administrador - Detalle</span>
  </div>
  <!-- INTRO -->
  <div id="intro">
    <figure class="hero hero--has-caption-right">
      <picture class="hero__picture">
        <img
          title=""
          width=""
          height=""
          fetchpriority=" high"
          class="hero__image"
          alt="Daimler Truck"
          src="assets/images/marketing/intro.jpg"
        />
      </picture>
    </figure>
    <div class="filters">
      <div class="container">
        <div class="location-search-hero__caption">
          <h1 class="title title--1 title--bold u-mb--4">Circulares</h1>
          <div class="detalle">
            <h2>Título de la circular que se muestra en el detalle</h2>
            <span class="fecha">30-ENE-2023, 14:12</span>
          </div>
          <div class="location-search-hero__filters">
            <div class="wrapperG3">
              <!-- FORM MULTISELECT - Con input para buscar  -->
              <div class="form-multiselect form-multiselect--open">
                <div class="form-multiselect__input-wrap">
                  <!-- Incluir <input> para ingresar texto de búsqueda, si está vacío incluir la clase "form-multiselect__input--empty" -->
                  <input
                    placeholder="Buscar circular"
                    class="form-multiselect__input form-multiselect__input--empty"
                  />
                  <span
                    class="form-multiselect__close"
                    style="display: none"
                  ></span>
                  <div class="button button--dark"><i class="icon"></i></div>
                </div>

                <ul class="form-multiselect__options" style="display: none">
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 1
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 2
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 3
                  </li>
                </ul>
              </div>

              <!-- FORM MULTISELECT - Con input para buscar  -->
              <div class="form-multiselect form-multiselect--open">
                <div class="form-multiselect__input-wrap">
                  <!-- Incluir <input> para ingresar texto de búsqueda, si está vacío incluir la clase "form-multiselect__input--empty" -->
                  <input
                    placeholder="Buscar año"
                    class="form-multiselect__input form-multiselect__input--empty"
                  />
                  <span
                    class="form-multiselect__close"
                    style="display: none"
                  ></span>
                  <div class="button button--dark">
                    <i class="icon icon-down"></i>
                  </div>
                </div>

                <ul class="form-multiselect__options" style="display: none">
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 1
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 2
                  </li>
                  <li
                    class="form-multiselect__option form-multiselect__option--small"
                  >
                    Opción 3
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- LISTADO -->
  <div id="detail">
    <div class="pageWidth">
      <!-- Indicadores -->
      <div class="indicators circulares">
        <div>
          <span><b>Destinatarios</b></span>
          <span>98</span>
        </div>
        <div>
          <span><b>Descargas</b></span>
          <span>21</span>
        </div>
        <div>
          <span><b>Pendientes</b></span>
          <span>77</span>
        </div>
      </div>
      <h3>Destinatarios</h3>

      <div class="hsGuide admin">
        <span>Tabla Detalle de Circulares - Admin</span>
      </div>
      <!-- Tabla para listado detalle de notificaciones (versión admin)-->
      <div class="customTable circularesDetalle">
        <div class="header">
          <div class="cell nombre">Nombre</div>
          <div class="cell fecha">Fecha de descarga</div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell nombre">Manuel Sandoval</div>
          <div class="cell fecha">30-01-2023</div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell nombre">Estela Gil Vidal</div>
          <div class="cell fecha"></div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell nombre">Andrea Castro</div>
          <div class="cell fecha">30-01-2023</div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell nombre">Cristian Ezequiel Aráoz</div>
          <div class="cell fecha">30-01-2023</div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell nombre">Roberto López Andrade</div>
          <div class="cell fecha">30-01-2023</div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell nombre">María Cristina Sanchez Oliva</div>
          <div class="cell fecha"></div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell nombre">Sergio Gutierrez</div>
          <div class="cell fecha"></div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell nombre">Camila Saavedra Ruíz</div>
          <div class="cell fecha">30-01-2023</div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell nombre">Omar Gonzalo Urtizberea Quintana</div>
          <div class="cell fecha">30-01-2023</div>
        </div>

        <!-- Fila con datos -->
        <div class="row">
          <div class="cell nombre">Diego Leonel Silva Herrero</div>
          <div class="cell fecha"></div>
        </div>
      </div>
    </div>
  </div>
</main>
