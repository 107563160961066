<div class="customPaginator">
  <div class="total">{{ total }} {{ "Records" | translate }}</div>
  
  <ngb-pagination
    class="pagination"
    [collectionSize]="total"
    [(page)]="page"
    [pageSize]="pageSize"
    [maxSize]="5"
    [boundaryLinks]="true"
    [rotate]="true"
    (pageChange)="onPageChange($event)"
  >
  </ngb-pagination>
  
  <div class="auxiliar">
    <label for="pageSizeSelect" class="page-size-label">{{ label }}</label> 
    <select id="pageSizeSelect" class="page-size-select" (change)="onPageSizeChange($event)">
      <option *ngFor="let size of pageSizeOptions" [value]="size" [selected]="size === pageSize">
        {{ size }}
      </option>
    </select>
  </div>
</div>
