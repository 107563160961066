import {WholeSale} from "../../../../core/model/CoreModel";
import {StandardFinancialSetupAttribute} from "@app/pages/quote/standard-quote/model/financial-setup.model";
import { DealerBranch } from "./dealer.model";

export class queryParamsQuote {
  //Parametros de busqueda
  filter: {
    dealerId: any,
    userType: any,
    page: number;
    pageSize: number;
    me: boolean;
    customerName: any;
    quoteName: any;
    status: any;
    to: any;
    from: any;
  };
}
export class quoteConceptDto {
  //Result de Calculate
  term: number;
  unitPrice: number;
  unitPriceNoTax: number;
  annualPercentageRate: number;
  downpaymentAmount: number;
  openingfeeAmount: number;
  guaranteeDepositAmount: number;
  insuranceAmount: number;
  additionalServicesAmount: number;
  confirmationExpensesAmount: number;
  initialPayment: number;
  financialAmount: number;
  purchaseOptionAmount: number;
  monthlyPayment: number;
  annualTotalCost: number;
  residualValue: number;
}

export class payments {
  //Amortizaciones
  id: number;
  interestTax: number;
  period: number;
  paymentDate: string;
  capitalBalance: number;
  capitalPayment: number;
  interest: number;
  interestNoTax: number;
  total: number;
  hasPayment: boolean;
  paymentType: string;
}
export class financialResponseDto {
  id: number;
  creationDate: string;
  updatedDate: string;
  creationUser: number;
  updateUser: number;
  financialSetupName: string;
  modelType: string;
  personaType: string;
  financialProduct: string;
  currency: string;
  classification: boolean;
  calendarType: string;
  paymentFormationType: paymentFormationTypeEnum;
  allowPaymentDay: boolean;
  allowCapitalGrace: boolean;
  allowInterestGrace: boolean;
  companyName: string;
  financialSetupDescription: string;
  paymentType: paymentTypeEnum;
  downPaymentTaxType: TaxTypeEnum;
  financialConditions: financialConditionsDto[];
  termId: number;
  termSetup: financialConditionsDto;
  standardFinancialSetupAttributes: StandardFinancialSetupAttribute[]
}

export class financialSetup {
  id: number;
  termId: number;
  financialCondition: financialConditionsDto;
  customerRate: any;
  downPaymentTaxType: TaxTypeEnum;
  downPaymentTax: number;
  downPayment: number;
  paymentType: number;
  openingFeeType: TaxTypeEnum;
  openingFeeTax: number;
  openingFee: number;
  financialProduct: FinancialProductEnum;
  calculationType: any;
}

export class financialConditionsDto {
  id: number;
  creationDate: string;
  updatedDate: string;
  creationUser: number;
  updateUser: number;
  financialSetupDescription: string;
  recurring: number;
  allowPaymentDay: boolean;
  minDownPaymentPercentage: number;
  maxDownPaymentPercentage: number;
  nominalRate: number;
  purchasePercentage: number;
  paymentIncentive: number;
  warrantyDepositPercentage: number;
  term: number;
  gracePeriod: number;
}

export class configuration {
  productId: number;
  coverages: coverages[];
  insurers: insurers[];
}

export class coverages {
  coverageId: number;
  coverageName: string;
  modelRange: { deductibleRanges: string[]; rangesSums: string[] };
  filterValueRangeSum: string;
  filterValueRangeDeductible: string;
  sumInsuredDefault: string;
  defaultDeductible: string;
  fixed: boolean;
  fixedProfile: boolean;
  selected: boolean;
  isMaskingSumsInsured: boolean;
  special: boolean;
  isMaskingDeductibles: boolean;
  insurersCoverage: any[];
  dependency: string;
  relatedCoverage: number;
  maskingSumsInsured: string;
}

export class insurers {
  insurerId: number;
  insurerName: string;
  src: string;
  alt: string;
}

export class models {
  classification: boolean;
  creationDate: string;
  creationUser: number;
  currency: string;
  enabled: boolean;
  id: number;
  model: string;
  name: string;
  price: number;
  priceType: priceTypeEnum;
  productCode: string;
  modelType: modelTypeEnum;
  updateUser: number;
  updatedDate: string;
  year: number;
  brand: any;
  subBrand: any;
  quantity: number;
  productVersionId: number;
  modelCode: any;
}

export class quoteDto {
  id: number;
  creationDate: string;
  creationUser: number;
  customer: customerDto;
  model: models;
  standardQuoteModel: models;
  standardQuoteDeals: dealsDto[];
  accessories: accesoriesDto[];
  dealAmount: number;
  status: string;
  updateUser: number;
  updatedDate: string;
  // financialService: financialServiceDto;
  companyName: string;
  quoteName: string;
  dealerId: number;
  quantity: number;
  dealerBranchId: number;
  dealerBranch: DealerBranch;
  wholeSale: WholeSale;
  dealer: any;
  wholeSaleId: number;
}

export class financialServiceDto {
  companyName: string;
  quoteName: string;
  dealerId: number;
}
export class customerDto {
  personType: string;
  firstName: string;
  secondName: string;
  lastName: string;
  mothersLastName: string;
}

export class dealsDto {
  id: number;
  financialSetup: financialSetup;
  financialCondition: any;
  insurance: any;
  result: quoteConceptDto;
  payments: payments;
  specialPayments: any;
  comment: string;
  isSelected: boolean;
  standardFinancialSetup: any;
  standardQuoteCondition: any;
}

export class accesoriesDto {
  id: number;
  price: number;
  classification: boolean;
  year: any;
  model: any;
  quantity: number;
}

export class calculateDto {
  financialSetup: financialSetup;
  insurance: any;
  result: quoteConceptDto;
  specialPayments: any;
  payments: any;
  panel: any;
  grace: grace[];
}

export class quoteRequestDto {
  brand: any;
  creationDate: string;
  creationUser: number;
  dealerId: number;
  customer: customerDto;
  dealAmount: number;
  id: number;
  model: models;
  accessories: any;
  standardQuoteDeals: dealsDto[];
  standardQuoteModel: any;
  status: string;
  subBrand: any;
  updateUser: number;
  updatedDate: string;
  quantity: number;
}

export class specialPayments {
  id: number;
  period: number;
  amount: number;
  applyTo: SpecialPaymentEnum;
  paymentDate: string;
  paymentType: string;
  specialPaymentDate: string;
}

export class grace {
  id: number;
  period: number;
  applyTo: SpecialPaymentEnum;
  gracePeriodDate: string;
  paymentType: string;
  paymentDate?: string;
}
export interface insurance {
  id: string;
  insuranceId: string;
  requestId: string;
  insuranceName: string;
  creationDate: string;
  updateDate: string;
  typeInsurance: string;
  state: number;
  stateName: string;
  use: number;
  postalCode: string;
  quantityPassengers: number;
  type: string;
  quantityTrailes: number;
  packageList: any;
}

export interface residualValue {
  id: number;
  daimlerRate: number;
  externalsMinRate: number;
  externalsMaxRate: number;
  term: number;
}

//#region ENUMS

export enum SpecialPaymentEnum {
  interestPayment = 'INTEREST_PAYMENT',
  captialPayment = 'CAPITAL_PAYMENT',
  interesTcaptialPayment = 'INTEREST_CAPITAL_PAYMENT',
}
export enum modelTypeEnum {
  accesorios = 'ACCESSORIES',
  autobuses = 'BUSES',
  camiones = 'TRUCKS',
  tractos = 'TRACTS',
  sprinter = 'SPRINTER',
}

export enum priceTypeEnum {
  priceCommercialValue = 'PRICE_COMERCIAL_VALUE',
  priceNormal = 'PRICE_NORMAL',
}

export enum paymentFormationTypeEnum {
  leveled = 'LEVELED',
  descending = 'DESCENDING',
}

export enum paymentTypeEnum {
  expiration = 'EXPIRATION',
}

export enum TaxTypeEnum {
  invoiceWithTax = 'INVOICE_VALUE_TAX',
  invoiceWithoutTax = 'INVOICE_VALUE_WITHOUT_TAX',
  amountToFinance = 'AMOUNT_TO_FINANCE',
  fixedAmount = 'FIXED_AMOUNT',
  numberOfRents = 'NUMBER_OF_RENTS',
}
export enum insuranceEnum {
  qualitas = 222,
  gnp_seguros = 14625,
  hdi_seguros = 240819,
}

export enum FinancialProductEnum {
  directCredit = 'DIRECT_CREDIT',
  financialLeasing = 'FINANCIAL_LEASING',
  pureLease = 'PURE_LEASING',
}

export enum warrantyType{
  external= 'EXTERNAL',
  daimler= 'DAIMLER'
}
//#endregion
