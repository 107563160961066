import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.css'],
})
export class SelectSearchComponent implements OnInit {
  @Input() options: any[];
  @Input() placeholder: string;
  @Output() searchSelected: EventEmitter<any> = new EventEmitter();

  isOpen: boolean = false;
  searchInput: string = '';
  selectedOption: string = '';
  optionsSearch: any[] = [];
  data: any[] = [];

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.data = this.options.map((i) => {
      return i.label;
    });
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  toggleOpen() {
    this.optionsSearch = this.data;
    this.isOpen = !this.isOpen;
  }

  filterOptions() {
    if (this.searchInput.length >= 3) {
      let searchTerm = this.searchInput.toLowerCase();
      this.optionsSearch = this.data.filter((option) => {
        let lowercasedOption = option.toLowerCase();
        return (
          lowercasedOption === searchTerm ||
          lowercasedOption.includes(searchTerm)
        );
      });
      this.isOpen = true;
    }

    if (this.searchInput == '') {
      this.optionsSearch = this.data;
    }
  }

  selectOptionSearch(option: string) {
    this.searchInput = option;
    this.selectedOption = option;
    let selected = this.options.filter((i) => i.label == option);
    this.searchSelected.emit(selected[0].value);
    this.isOpen = false;
  }

  reset() {
    this.selectedOption = '';
    this.searchInput = '';
    this.searchSelected.emit(null);
  }
}
