<section>
  <app-notifications variant="error"></app-notifications>
  <app-preloader *ngIf="this.isLoading"></app-preloader>
  <div id="register">
    <div class="wrapper">
      <h1>{{ "Register title" | translate }}</h1>
      <p>{{ "Register text" | translate }}</p>
      <p class="note">
        {{ "Complete required data" | translate }}
        <span>*</span>
      </p>

      <h2>{{ "Profile title" | translate }}</h2>

      <form [formGroup]="form" (ngSubmit)="onSubmit()" [ngClass]="{ 'disable-enter': disabledButton }">
        <div class="controls">
          <div class="inputWrapper">
            <input [ngClass]="{
                error:
                  !form.controls['firstName'].valid &&
                  (submitted || !hideErrors)
              }" type="text" placeholder=" " formControlName="firstName" />
            <label class="floatingLabel">{{ "Name" | translate }} <span class="required">*</span></label>
            <ng-container *ngIf="
                !form.controls['firstName'].valid &&
                (submitted || !hideErrors) &&
                form.controls['firstName'].hasError('required')
              "><span class="alert">{{ "Name required" | translate }}.</span></ng-container>
          </div>

          <div class="inputWrapper">
            <input [ngClass]="{
                error:
                  !form.controls['lastName'].valid && (submitted || !hideErrors)
              }" type="text" placeholder=" " formControlName="lastName" />

            <label class="floatingLabel">{{ "LastName" | translate }}
              <span class="required">*</span></label>
            <ng-container *ngIf="
                !form.controls['lastName'].valid &&
                (submitted || !hideErrors) &&
                form.controls['lastName'].hasError('required')
              "><span class="alert">{{
                "LastName required" | translate
                }}</span></ng-container>
          </div>
        </div>

        <div class="controls">
          <div class="inputWrapper">
            <input [ngClass]="{
                error:
                  !form.controls['mothersLastName'].valid &&
                  (submitted || !hideErrors)
              }" type="text" placeholder=" " formControlName="mothersLastName" />

            <label class="floatingLabel">{{ "MothersLastName" | translate }}
              <span class="required">*</span></label>
            <ng-container *ngIf="
                !form.controls['mothersLastName'].valid &&
                (submitted || !hideErrors) &&
                form.controls['mothersLastName'].hasError('required')
              "><span class="alert">{{
                "MothersLastName required" | translate
                }}</span></ng-container>
          </div>

          <div class="inputWrapper">
            <input [ngClass]="{
                error:
                  !form.controls['email'].valid && (submitted || !hideErrors)
              }" type="email" placeholder=" " formControlName="email" />
            <label class="floatingLabel">{{ "Email" | translate }} <span class="required">*</span></label>

            <ng-container *ngIf="
                !form.controls['email'].valid &&
                (submitted || !hideErrors) &&
                form.controls['email'].hasError('required')
              "><span class="alert">{{
                "Email required" | translate
                }}</span></ng-container>
            <ng-container *ngIf="form.controls['email'].hasError('emailDomain')"><span class="alert">
                {{ "Email domain" | translate }}</span></ng-container>
          </div>
        </div>


        <div class="controls">
          <!-- Fecha de nacimiento -->
          <div class="inputWrapper">
            <label class="floatingLabel"
              >{{ "Date of Birth" | translate }}
              <span class="required">*</span></label
            >
            <app-date-picker
              [selectedDate]="form.get('dateOfBirth')?.value"
              (selectedDateChange)="form.get('dateOfBirth')?.setValue($event)"
              [hasError]="
                !form.get('dateOfBirth')?.valid && (submitted || !hideErrors)
              "
            >
            </app-date-picker>

            <ng-container *ngIf="form.get('dateOfBirth')?.errors">
              <span
                class="alert"
                *ngIf="
                  form.get('dateOfBirth')?.hasError('required') &&
                  form.get('dateOfBirth')?.touched
                "
              >
                {{ "Date of Birth required" | translate }}
              </span>
              <span
                class="alert"
                *ngIf="form.get('dateOfBirth')?.hasError('invalidDate')"
              >
                {{ "maxAge" | translate }}
              </span>
              <span
                class="alert"
                *ngIf="form.get('dateOfBirth')?.hasError('futureDate')"
              >
                {{ "Date cannot be in the future" | translate }}
              </span>
            </ng-container>
          </div>

          <!-- Sexo input -->
          <div class="controlWrapper">
            <label
              >{{ "Gender" | translate }} <span class="required">*</span></label
            >
            <app-select
              [options]="genders"
              [placeholder]="'Gender' | translate"
              (optionSelected)="form.controls['gender'].setValue($event)"
            >
            </app-select>
            <ng-container
              *ngIf="
                !form.controls['gender'].valid &&
                (submitted || !hideErrors) &&
                form.controls['gender'].hasError('required')
              "><span class="alert">{{
                "Gender required" | translate
              }}</span></ng-container
            >
          </div>
        </div>



        <div class="controls">
          <div class="inputWrapper">
            <!-- Si el input no es válido agregar la clase "error" -->
            <input type="text" maxlength="10" placeholder=" " formControlName="telephoneNumber" [ngClass]="{
                error:
                  !form.controls['telephoneNumber'].valid &&
                  (submitted || !hideErrors)
              }" />
            <label class="floatingLabel">{{ "Phone" | translate }} <span class="required">*</span></label>
            <div *ngIf="
                form.get('telephoneNumber')?.errors &&
                form.get('telephoneNumber')?.dirty
              ">
              <span class="alert" *ngIf="form.get('telephoneNumber')?.hasError('required')">{{ "Phone required" |
                translate }}</span>
              <span class="alert" *ngIf="form.get('telephoneNumber')?.hasError('pattern')">{{ "Phone validation" |
                translate }}</span>
            </div>
          </div>

          <div class="controlWrapper">
            <label
              >{{ "Profile" | translate }}
              <span class="required">*</span></label
            >
            <app-select
              [options]="profiles"
              [placeholder]="'Profile' | translate"
              (optionSelected)="form.controls['profileId'].setValue($event)"
            >
            </app-select>
            <ng-container
              *ngIf="
                !form.controls['profileId'].valid &&
                (submitted || !hideErrors) &&
                form.controls['profileId'].hasError('required')
              "><span class="alert">{{
                "Profile required" | translate
              }}</span></ng-container
            >
          </div>
        </div>

       <div class="controlWrapper">
        <label>
          {{ "Dealer" | translate }}
          <span class="required">*</span>
      </label>
      <app-select
          [options]="dealers"
          [placeholder]="'Dealer' | translate"
          (optionSelected)="form.controls['dealerId'].setValue($event)"
      ></app-select>
            <!--
            <input matInput [matAutocomplete]="dealer" formControlName="dealerId" />
            <mat-autocomplete #dealer (optionSelected)="onDealerSelected($event)">
              <mat-option *ngFor="let dealer of dealers" [value]="dealer.name">
                {{ dealer.name }}
              </mat-option>
            </mat-autocomplete>
            -->

            <ng-container *ngIf="
                form.controls['dealerId'].touched &&
                form.controls['dealerId'].hasError('invalidOption')
              "><span class="alert">La opción seleccionada es incorrecta</span></ng-container>
            <ng-container *ngIf="
                !form.controls['dealerId'].valid &&
                (submitted || !hideErrors) &&
                form.controls['dealerId'].hasError('required') &&
                form.controls['dealerId'].hasError('invalidOption')
              "><span class="alert">{{
                "Dealer required" | translate
              }}</span></ng-container
            >
          </div>

        <div class="controls">
          <div class="inputWrapper">
            <input type="password" placeholder=" " formControlName="password" (keydown.enter)="$event.preventDefault()"
              [ngClass]="{
                error:
                  !form.controls['password'].valid && (submitted || !hideErrors)
              }" />
            <label class="floatingLabel">{{ "User input password" | translate
              }}<span class="required">*</span></label>
            <ng-container *ngIf="
                !form.controls['password'].valid &&
                (submitted || !hideErrors) &&
                form.controls['password'].hasError('required')
              "><span class="alert">{{
                "User input password required" | translate
                }}</span></ng-container>
            <ng-container *ngIf="
                form.controls['password'].errors && (submitted || !hideErrors)
              ">
              <span class="alert">{{ "Password alert" | translate }}</span>
            </ng-container>
          </div>

          <div class="inputWrapper">
            <input type="password" placeholder=" " formControlName="confirmPassword"
              (keydown.enter)="$event.preventDefault()" [ngClass]="{
                error:
                  !form.controls['confirmPassword'].valid &&
                  (submitted || !hideErrors)
              }" />
            <label class="floatingLabel">{{ "Repeat password" | translate }}
              <span class="required">*</span></label>
          </div>
        </div>

        <div class="strongPassword">
          <p>{{ "Password rules" | translate }}</p>
          <ul>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['uppercase'],
                  error: !passwordValidation['uppercase']
                }">{{ passwordValidation["uppercase"] ? "" : "" }}</span>{{ "Uppercase rule" | translate }}
            </li>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['digit'],
                  error: !passwordValidation['digit']
                }">{{ passwordValidation["digit"] ? "" : "" }} </span>{{ "Digit rule" | translate }}
            </li>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['length'],
                  error: !passwordValidation['length']
                }">{{ passwordValidation["length"] ? "" : "" }}</span>{{ "Character rule" | translate }}
            </li>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['validCharacters'],
                  error: !passwordValidation['validCharacters']
                }">{{ passwordValidation["validCharacters"] ? "" : "" }}</span>{{ "Character valid" | translate }}
            </li>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['lowercase'],
                  error: !passwordValidation['lowercase']
                }">{{ passwordValidation["lowercase"] ? "" : "" }}</span>{{ "Lowercase" | translate }}
            </li>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['special'],
                  error: !passwordValidation['special']
                }">{{ passwordValidation["special"] ? "" : "" }}</span>{{ "Character special" | translate }}
            </li>

            <li *ngIf="form.controls['confirmPassword'].errors?.['mustMatch']">
              <span class="icon error"></span>{{ "Passwords not agree" | translate }}
            </li>
            <li *ngIf="!form.controls['confirmPassword'].errors?.['mustMatch']">
              <span class="icon confirmation"></span>{{ "Passwords agree" | translate }}
            </li>
          </ul>
        </div>
      </form>
      <div class="approval">
        <label class="checkboxWrapper" for="agree">
          <input type="checkbox" id="agree" [(ngModel)]="this.agreeChecked" required />

          <span class="checkmark"></span>
          <span class="description">{{ "agree" | translate
            }}<a href="info/tyc"> {{ "TyC" | translate }}</a>
            {{ "accept" | translate }}
            <a href="/info/privacy-statment">{{
              "Personal declaration" | translate
              }}</a>
            <span style="color: red">*</span></span>
        </label>
      </div>
      <div class="sendWrapper">
        <button #sendButton (click)="onSubmit()" [disabled]="disabledButton || !agreeChecked">
          {{ "Send" | translate }}
        </button>
      </div>
    </div>
  </div>
</section>
