<div id="intro">
  <figure class="hero hero--has-caption-right">
    <picture class="hero__picture">
      <img
        title=""
        width=""
        height=""
        fetchpriority=" high"
        class="hero__image"
        alt="{{ alt }}"
        src="{{ image }}"
      />
    </picture>
  </figure>
  <div class="filters">
    <div class="container">
      <div class="location-search-hero__caption">
        <h1 class="title title--1 title--bold u-mb--4">{{ title }}</h1>
        <span *ngIf="subTitle" class="area">
          {{ subTitle }}
          <span class="fecha">{{
            date | date : "dd-MMM-yyyy, HH:mm" : "UTC" : "es-Es" | uppercase
          }}</span>
        </span>
        <div class="location-search-hero__filters" *ngIf="contentTemplate">
          <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
