import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { MenusServices } from '@app/services/menus.service';
import { NotificationService } from '@app/services/notifications.service';
import { MatDialog } from '@angular/material/dialog';
import {
  FinancialProductEnum,
  financialResponseDto,
  models,
  quoteDto,
  residualValue,
  warrantyType,
} from '../../model/standard-quote.model';
import { StandardQuoteService } from '../../standard-quote-service.service';
import { TaxType } from '../../../open-quote/model/open-quote.model';
import { ModalService } from '@app/components/modal/modal.service';
import { OpenQuoteService } from '@app/pages/quote/open-quote/open-quote-service.service';
import { StandardFinancialSetupAttribute } from "@app/pages/quote/standard-quote/model/financial-setup.model";

@Component({
  selector: 'app-financial-product',
  templateUrl: './financial-product.component.html',
  styleUrls: ['./financial-product.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FinancialProductComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FinancialProductComponent),
      multi: true,
    },
  ],
})
export class FinancialProductComponent
  extends BaseComponent
  implements OnInit, ControlValueAccessor, Validator {
  public get openQuoteService(): OpenQuoteService {
    return this._openQuoteService;
  }

  public set openQuoteService(value: OpenQuoteService) {
    this._openQuoteService = value;
  }

  models: models | undefined = this.quoteService.formData?.model;
  financialService: quoteDto | null = this.quoteService.formData;

  @Input() reset: boolean;
  @Input() grace: any[];
  @Input() specialPayments: any[];
  @Input() insurance: any;
  @Input() financialCondition: any | null;

  @Output() calculate: EventEmitter<any> = new EventEmitter<any>();
  @Output() financialConditionEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() _deleteAllPayments: EventEmitter<any> = new EventEmitter<any>();
  @Output() requestDownloadJson: EventEmitter<any> = new EventEmitter<any>();
  @Output() deletePayments: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>;

  formFinance: FormGroup;

  financialPackages: financialResponseDto[] = [];
  paymentTerms: any[] = [];
  _financialPackageOptions: any[] = [];
  _financialProduct: any;

  taxType = TaxType;
  isLoading: boolean = false;
  minDownPaymentPercentage: any;
  maxDownPaymentPercentage: any;
  minDownPaymentPrice: number;
  maxDownPaymentPrice: number;
  idQuote: any;
  idDeal: any;
  change: number = 0;
  price: number;
  packageDescription: string = "";
  packageVersion: string = "";
  residualValues: residualValue[] = [];
  residualValueSelected: residualValue | null;
  warrantyType: warrantyType | null;

  constructor(
    route: ActivatedRoute,
    menu: MenusServices,
    private fb: FormBuilder,
    notification: NotificationService,
    public quoteService: StandardQuoteService,
    public _openQuoteService: OpenQuoteService,
    public dialog: MatDialog,
    private router: ActivatedRoute,
    private modalService: ModalService
  ) {
    super(route, menu, notification);
    this.createForm();
    this.residualValues = quoteService.residualValue;
  }

  //#region CONTROLS
  get downPaymentControl(): AbstractControl {
    return this.formFinance.controls['downPayment'];
  }

  get downPaymentTaxControl(): AbstractControl {
    return this.formFinance.controls['downPaymentTax'];
  }

  get downPaymentTaxTypeControl(): AbstractControl {
    return this.formFinance.controls['downPaymentTaxType'];
  }

  get financialProductControl(): AbstractControl {
    return this.formFinance.controls['financialProduct'];
  }

  get openingFeeControl(): AbstractControl {
    return this.formFinance.controls['openingFee'];
  }

  get openingFeeTypeControl(): AbstractControl {
    return this.formFinance.controls['openingFeeType'];
  }

  get openingFeeTaxControl(): AbstractControl {
    return this.formFinance.controls['openingFeeTax'];
  }

  get termIdControl(): AbstractControl {
    return this.formFinance.controls['termId'];
  }

  get nominalRateControl(): AbstractControl {
    return this.formFinance.controls['nominalRate'];
  }

  get idControl(): AbstractControl {
    return this.formFinance.controls['id'];
  }

  get calendarTypeControl(): AbstractControl {
    return this.formFinance.controls['calendarType'];
  }

  get calculationTypeControl(): AbstractControl {
    return this.formFinance.controls['calculationType'];
  }

  get paymentTypeControl(): AbstractControl {
    return this.formFinance.controls['paymentType'];
  }

  //#endregion

  ngOnInit() {
    this.price = this.getPrice();

    if (this.financialCondition)
      this.formFinance.controls['termId'].setValue(this.financialCondition.id);

    this.getFinancialPackage();
    this.nominalRateControl.disable();
    this.router.paramMap.subscribe((params) => {
      this.idQuote = params.get('id');
      this.idDeal = params.get('idDeal');
      if (!this.idDeal && !this.quoteService.duplicateDeal) {
        this.change = 1;
      }
    });
  }

  getPrice() {
    let price = 0;
    if (this.financialService?.model) {
      price = this.financialService.model.price;
      if (this.financialService.accessories) {
        this.financialService.accessories.map((i) => (price += i.price));
      }
    }
    return price;
  }

  createForm() {
    this.formFinance = this.fb.group({
      financialProduct: [, [Validators.required]],
      id: [, [Validators.required]],
      termId: [, [Validators.required]],
      financialCondition: [, [Validators.required]],
      nominalRate: [, [Validators.required]],
      downPaymentTaxType: [, [Validators.required]],
      downPayment: [, []],
      downPaymentTax: [, [Validators.required]],
      paymentType: [, [Validators.required]],
      openingFee: [, []],
      openingFeeType: [, [Validators.required]],
      openingFeeTax: [, [Validators.required]],
      calendarType: [, [Validators.required]],
      calculationType: [, [Validators.required]],
      daimlerRate: [],
      externalsMinRate: [],
      externalsMaxRate: [],
      term: [],
    });

    this.formFinance.valueChanges.subscribe((value) => {
      this.onChange(value);
      this.onTouched();
      if (
        this.idDeal &&
        (this.grace.length > 0 || this.specialPayments.length > 0 || this.insurance)
      ) {
        let fields = [
          'financialProduct',
          'id',
          'termId',
          'downPaymentTax',
          'downPayment',
          'openingFeeTax',
          'openingFee',
        ];
        fields.map((controlName) => {
          const control = this.formFinance.get(controlName);

          if (control?.dirty) {
            this.modalService.component = this.modalTemplate;
            this.modalService.openModal();
            control.markAllAsTouched();
            return;
          }
        });
      }
    });
  }

  getFinancialPackage() {
    this.isLoading = true;
    this.quoteService.getPackage().subscribe({
      next: (response) => {
        this.financialPackages = response.data;
        this.isLoading = false;
        if (
          (this.change == 0 && this.idDeal) ||
          (this.change == 0 && this.quoteService.duplicateDeal)
        ) {
          this.onChangeFinancialProduct();
          this.quoteService.duplicateDeal = null;
          this.change++;
        }
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  processNewFinancialProduct() {
    const financialProductSelected = this.financialProductControl.value;

    const validators: Record<string, any> = {
      'DIRECT_CREDIT': {
        required: ['downPaymentTaxType', 'downPaymentTax', 'downPayment'],
        remove: []
      },
      'FINANCIAL_LEASING': {
        required: ['downPaymentTaxType', 'downPaymentTax', 'downPayment'],
        remove: []
      },
      'PURE_LEASING': {
        required: [],
        remove: ['downPaymentTaxType', 'downPaymentTax', 'downPayment']
      }
    }
    const requiredFields = validators[financialProductSelected].required ?? [];
    const removedFields = validators[financialProductSelected].removed ?? [];

    this.removeRequiredValidator(removedFields);
    this.setRequired(requiredFields);
  }


  public checkModelFilter(modelTypeSearch?: string, personaTypeSearch?: string, attributeFinancialSetup?: StandardFinancialSetupAttribute[]): boolean {
    if (!modelTypeSearch) return false;
    if (!personaTypeSearch) return false;
    if (!attributeFinancialSetup) return false;
    const modelType = attributeFinancialSetup.filter(i => i.type == 'PRODUCT_TYPE').map(i => i.value).join(',');
    const personType = attributeFinancialSetup.filter(i => i.type == 'PERSON_TYPE').map(i => i.value).join(',');
    if (!modelType || !personType) return false;
    return modelType.includes(modelTypeSearch) && personType.includes(personaTypeSearch);
  }


  //Seleccion de producto financiero
  onChangeFinancialProduct() {
    if (!this.models || !this.financialService || this.models.classification == null) return;
    const financialProductSelected = this.financialProductControl.value;
    let companyName = this.financialService.companyName;
    this._financialPackageOptions = this.financialPackages
      .filter(
        (i) =>
          i.financialProduct == financialProductSelected && i.classification == this.models?.classification && i.companyName == companyName &&
          this.checkModelFilter(this.models?.modelType,
            this.quoteService.formData?.customer.personType
            , i.standardFinancialSetupAttributes)
      );
    this.packageDescription = '';

    this.processNewFinancialProduct();

    if (this.change == 0) {
      this.onChangePackage();
    } else if (this.formFinance.controls['id'].value) {
      this.setValueNull([
        'id',
        'termId',
        'financialCondition',
        'nominalRate',
        'downPaymentTaxType',
        'downPayment',
        'downPaymentTax',
        'openingFeeType',
        'openingFee',
        'openingFeeTax',
        'calendarType',
        'calculationType',
        'paymentType',
      ]);
      this.formFinance.updateValueAndValidity();
      this.deletePayments.emit()
    }
  }

  //Seleccion de poquete

  onChangePackage() {
    if (!this.idControl.value) return;
    const financialPackageId = this.idControl.value;
    let selectedPackage = this._financialPackageOptions.find(
      (i) => i.id == financialPackageId
    );
    if (selectedPackage == null) {
      this.warrantyType = null
      return;
    }
    this.warrantyType = selectedPackage.warrantyType;

    this.packageDescription = selectedPackage.financialSetupDescription;
    this.packageVersion = selectedPackage.version;
    this.calendarTypeControl.setValue('COMERCIAL');
    this.calculationTypeControl.setValue(selectedPackage.paymentFormationType);
    this.paymentTypeControl.setValue(selectedPackage.paymentType);

    this.downPaymentTaxTypeControl.setValue(selectedPackage.downPaymentTaxType);
    this.openingFeeTypeControl.setValue(selectedPackage.openingFeeType);
    this.paymentTerms = selectedPackage.financialConditions;
    this.paymentTerms.sort((a, b) => parseInt(a.term) - parseInt(b.term));

    if (this.change == 0) {
      this.onChangeTerm();
    } else {
      this.setValueNull([
        'termId',
        'financialCondition',
        'nominalRate',
        'downPayment',
        'downPaymentTax',
        'openingFee',
        'openingFeeTax',
      ]);
      this.formFinance.updateValueAndValidity();
      this.deletePayments.emit()
    }
    this.calculateDownPayment();
    this.calculateOpeningFee();
  }

  //Seleccion de plazo

  onChangeTerm() {
    const termId =  this.termIdControl.value;

    if (!termId) return;
    const term = this.paymentTerms.find((i) => i.id == termId);
    this.financialConditionEmit.emit(term);
    this.formFinance.controls['financialCondition'].setValue(term);
    this.nominalRateControl.setValue(term.nominalRate.toFixed(2));
    this.minDownPaymentPercentage = term.minDownPaymentPercentage;
    this.maxDownPaymentPercentage = term.maxDownPaymentPercentage;
    this.downPaymentTaxControl.setValue(this.minDownPaymentPercentage);
    this.openingFeeTaxControl.setValue(term.openingFeePercentage);
    this.openingFeeControl.setValue(this.calculateAmount('openingFeeTax'));

    this.selectedResidual(term.term)
    const priceModel = this.price;
    if (!priceModel) return;
    this.minDownPaymentPrice =
      priceModel * ((term.minDownPaymentPercentage * 0.01) / 100);
    this.maxDownPaymentPrice =
      priceModel * ((term.maxDownPaymentPercentage * 0.01) / 100);

    if (this.change == 0) {
      this._financialProduct = {
        financialProduct: this.financialProductControl.value,
        id: this.idControl.value,
        termId: this.termIdControl.value,
        downPaymentTax: this.downPaymentTaxControl.value,
        downPayment: this.downPaymentControl.value,
        openingFeeTax: this.openingFeeTaxControl.value,
        openingFee: this.openingFeeControl.value,
      };
    } else {
      this.deletePaymentsDeal();
    }
    this.calculateDownPayment('downPayment', 'percentaje');
    this.calculateOpeningFee('openingFeeTax', 'percentaje');
  }

  selectedResidual(term: any) {
    if (this.residualValues.length > 0) {
      let selected = this.residualValues.find((i) => i.term == term)

      if (selected) {
        this.residualValueSelected = selected
        this.formFinance.controls['daimlerRate'].setValue(selected?.daimlerRate)
        this.formFinance.controls['externalsMaxRate'].setValue(selected?.externalsMaxRate)
        this.formFinance.controls['externalsMinRate'].setValue(selected?.externalsMinRate)
        this.formFinance.controls['term'].setValue(selected?.term)
      } else {
        this.formFinance.controls['daimlerRate'].setValue(null)
        this.formFinance.controls['externalsMaxRate'].setValue(null)
        this.formFinance.controls['externalsMinRate'].setValue(null)
        this.formFinance.controls['term'].setValue(null)
        this.residualValueSelected = null;
      }
    }
  }

  calculateDownPayment(id?: string, type?: string) {
    const downPaymentTaxType = this.downPaymentTaxTypeControl.value;
    let valid = false;
    if (!downPaymentTaxType || (!this.minDownPaymentPrice && this.minDownPaymentPrice != 0) || (!this.maxDownPaymentPrice && this.minDownPaymentPrice != 0)) {
      this.setValueAndErrors(['downPaymentTax', 'downPayment']);
    }

    if (downPaymentTaxType == this.taxType.fixedAmount) {
      this.formFinance.controls['downPayment'].setValidators(
        Validators.required
      );
      this.setValueAndErrors(['downPaymentTax']);
    } else if (downPaymentTaxType == this.taxType.invoiceWithTax || downPaymentTaxType == this.taxType.invoiceWithoutTax) {
      valid = this.percentageValid();
      this.formFinance.controls['downPaymentTax'].setValidators(
        Validators.required
      );
      if (this.change != 0 && !type) this.setValueAndErrors(['downPayment']);
    }

    if (type == 'percentaje' && valid) {
      this.formFinance.controls['downPayment'].setValue(
        this.calculateAmount('downPaymentTax')
      );
    } else if (type == 'fixed') {
      this.formFinance.controls['downPaymentTax'].setValue(
        this.calcularPercentaje('downPayment')
      );
      this.hideAmountInput(id, true);
    }

    this.formFinance.updateValueAndValidity();
  }

  calculateOpeningFee(id?: string, type?: string) {
    const openingFeeType = this.openingFeeTypeControl.value;

    if (!openingFeeType) {
      this.setValueAndErrors(['openingFeeTax', 'openingFee']);
    }

    if (openingFeeType == this.taxType.fixedAmount) {
      this.setValueAndErrors(['openingFeeTax']);

      this.openingFeeControl.setValidators(Validators.required);
    } else if (openingFeeType == this.taxType.invoiceWithTax) {
      this.setValueAndErrors(['openingFee']);
      this.openingFeeTaxControl.setValidators(Validators.required);
    }

    if (openingFeeType == this.taxType.fixedAmount && id && type == 'fixed') {
      this.hideAmountInput(id, true);
    }

    if (type == 'percentaje') {
      this.openingFeeControl.setValue(this.calculateAmount('openingFeeTax'));
    } else if (type == 'fixed') {
      this.openingFeeTaxControl.setValue(this.calcularPercentaje('openingFee'));
      this.hideAmountInput(id, true);
    }
    this.formFinance.updateValueAndValidity();
  }

  percentageValid(): boolean {
    const percentaje = this.downPaymentTaxControl.value;

    if (
      !percentaje ||
      (!this.minDownPaymentPercentage && this.minDownPaymentPercentage != 0) ||
      (!this.maxDownPaymentPercentage && this.maxDownPaymentPercentage != 0)
    ) {
      return false;
    } else if (percentaje < this.minDownPaymentPercentage ||
      percentaje > this.maxDownPaymentPercentage) {
      this.downPaymentTaxControl.setValue(null);
      this.downPaymentControl.setValue(null);
      return false;
    }
    return true;
  }

  amountValid() {
    const amount = this.downPaymentControl.value;
    if (!amount || !this.minDownPaymentPrice || !this.maxDownPaymentPrice)
      return;
    if (
      amount < this.minDownPaymentPrice ||
      amount > this.maxDownPaymentPrice
    ) {
      this.downPaymentControl.setValue(null);
      this.downPaymentTaxControl.setValue(null);
    }
  }

  setRequired(fields: string[]) {
    fields.map((field) => {
      this.formFinance.controls[field].setValidators(Validators.required);
    });
  }

  removeRequiredValidator(fields: string[]) {
    fields.map((field) => {
      this.formFinance.controls[field].removeValidators(Validators.required);
    });
  }

  setValueNull(fields: string[]) {
    fields.map((field) => {
      this.formFinance.controls[field].setValue(null);
    });
  }

  setValueAndErrors(fields: string[]) {
    fields.map((field) => {
      this.formFinance.controls[field].setValue(null);
      this.formFinance.controls[field].setErrors(null);
      this.formFinance.controls[field].clearValidators();
    });
  }

  status() {
    const form = this.formFinance;
    for (const controlName of Object.keys(form.controls)) {
      const control = form.get(controlName);

      if (control && control.invalid) {
        console.error(`Campo inválido: ${controlName}`);
      }
    }
  }

  cancelChanges() {
    this.formFinance.patchValue(this._financialProduct);
    this.modalService.closeModal();
  }

  deleteAllPayments() {
    this._deleteAllPayments.emit();
    this.modalService.closeModal();
  }

  calculateAmount(typeField: string) {
    const percentaje = this.formFinance.controls[typeField].value;
    const priceModel = this.price;
    const decimalPercentaje = percentaje / 100;

    if (!priceModel || !percentaje) return;
    const amount = priceModel * decimalPercentaje;
    return amount.toFixed(2);
  }

  calcularPercentaje(typeField: string) {
    const amountFixed = this.formFinance.controls[typeField].value;
    const priceModel = this.price;

    if (!priceModel || !amountFixed) return;

    let percentaje: number | null = (amountFixed / priceModel) * 100;

    if (
      (typeField == 'downPayment' &&
        percentaje < this.minDownPaymentPercentage) ||
      percentaje > this.maxDownPaymentPercentage
    ) {
      percentaje = null;
      this.downPaymentControl.setValue(null);
    }
    return percentaje?.toFixed(2);
  }

  //#region CVA
  writeValue(value: any): void {
    if (value) {
      this.formFinance.patchValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (_: any) => {
  };
  private onTouched = () => {
  };

  //#endregion

  //#region Validator
  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    return this.formFinance.valid ? null : { invalidFormFinance: true };
  }

  //#endregion

  emitFormDataForDownload() {
    this.requestDownloadJson.emit(this.formFinance.value);
  }

  showDownPayment() {
    return [
      FinancialProductEnum.directCredit,
      FinancialProductEnum.financialLeasing
    ].includes(this.financialProductControl.value);
  }

  deletePaymentsDeal() {
    this.deletePayments.emit();
  }

}
