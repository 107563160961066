import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from '@app/services/notifications.service';
import { MustMatch } from '../../register/must-match.validator';
import { RegisterService } from '../../register/services/register.service';
import { ActivatedRoute } from '@angular/router';
import { encodingBase64 } from '@app/components/base/encodingB64';
import { AuthService } from '@app/common/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit{
  @ViewChild('sendButton', { static: false }) sendButtonRef!: ElementRef;
  @ViewChild('sendButton2', { static: false }) sendButton2Ref!: ElementRef;
  form!: FormGroup;
  formChangePassword!: FormGroup;
  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  password: string = '';
  newPassword: string = '';
  messages: string[] = [];
  isLoading: boolean = false;
  disabledButton: boolean;

  constructor(
    private fb: FormBuilder,
    private registerService: RegisterService,
    private notificationsService: NotificationService,
    private route: ActivatedRoute,
    public authService: AuthService
  ) {
    this.messages = []; 
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let update = params['uP'];
      if(update){
        this.step1 = false;
        this.step2 = true;
      }
    });
    this.initForm();
  }

  initForm(){
    
    this.form = this.fb.group({
      password: ['', Validators.required],
    });
    this.formChangePassword = this.fb.group(
      {
        newPassword: [
          '',
          [Validators.required,
          Validators.minLength(8),
          Validators.maxLength(60),
          this.passwordValidator,]
        ],
        confirmNewPassword: [''],
      },
      {
        validators: MustMatch('newPassword', 'confirmNewPassword'),
      } as AbstractControlOptions
    );
  }

  get passwordValidation(): { [key: string]: boolean } {
    const passwordControl = this.formChangePassword.get('newPassword');
    const password = passwordControl ? passwordControl.value : null;

    return {
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      special: /[^A-Za-z0-9]/.test(password),
      length: password.length >= 8,
      validCharacters: /^[A-Za-z0-9!@#$%^&*()_+\-=[\]{}|\\:;"',.<>/?]*$/.test(
        password
      ),
    };
  }

  passwordValidator(control: AbstractControl) {
    const password = control.value;

    // Comprobar si la contraseña cumple los requisitos
    const uppercaseRegex = /^(?=.*[A-Z])/;
    const lowercaseRegex = /^(?=.*[a-z])/;
    const digitRegex = /^(?=.*\d)/;
    const specialCharRegex = /^(?=.*[!@#$%^&*()_+\-=[\]{}|\\:;"',.<>/?])/;

    const isUppercaseValid = uppercaseRegex.test(password);
    const isLowercaseValid = lowercaseRegex.test(password);
    const isDigitValid = digitRegex.test(password);
    const isSpecialCharValid = specialCharRegex.test(password);

    // Validar la contraseña
    const isValid =
      isUppercaseValid &&
      isLowercaseValid &&
      isDigitValid &&
      isSpecialCharValid &&
      password.length >= 8;

    if (!isValid) {
      // Devolver un error si no se cumplen los requisitos
      return { invalidPassword: true };
    }

    return null; // La contraseña es válida
  }

  get f() {
    return this.form.controls;
  }

  get fC() {
    return this.formChangePassword.controls;
  }

  getUser(): string {
    return JSON.parse(sessionStorage.getItem('user')!);
  }

  onSubmit(): void {
    this.isLoading = true;
    this.sendButtonRef.nativeElement.disabled = true;
    this.password = encodingBase64(this.form.controls['password'].value);
    const model = {
      email: this.getUser(),
      password: this.password,
    };

    if (this.form.valid) {
      this.registerService.changePasswordStep1(model).subscribe({
        next: (response) => {
          if (response.status === true) {
            this.step1 = false;
            this.step2 = true;
            this.isLoading = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.disabledButton = true;
          this.messages.push(
            'La contraseña es incorrecta. Vuelva a intentarlo'
          );
          this.notificationsService.showMessage(this.messages, 'error');
          setTimeout(() => {
            this.messages = [];
            this.notificationsService.clearMessages();
            this.disabledButton = false;
            this.sendButtonRef.nativeElement.disabled = false;
          }, 5000);
        },
      });
    } else if (!this.form.valid) {
      this.isLoading = false;
      this.disabledButton = true;
      this.messages.push('Ingrese su contraseña para continuar');
      this.notificationsService.showMessage(this.messages, 'error');
      setTimeout(() => {
        this.messages = [];
        this.notificationsService.clearMessages();
        this.disabledButton = false;
        this.sendButtonRef.nativeElement.disabled = false;
      }, 5000);
    }
  }

  onSubmitChangePassword(): void {
    this.isLoading = true;
    this.sendButton2Ref.nativeElement.disabled = true;
    this.newPassword = encodingBase64(this.formChangePassword.controls['newPassword'].value);

    const model = {
      email: this.getUser(),
      newPassword: this.newPassword,
    };

    if (this.formChangePassword.valid) {
      this.registerService.changePasswordStep2(model).subscribe({
        next: (response) => {
          if (response.status === true) {
            this.step2 = false;
            this.step3 = true;
            this.isLoading = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.disabledButton = true;
          this.messages.push('Hubo un error. Vuelva a intentarlo');
          this.notificationsService.showMessage(this.messages, 'error');
          setTimeout(() => {
            this.messages = [];
            this.notificationsService.clearMessages();
            this.disabledButton = false;
            this.sendButton2Ref.nativeElement.disabled = false;
          }, 5000);
        },
      });
    } else if (!this.formChangePassword.valid) {
      this.isLoading = false;
      this.disabledButton = true;
      this.messages.push('Revise la composición de la contraseña ingresada');
      this.notificationsService.showMessage(this.messages, 'error');
      setTimeout(() => {
        this.messages = [];
        this.notificationsService.clearMessages();
        this.disabledButton = false;
        this.sendButton2Ref.nativeElement.disabled = false;
      }, 5000);
    }
  }
}