<app-preloader *ngIf="isLoading"></app-preloader>

<div class="modalWrapper">
  <div class="modal">
    <a class="close" (click)="onClose()"><span>Cerrar</span></a>
    <h3>{{ "New mailshot" | translate }}</h3>

    <form [formGroup]="form">
      <div class="modalContent">
        <div class="inputWrapper">
          <input
            type="text"
            placeholder=" "
            formControlName="name"
            maxlength="100"
            [ngClass]="{
              error: !form.get('name')?.valid && form.get('name')?.dirty
            }"
          />
          <label class="floatingLabel">{{ "Title" | translate }}</label>
          <ng-container
            *ngIf="
              form.get('name')?.hasError('required') && form.get('name')?.dirty
            "
          >
            <span class="alert">{{ "Required title" | translate }}</span>
          </ng-container>
        </div>

        <div class="attachWrapper">
          <label>{{ "Attach mailshot" | translate }}</label>
          <mat-form-field style="width: 100%">
            <mat-label>{{ "File" | translate }}</mat-label>
            <button mat-icon-button matPrefix (click)="f_input.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="text" readonly matInput formControlName="fileName" />
            <input
              type="file"
              multiple
              hidden
              #f_input
              (change)="onFileSelected($event)"
            />
            <mat-error>{{ "Required file" | translate }}</mat-error>
          </mat-form-field>
          <ng-container
            *ngIf="
              form.controls['mimeType'];
              hasError: 'required' && form.controls['mimeType'];
              dirty
            "
          >
            <span class="alert">{{ "Required file" | translate }}</span>
          </ng-container>
        </div>

        <div class="recipientsWrapper">
          <div class="tagsComponent">
            <label>{{ "Receiver dealers" | translate }}</label>
            <mat-form-field class="example-chip-list" appearance="fill">
              <mat-chip-grid
                #chipGridDealers
                aria-label="Seleccionar distribuidores"
              >
                <mat-chip-row
                  *ngFor="let profile of selectedOptionsDealers"
                  (removed)="remove(profile, 'dealers')"
                >
                  {{ profile.description }}
                  <button
                    matChipRemove
                    [attr.aria-label]="'remove ' + profile.description"
                  >
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </mat-chip-grid>
              <input
                placeholder="Seleccionar distribuidores"
                #dealersInput
                [matChipInputFor]="chipGridDealers"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="selectedDealers($event)"
              >
                <mat-option [value]="{ description: 'Todos' }"
                  >Todos</mat-option
                >
                <mat-option
                  *ngFor="let profile of data.dealers"
                  [value]="profile"
                >
                  {{ profile.description }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="tagsComponent">
            <label>{{ "Receiver internals" | translate }}</label>
            <mat-form-field class="example-chip-list" appearance="fill">
              <mat-chip-grid
                #chipGridInternals
                aria-label="Seleccionar personal interno"
              >
                <mat-chip-row
                  *ngFor="let profile of selectedOptionsInternals"
                  (removed)="remove(profile, 'internals')"
                >
                  {{ profile.description }}
                  <button
                    matChipRemove
                    [attr.aria-label]="'remove ' + profile.description"
                  >
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </mat-chip-grid>
              <input
                placeholder="Seleccionar personal interno"
                #internalsInput
                [matChipInputFor]="chipGridInternals"
                [matAutocomplete]="autoInternals"
              />
              <mat-autocomplete
                #autoInternals="matAutocomplete"
                (optionSelected)="selectedInernals($event)"
              >
                <mat-option [value]="{ description: 'Todos' }"
                  >Todos</mat-option
                >
                <mat-option
                  *ngFor="let profile of data.internals"
                  [value]="profile"
                >
                  {{ profile.description }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
    <div class="modalActions">
      <button (click)="onClose()" class="secondary">
        {{ "Cancel" | translate }}
      </button>
      <button [disabled]="!isEnabled" (click)="save()">
        {{ "Create" | translate }}
      </button>
    </div>
  </div>
</div>
