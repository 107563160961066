import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../services/api.base.service';
import { environment } from '@env/environment.local';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(public api: ApiService) {}

  public register(model: any): Observable<any> {
    return this.api.post(`${environment.API_USER_SERVICE}/users/signup`, model);
  }

  public changePasswordStep1(model: any): Observable<any> {
    return this.api.post(`${environment.API_USER_SERVICE}/users/password/step-one`, model);
  }

  public changePasswordStep2(model: any): Observable<any> {
    return this.api.put(`${environment.API_USER_SERVICE}/users/password/step-two`, model);
  }
}
