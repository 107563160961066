import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { InternalNotificationsService } from '../services/internal-notificacions.service';
import { BaseComponent } from '@app/components/base/base.component';
import { MenusServices } from '@app/services/menus.service';
import { AuthService } from '@app/common/auth.service';
import { queryParamsNotifications } from '../models/notifications.model';
import { NotificationService } from '@app/services/notifications.service';

@Component({
  selector: 'app-internal-notifications',
  templateUrl: './internal-notifications.component.html',
  styleUrls: ['../internal-notifications.css'],
})
export class InternalNotificationsComponent
  extends BaseComponent
  implements OnInit
{
  isLoading: boolean = false;
  notifications: any[] = [];
  notificationsSelected: any[] = [];
  selectAllItems: boolean = false;
  selectedRow: any;
  list: any[] = [];
  enabled: boolean;
  userId: any;
  enabledNotificacions: any;
  messages!: string[];

  pageSize = this.authService.getCurrentPaginators.pageSize;
  pageIndex =  this.authService.getCurrentPaginators.page;
  totalNotifications: number = 0;

  params: queryParamsNotifications = {
    page: this.pageIndex,
    pageSize: this.pageSize,
    filter: this.authService.getCurrentPaginators.filter,
  };

  selectRow(id: number) {
    this.selectedRow = id;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    route: ActivatedRoute,
    menuService: MenusServices,
    private notificationService: InternalNotificationsService,
    private notificationsService: NotificationService
  ) {
    super(route, menuService);
    this.messages = [];
  }

  ngOnInit(): void {
    this.userId = this.authService.currentUserIds;
    this.list = [];
    this.enabledNotificacions = this.authService.currentEnabledNotifications;
    this.sendDataToMenu();
    this.getNotifications();
    this.onSearchReset();
  }

  getNotifications() {
    this.isLoading = true;
    this.notificationService
      .listNotificationsUser(this.params, this.userId)
      .subscribe({
        next: (response) => {
          this.notifications = response.data;
          this.totalNotifications = response.totalElements;
          this.pageIndex = response.page - 1;
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.messages.push(
            'Hubo un error de conexión con nuestro servidor. Por favor, intente nuevamente o solicite soporte.'
          );
          this.notificationsService.showMessage(this.messages, 'error');
          setTimeout(() => {
            this.messages = [];
            this.notificationsService.clearMessages();
          }, 5000);
        },
      });
  }

  public onSearchReset = (): void => {
    this.params.filter = '';
    this.authService.setCurrentPaginators = {page: this.params.page, pageSize: this.params.pageSize,filter: ''}
    this.getNotifications();
  };

  public onSearchTextChange = (searchText: string): void => {
    this.params.filter = searchText;
    this.authService.setCurrentPaginators = {page: this.params.page, pageSize: this.params.pageSize,filter: searchText}
    this.getNotifications();
  };

  onCheckEmailNotificationChange(event: any) {
    this.isLoading = true;
    this.enabled = event.target.checked;
    const model = {
      enabled: this.enabled,
    };
    this.notificationService
      .markNotificationsByEmail(this.userId, model)
      .subscribe({
        next: (response) => {
          this.messages.push(
            'Su configuración ha sido modificada. Vuelva a iniciar sesión para ver sus cambios.'
          );
          this.notificationsService.showMessage(this.messages, 'success');
          setTimeout(() => {
            this.messages = [];
            this.notificationsService.clearMessages();
          }, 5000);
          this.isLoading = false;
        },
        error: (error) => {
          this.messages.push(
            'Ha ocurrido un error. Vuelva a intentarlo o solicite soporte.'
          );
          this.notificationsService.showMessage(this.messages, 'error');
          setTimeout(() => {
            this.messages = [];
            this.notificationsService.clearMessages();
          }, 5000);
          this.isLoading = false;
        },
      });
  }

  selectAllRows() {
    this.list = [];
    for (let item of this.notifications) {
      item.selected = !this.selectAllItems;
      if (item.selected) {
        const selectedItem = {
          notificationId: item.notificationId,
          seenByUser: item.seenByUser,
        };
        this.list.push(selectedItem);
      }
    }
  }

  onCheckboxChange(item: any) {
    if (item.selected) {
      const selectedItem = {
        notificationId: item.notificationId,
        seenByUser: item.seenByUser,
      };
      this.list.push(selectedItem);
    } else {
      const index = this.list.findIndex(
        (selectedItem) => selectedItem.id === item.notificationId
      );
      if (index !== -1) {
        this.list.splice(index, 1);
      }
    }
  }

  viewDetails(id: number) {
    const notification = this.notifications.find(
      (n) => n.notificationId === id
    );
    if (!notification.seenByUser) {
      const model = {
        list: [
          {
            notificationId: id,
            seenByUser: true,
          },
        ],
      };

      this.notificationService.markAsSeen(model).subscribe({
        next: (response) => {},
        error: (error) => {},
      });
      this.router.navigate([`notifications/details/${id}`]);
    } else {
      this.router.navigate([`notifications/details/${id}`]);
    }
  }

  markNotificationSeen() {
    this.list.forEach((notification) => {
      notification.seenByUser = true;
    });
    const requestBody = {
      list: this.list,
    };
    this.notificationService.markAsSeen(requestBody).subscribe({
      next: (response) => {
        location.reload();
      },
      error: (error) => {},
    });
  }

  markNotificationNotSeen() {
    this.list.forEach((notification) => {
      notification.seenByUser = false;
    });
    const requestBody = {
      list: this.list,
    };
    this.notificationService.markAsSeen(requestBody).subscribe({
      next: (response) => {
        location.reload();
      },
      error: (error) => {},
    });
  }

  handlePage(e: PageEvent) {
    this.authService.setCurrentPaginators = {page: e.pageIndex+1, pageSize: e.pageSize,filter: this.params.filter};
    this.params.page = e.pageIndex + 1;
    this.params.pageSize = e.pageSize;
    this.getNotifications();
  }
}
