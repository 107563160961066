<app-img-header
  [title]="'Title mailshot' | translate"
  [subTitle]="data?.name"
  [date]="data?.date"
  alt="Daimler Truck"
  image="/assets/images/marketing/intro.jpg"
  [contentTemplate]="template"
></app-img-header>

<!-- LISTADO -->
<div id="detail">
  <app-preloader *ngIf="isLoading"></app-preloader>
  <div class="pageWidth">
    <!-- Indicadores -->
    <div class="indicators circulares">
      <div>
        <span
          ><b>{{ "Recipients" | translate }}</b></span
        >
        <span>{{ data?.recipients }}</span>
      </div>
      <div>
        <span
          ><b>{{ "Downloads" | translate }}</b></span
        >
        <span>{{ data?.downloads }}</span>
      </div>
      <div>
        <span
          ><b>{{ "Pending" | translate }}</b></span
        >
        <span>{{ data?.downloadPending }}</span>
      </div>
    </div>
    <h3>{{ "Recipients" | translate }}</h3>

    <div *ngIf="!hasData" class="noData">
      {{ "No data" | translate }}
    </div>
    <!-- Tabla para listado detalle de notificaciones (versión admin)-->
    <div *ngIf="hasData" class="customTable circularesDetalle">
      <div class="header">
        <div class="cell nombre">Nombre</div>
        <div class="cell perfil">Perfil</div>
        <div class="cell distribuidor">Distribuidor</div>
        <div class="cell fecha">Fecha de descarga</div>
        <div [ngClass]="isOpen ? 'tableActions active' : 'tableActions'">
          <a class="control" (click)="openActions()">Acciones</a>
          <ul>
            <li (click)="exportToExcel()">
              <a>
                <i class="icon customIcon">
                  <img src="/assets/images/icons/export.svg" class="normal" />
                  <img
                    src="/assets/images/icons/export_hover.svg"
                    class="hover"
                  />
                </i>
                {{ "Export table" | translate }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!-- Fila con datos -->
      <div *ngFor="let item of users" class="row">
        <div class="cell nombre">
          {{ item.name }}
        </div>
        <div class="cell perfil">
          {{ getProfileName(item.profileId) }}
        </div>
        <div class="cell distribuidor">
          <app-label-dealer [dealerId]="item.dealerId"></app-label-dealer>
        </div>
        <div class="cell fecha">{{ format(item.downloadDate) }}</div>
      </div>
    </div>
    <div *ngIf="hasData">
      <app-paginator
        [total]="total"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        (changePage)="handlePage($event)"
      ></app-paginator>
    </div>

    <ng-template #template>
      <div class="wrapperG3">
        <app-input
          [data]="input[0]"
          (textSearch)="filter($event, 'user')"
          [showIcon]="true"
        ></app-input>
        <app-select
          [options]="select[1].data"
          [placeholder]="'Select one' | translate"
          (optionSelected)="filter($event, 'download')"
        ></app-select>
      </div>
    </ng-template>
  </div>
</div>
