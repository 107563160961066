import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../services/api.base.service';
import { environment } from '@env/environment.local';
import { QueryParamsUsers } from '../models/users.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(public api: ApiService) {}

  public getListUsers(): Observable<any> {
    return this.api.get(`${environment.API_USER_SERVICE}/users`);
  }

  public getUserById(id: any): Observable<any> {
    return this.api.get(`${environment.API_USER_SERVICE}/users/${id}`);
  }

  public editUser(id: any, model: any): Observable<any> {
    return this.api.patch(
      `${environment.API_USER_SERVICE}/users/${id}/admin/profile`,
      model
    );
  }

  public searchUsers(userId: any, data: QueryParamsUsers): Observable<any> {
    return this.api.get(
      `${environment.API_USER_SERVICE}/users/admin/${userId}/users?page=${data.page}&pagesize=${data.pageSize}&filter=${data.filter}`
    );
  }

  public getGroups(){
    return this.api.get(`${environment.API_DEALEARS_SERVICE}/groups`);
  }
}
