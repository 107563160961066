<section>
  <app-notifications></app-notifications>
  <app-preloader *ngIf="this.isLoading"></app-preloader>
  <ng-container *ngIf="this.formResetPassword">
    <!-- El proceso de login tiene 2 pasos -->
    <div id="login">
      <div class="wrapper">
        <h1>{{ "Forget password title" | translate }}</h1>
        <p>
          {{ "Forget password text" | translate }}
        </p>
        <form [formGroup]="form">
          <div class="inputWrapper">
            <input
              type="email"
              placeholder=" "
              formControlName="email"
              [ngClass]="{ error: form.controls['email'].hasError('email') }"
            />
            <label class="floatingLabel">Dirección de correo</label>
            <ng-container
            *ngIf="
              form.get('email')?.touched &&
              form.controls['email'].hasError('email')
            "
          >
            <span class="alert">
              Debe introducir un correo válido.
            </span>
          </ng-container>
          </div>

          <div class="sendWrapper">
            <button (click)="onSubmit()" [disabled]="!form.valid">
              {{ "Send" | translate }}
            </button>
            <a href="/"> {{ "Back to init" | translate }}</a>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</section>

<ng-container *ngIf="this.resetPasswordSent">
  <div id="login">
    <div class="wrapper">
      <h1>
        {{ "Reset password title" | translate }}
      </h1>
      <p>
        {{ "Personal data" | translate }}
        <a href="/info/privacy-statment" class="privacy">{{
          "Personal declaration" | translate
        }}</a>
        {{ "Send mail" | translate }}
        <a href="{{ 'mailto:' + email }}" class="mail">{{ email }}</a>
        {{ "Send mail 2" | translate }}
      </p>
      <a href="/">{{ "Back to init" | translate }}</a>
    </div>
  </div>
</ng-container>