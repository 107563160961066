import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup, Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StandardQuoteService } from '../standard-quote-service.service';
import { Subject } from 'rxjs';
import { AuthService } from '@app/common/auth.service';
import { BaseComponent } from '@app/components/base/base.component';
import { priceTypeEnum } from '../model/standard-quote.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from './confirmationDialog';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.css'],
})

export class ModelsComponent extends BaseComponent implements OnInit {
  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>;
  @Input() ready: Subject<boolean>;
  @Output() updateForm: EventEmitter<any> = new EventEmitter();
  @Output() setQuantity: EventEmitter<any> = new EventEmitter();
  @Output() deleteAllDeals: EventEmitter<any> = new EventEmitter();
  creationUser: number;
  hasDeals: boolean;

  form: FormGroup;
  originalValues: any;
  isLoading: boolean = false;
  modelOptions: any[] = [];
  ownerUser: boolean = false;

  modelyears: number[] = [];
  allModels: any[] = [];

  constructor(route: ActivatedRoute, public quoteService: StandardQuoteService, private fb: FormBuilder, public authService: AuthService, private dialog: MatDialog) {
    super(route);
    this.createForm();
    const idQuote = this.route?.snapshot.params['id'];
    if (!idQuote) {
      this.ownerUser = true;
    };
  }

  ngOnInit() {
    this.ready.subscribe((value: any) => {
      if (value) {
        this.isLoading = true;
        this.creationUser = value.creationUser;
        this.ownerUser = value.creationUser == this.authService.currentUserIds;
        this.hasDeals = value.hasDeals;
        this.quoteService.getModelsByFilter({ modelType: value.modelType, classification: value.classification }).subscribe({
          next: (response) => {
            this.allModels = response.data;
            this.originalValues = value;
            this.originalValues.brand = value.brand;
            this.originalValues.subBrand = value.subBrand;
            this.form.patchValue(this.originalValues);
            this.hasDeals = value.hasDeals;
            this.filterYears();
            this.filterModels();
            this.isLoading = false;
          }
        })
      }
    })
    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) this.updateForm.emit({ model: this.form.getRawValue() });
    })
  }

  createForm() {
    this.form = this.fb.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      modelCode: ['', [Validators.required]],
      modelType: ['', [Validators.required]],
      classification: ['', [Validators.required]],
      year: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      price: ['', [Validators.required]],
      priceType: [priceTypeEnum.priceNormal, [Validators.required]],
      currency: ['', [Validators.required]],
      subBrand: ['', [Validators.required]],
      brand: ['', [Validators.required]],
    });
  }

  fetchData() {
    const modelType = this.form.controls['modelType'].value;
    const classification = this.form.controls['classification'].value;

    this.isLoading = true;
    this.quoteService.getModelsByFilter({ modelType, classification }).subscribe({
      next: (response) => {
        this.allModels = response.data;
        this.filterYears();
        this.filterModels();
        this.isLoading = false;
      }
    })
  }

  filterYears(){
    const modelType = this.form.controls['modelType'].value;
    const classification = this.form.controls['classification'].value;
    this.modelyears = [...new Set(this.allModels.filter((i) => i.modelType === modelType && i.classification === classification).map((i) => i.year))].sort();
  }

  filterModels(){
    const modelType = this.form.controls['modelType'].value;
    const classification = this.form.controls['classification'].value;
    this.modelOptions = this.allModels
      .filter((i) => i.modelType === modelType && i.classification === classification)
      .map((i) => ({
        label: i.name,
        value: i.id,
        modelCode: i.modelCode,
        modelType: i.modelType,
        classification: i.classification,
        year: i.year,
      }));
  }

  cleanModelData() {
    this.form.controls['name'].setValue(null);
    this.form.controls['modelCode'].setValue(null);
    this.form.controls['price'].setValue(null);
    this.form.controls['currency'].setValue(null);
  }

  fetchDataIfChangeModelOrClassification() {
    const modelType = this.form.controls['modelType'].value;
    const classification = this.form.controls['classification'].value;

    if (modelType && classification.toString()) {
      this.fetchData()
      this.form.controls['year'].setValue(null);
      this.cleanModelData();
    }
  }

  changeModel(event: any) {
    if (!event) return;
    const modelSelected: any = this.allModels.find((i) => i.modelCode == this.form.controls['modelCode'].value);
    const dataModel = {
      id: modelSelected.id,
      modelCode: modelSelected.modelCode,
      currency: modelSelected.currency,
      price: modelSelected.price,
      brand: modelSelected.brand,
      subBrand: modelSelected.subBrand,
      name: modelSelected.name,
    };
    this.quoteService.residualValue = modelSelected?.residualModelValues
    this.form.patchValue(dataModel);
    this.changeSensibleData('name', event);
  }

  triggers: any = {
    modelType: () => {
      this.fetchDataIfChangeModelOrClassification();
    },
    classification: () => {
      this.fetchDataIfChangeModelOrClassification();
    },
    year: () => {
      const year = this.form.controls['year'].value;
      this.filterModels();
      this.modelOptions = this.modelOptions.filter((i) => i.year == year);
      this.cleanModelData();
    },
    name: () => { },
    currency: () => { },
    price: () => { },
    quantity: () => {
      this.setQuantity.emit(this.form.get('quantity')?.value);
    }
  }

  changeSensibleData(field: string, event?: any) {
    if ((field in this.triggers)) {
      if (this.hasChanges() && this.hasDeals) {
        const dialogRef = this.dialog.open(ConfirmationDialog, { width: 'auto' });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.triggers[field](event);
            this.deleteAllDeals.emit();
            this.hasDeals = false;
          } else {
            this.form.controls[field].setValue(this.originalValues[field]);
            if (field === 'name') {
              const modelSelected: any = this.allModels.find((i) => i.modelCode == this.originalValues.modelCode);
              this.form.controls['modelCode'].setValue(modelSelected.modelCode);
              this.form.controls['price'].setValue(modelSelected.price);
            }
            this.form.updateValueAndValidity({ onlySelf: true });
          }
        });
      } else {
        this.triggers[field](event);
      }
    }
  }

  hasChanges() {
    if (!this.originalValues) return false;
    return Object.keys(this.triggers).some((key: any) => this.originalValues[key] !== this.form.getRawValue()[key])
  }

  getDescriptionVehicle(modelType: any) {
    return this.quoteService.productType.find((i) => i.productType == modelType)?.description;
  }
}
