import { Component, OnInit } from '@angular/core';
import { customerDto } from '../../model/standard-quote.model';
import { StandardQuoteService } from '../../standard-quote-service.service';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.css'],
})
export class CustomerViewComponent implements OnInit {
  customer: customerDto | undefined = this.quoteService.formData?.customer;
  constructor(private quoteService: StandardQuoteService) {}

  ngOnInit() {
  }

  getTypeCustomer(personType: string | undefined): string {
    if (personType == 'LEGAL_PERSON') {
      return 'Persona moral';
    } else if (personType == 'NATURAL_PERSON') {
      return 'Persona física';
    }
    return '';
  }
}
