export class Payment {
  id: number;
  period: number;
  paymentDate: string;
  paymentType: string;
  amount: number;
  applyTo: string;

  constructor(periodSelected: any, paymentDate: string) {
    this.id = periodSelected.id;
    (this.period = periodSelected.period),
      (this.paymentDate = paymentDate),
      (this.paymentType = periodSelected.paymentType),
      (this.amount = periodSelected.amount),
      (this.applyTo = periodSelected.applyTo);
  }
}
