<app-preloader *ngIf="isLoading"></app-preloader>
<div id="unit" class="module">
  <div class="title">
    <h3>Unidad</h3>
  </div>

  <form action="" [formGroup]="form">
    <div class="wrapperG3">
      <div class="selectWrapper">
        <label for="">Tipo de unidad<span class="required">*</span></label>
        @if(ownerUser) {
        <div class="customSelect">
          <select name="" id="" formControlName="modelType" (change)="changeSensibleData('modelType')">
            <option *ngFor="let product of quoteService.productType" [value]="product.productType">
              {{ product.description }}
            </option>
          </select>
        </div>
        } @else {
        <div class="inputWrapper">
          <input type="text" disabled value="{{ getDescriptionVehicle(form.get('modelType')?.value) }}" />
        </div>
        }
      </div>
      <div class="selectWrapper">
        <label for="">Condición<span class="required">*</span></label>
        @if(ownerUser) {
        <div class="customSelect">
          <select name="" id="" formControlName="classification" (change)="changeSensibleData('classification')">
            <option [ngValue]="true">Nuevo</option>
            <option [ngValue]="false">Usado</option>
          </select>
        </div>
        } @else {
        <div class="inputWrapper">
          <input type="text" disabled value="{{ form.get('classification')?.value ? 'Nuevo' : 'Usado' }}" />
        </div>
        }
      </div>
      <div class="selectWrapper" *ngIf="modelyears.length > 0">
        <label for="">Año<span class="required">*</span></label>
        @if(ownerUser) {
        <div class="customSelect">
          <select formControlName="year" (change)="changeSensibleData('year')">
            <option [value]="null" disabled selected>Seleccione un año</option>
            <option *ngFor="let year of modelyears" [value]="year">
              {{ year }}
            </option>
          </select>
        </div>
        } @else {
        <div class="inputWrapper">
          <input type="text" disabled value="{{ form.get('year')?.value }}" />
        </div>
        }
      </div>
    </div>
    <div class="wrapperG2" *ngIf="modelOptions.length > 0 && form.controls['year'].value">
      <div class="selectWrapper" *ngIf="modelOptions.length > 0">
        <label>Modelo<span class="required">*</span></label>
        @if(ownerUser) {
        <div class="customSelect">
          <select formControlName="modelCode" (change)="changeModel($event.target)">
            <option [value]="null" disabled selected>Buscar modelo</option>
            <option *ngFor="let model of modelOptions" [value]="model.modelCode"> {{ model.label }} </option>
          </select>
        </div>
        } @else {
        <div class="inputWrapper">
          <input type="text" disabled value="{{ form.get('name')?.value }}" />
        </div>
        }
      </div>
      <div class="wrapperG4Custom">
        <div class="inputWrapper">
          <label for="">Precio unitario<span class="required">*</span></label>
          @if(ownerUser) {
          <input
            type="number"
            id="price"
            style="display: none"
            formControlName="price"
            (blur)="hideAmountInput('price')"
            (keypress)="preventInvalidCharacters($event)"
            (change)="changeSensibleData('price')"
          />
          <input
            type="text"
            id="hidden-price"
            (focus)="showAmountInput('price')"
            style="display: block"
            [value]=" form.controls['price'].value | currency : '' : '' : '0.0-8' "
          />
          } @else {
            <input type="text" id="hidden-price" style="display: block" (focus)="showAmountInput('price')" disabled [value]=" form.get('price')?.value | currency " />
          }
        </div>
        <div class="selectWrapper">
          <label for="">Moneda factura<span class="required">*</span></label>
          @if(ownerUser) {
          <div class="customSelect">
            <select formControlName="currency" (change)="changeSensibleData('currency')">
              <option *ngFor="let currency of quoteService.currencies" [value]="currency.value">
                {{ currency.type }}
              </option>
            </select>
          </div>
          } @else {
          <div class="inputWrapper">
            <input type="text" disabled value="{{ form.get('currency')?.value }}" />
          </div>
          }
        </div>
        <div class="inputWrapper">
          <label for="">Cantidad<span class="required">*</span></label>
          @if(ownerUser) {
          <input type="number" id="quantity" formControlName="quantity" (change)="triggers.quantity()" />
          } @else {
          <input type="text" disabled value="{{ form.get('quantity')?.value }}" />
          }
        </div>
        <div class="inputWrapper">
          <label for="">Total venta</label>
          <span class="readOnly">{{ (form.get('price')?.value * form.get('quantity')?.value) | currency }}</span>
        </div>
      </div>
    </div>
  </form>
</div>
