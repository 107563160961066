import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '@app/services/api.base.service';
import { environment } from '@env/environment.local';
import { queryParamsNotifications } from '../models/notifications.model';

@Injectable({
  providedIn: 'root',
})
export class InternalNotificationsService {
  private infoSubject: BehaviorSubject<any>;
  public infoNotification: Observable<any>;

  constructor(public api: ApiService) {
    this.infoSubject = new BehaviorSubject<number>(0);
    this.infoNotification = this.infoSubject.asObservable();
  }

  public listNotifications(): Observable<any> {
    return this.api.get(
      `${environment.API_NOTIFICATION_SERVICE}/notifications/`
    );
  }

  public listNotificationsUser(
    data: queryParamsNotifications,
    userId: any
  ): Observable<any> {
    return this.api.get(
      `${environment.API_NOTIFICATION_SERVICE}/notifications/users/${userId}?filter=${data.filter}&page=${data.page}&pagesize=${data.pageSize}`
    );
  }

  public getNotificationById(id: any): Observable<any> {
    return this.api.get(
      `${environment.API_NOTIFICATION_SERVICE}/notifications/${id}`
    );
  }

  public editNotification(model: any): Observable<any> {
    return this.api.post(
      `${environment.API_NOTIFICATION_SERVICE}/notifications`,
      model
    );
  }

  public deleteNotification(id: any, model: any): Observable<any> {
    return this.api.delete(
      `${environment.API_NOTIFICATION_SERVICE}/notifications/${id}`,
      model
    );
  }

  public markAsSeen(model: any): Observable<any> {
    return this.api.patch(
      `${environment.API_NOTIFICATION_SERVICE}/notifications/seen/all`,
      model
    );
  }

  public markNotificationsByEmail(userId: any, model: any): Observable<any> {
    return this.api.patch(
      `${environment.API_USER_SERVICE}/users/${userId}/enabled/notification`,
      model
    );
  }

  public get countNotification(): number {
    return this.infoSubject.value;
  }

  public notificationsWithoutSeen(userId: any) {
    return this.api
      .get(
        `${environment.API_NOTIFICATION_SERVICE}/notifications/users/not-seen/${userId}`
      )
      .subscribe((p) => {
        this.infoSubject.next(p.data.count);
      });
  }
}
