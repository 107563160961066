import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Permission } from '../models/permissions.enum';
import { PermissionService } from '../services/permission-manager.service';
import { UserType } from '../models/roles.enum';

@Directive({
  selector: '[authPermission]',
})
export class PermissionDirective implements OnInit {
  private _permissionKey!: Permission[];
  private _viewRef: EmbeddedViewRef<any> | null = null;
  private _templateRef: TemplateRef<any> | null = null;

  @Input()
  set authPermission(permission: Permission[]) {
    this._permissionKey = permission;
    this._viewRef = null;
  }

  @Input('authPermissionUser') user?: Array<UserType>;
  @Input('authPermissionRole') role?: any;
  @Input('authPermissionModule') module?: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  init() {
    if (this._permissionKey.length != 0) {
      const isPermitted = this.permission.validatePermissionKey(
        this._permissionKey
      );
      if (!isPermitted) return;

      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
    if (this.user?.length != 0) {
      const userType = this.permission.validateUserAndRoles(this.user,this.role,this.module);
      if (!userType) return;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
    return false;
  }
}
