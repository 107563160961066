import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment.local';
import { AuthService } from '../common/auth.service';
import { Permission } from '../models/permissions.enum';
import { PermissionService } from '../services/permission-manager.service';
import { generalAreas } from './models/generalAreas.model';
import { ProcotizaService } from '../services/procotiza.service';
import { NotificationService } from '../services/notifications.service';
import { BaseComponent } from '../components/base/base.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  content?: string;
  isLoading!: boolean;
  dealerSite = environment.DEALERS_SITE;
  permissions = Permission;

  generalAreas: generalAreas[] = [
    {
      label:'Portal de Clientes',
      href: 'https://www.daimlerfinancialservices.com.mx',
      image:'portal_de_clientes',
      permissions: [Permission.administracion0]
    },
    {
      label:'Campañas Marketing',
      href: `${this.dealerSite}token=${this.authService.tokenLS}&pagina=videoCampaign0`,
      image:'campanas_mkt',
      permissions: [Permission.videoCampaign0]
    },
    {
      label:'E-files Autos',
      href: `${this.dealerSite}token=${this.authService.tokenLS}&pagina=efilesAutosAnalistas1`,
      image:'e-files_autos',
      permissions: [Permission.efilesAutos0]
    },
    {
      label:'Propuesta de valor',
      href: `${this.dealerSite}token=${this.authService.tokenLS}&pagina=circulares0`,
      image:'propuesta_de_valor',      
      permissions: [Permission.propuestaValor0]
    },
    {
      label:'Circulares',
      href: '/mailshot/admin-mailshot',
      image:'circulares',
      target: '_self',
      permissions: [Permission.circulares0]
    },
  ];

  moreAccesses: generalAreas[] = [
    {
      label:'Mercedes-Benz Insurance',
      href: `${this.dealerSite}token=${this.authService.tokenLS}&pagina=seguros0`,
      image:'insurance',
      permissions: [Permission.seguros0]
    },
    {
      label:'Dealer contact center',
      href: `${this.dealerSite}token=${this.authService.tokenLS}&pagina=dealercontact0`,
      image:'',
      permissions: [Permission.dealercontact0]
    },
    {
      label:'PBL',
      href: `${this.dealerSite}token=${this.authService.tokenLS}&pagina=pbl0`,
      image:'pbl',
      permissions: [Permission.pbl0]
    },
    {
      label:'Descargas',
      href: `${this.dealerSite}token=${this.authService.tokenLS}&pagina=descargas0`,
      image:'',
      permissions: [Permission.descargas0]
    },
    {
      label:'Directorio',
      href: `/info/directory`,
      image:'directorio',
      permissions: [Permission.directorio0]
    },
    {
      label:'Loan to value',
      href: `${this.dealerSite}token=${this.authService.tokenLS}&pagina=ltv`,
      image:'',
      permissions: [Permission.ltv]
    },
  ]

  constructor(
    public authService: AuthService,
    private permissionService: PermissionService,
    private procotizaService: ProcotizaService,
    private notificationsService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.initializeAreas();
    this.initializeMoreAccesses();
  }

  initializeAreas(){
    let hasPermission: generalAreas[] = [];

    this.generalAreas.map((i)=>{
      if(this.permissionService.validatePermissionKey(i.permissions)){
        hasPermission.push(i);
      }
    });
    this.generalAreas = hasPermission;
  }

  initializeMoreAccesses(){
    let hasPermission: generalAreas[] = []
    this.moreAccesses.map((i)=>{
      if(this.permissionService.validatePermissionKey(i.permissions)){
         hasPermission.push(i);
      }
    });
    this.moreAccesses = hasPermission;
  }

  redirectionProcotiza(){
    let procotiza = this.authService.currentProcotiza;

    if (procotiza === null) {
      this.notificationsService.showMessage(['Ha ocurrido un error. Por favor contactarse con el administrador.'], 'error');
      setTimeout(() => {
        this.notificationsService.clearMessages();
      }, 3000);
      return;
    }

    let model = {
      userId: procotiza.userId,
      token: procotiza.refresh_token
    }

    this.procotizaService.refreshToken(model).subscribe({
      next: (r) => {
          if(r.Url) {
            window.location.href = r.Url;
          }else{
            this.notificationsService.showMessage([r.Mensaje], 'error');
          }
        },
      error: (_error) => {
        this.notificationsService.showMessage(['Ha ocurrido un error. Vuelva a intentarlo.'], 'error');
      }
    });
    setTimeout(() => {
      this.notificationsService.clearMessages();
    }, 3000);
  }

}
