import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InternalNotificationsComponent } from './internal-notifications/internal-notifications.component';
import { ViewInternalNotificationsComponent } from './view-internal-notifications/view-internal-notifications.component';
import { AuthGuard } from '@app/common/auth.guard';
import { InternalNotificationsStaticComponent } from './internal-notifications-static/internal-notifications-static.component';

const routes: Routes = [
  {
    path: '',
    component: InternalNotificationsComponent,
    data: {
      breadcrumb: {
        title: 'NOTIFICACIONES',
        parent: 'Listado de notificaciones',
        path: 'notifications',
      },
    },
  },
  {
    path: 'details/:id',
    component: ViewInternalNotificationsComponent,
    data: {
      breadcrumb: {
        title: 'NOTIFICACIONES',
        parent: 'Notificaciones',
        path: 'notifications',
        children: 'Detalles notificaciones',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InternalNotificationsRoutingModule {}
