<!-- DETALLE -->
<app-preloader *ngIf="this.isLoading"></app-preloader>
<main class="main" id="notificacionesInternas">
  <app-img-header
    title="Notificaciones"
    alt="Daimler Truck"
    image="/assets/images/notificaciones/intro.jpg"
  ></app-img-header>

  <div id="detail" class="white">
    <div class="pageWidth">
      <div class="notificationHeader">
        <!-- <div class="backWrapper">
          <a href="notifications" style="cursor: pointer"
            ><i class="icon"></i>Volver al listado</a
          >
        </div> -->
        <div>
          <h2>{{ title }}</h2>
          <div class="time">{{ format(creationDate) }}</div>
        </div>
      </div>

      <!-- Detalle de notificación -->
      <div class="detalleNotificacion">
        <p [innerHTML]="transformMessageToLink(message)"></p>
        <ul *ngFor="let item of attachments" class="attachements">
          <li>
            <a href="{{ item.sourceUrl }}"
              ><i class="icon"></i>{{ item.fileName }}.{{ item.extensionFile
              }}<i class="icon"></i></a
            >
          </li>
        </ul>
      </div>

      <button class="primary" [routerLink]="['/notifications']">
        Volver al listado
      </button>

      <!-- Acciones y paginador -->
      <!-- <div class="wrapperG3 actionsAndPager">
        <div></div>
        <div>
          <div class="prevAndNext">
            <a style="cursor: pointer" (click)="navigateToPrevious()"><i class="icon"></i> Ant.</a>
            <a style="cursor: pointer" (click)="navigateToNext()">Sgte. <i class="icon"></i></a>
          </div>
        </div>
        <div></div>
      </div> -->
    </div>
  </div>
</main>
