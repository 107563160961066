import { Injectable } from '@angular/core';
import { ApiService } from './api.base.service';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment.local';

@Injectable({
  providedIn: 'root'
})
export class ProcotizaService {

constructor(public api: ApiService) { }

  public refreshToken(data: any): Observable<any> {
    return this.api.post(`${environment.API_PROCOTIZA}`,data);
  }
  
}
