import {formatDate} from "@angular/common";

export class Util {

  public static showInsuranceVehicle(deal: any) {
    if (deal.insurance && deal.insurance.packageList && deal.insurance.packageList.amount) {
      return deal.insurance.packageList.amount;
    }
    return 0;
  }

  formatDateTable(date: any){
    if(date) return formatDate(date,'dd-MMM-yyyy','es-Es')?.toUpperCase();
    return '';
  }
}

export const allowedActions = [
  {allowPaymentDay: 'Permitir día de pago'},
  {allowCapitalGrace: 'Permitir gracia de capital'},
  {allowInterestGrace: 'Permitir interés de gracia de capital'},
  {allowOpeningFee: 'Modificar comisión por apertura'}
]
