import { Component, Input, OnInit } from '@angular/core';
import {
  insurance,
  insuranceEnum,
  quoteConceptDto,
} from '../../model/standard-quote.model';
import { StandardQuoteService } from '../../standard-quote-service.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css'],
})
export class ResultComponent implements OnInit {
  @Input() financialProduct: string;
  @Input() quoteResult: quoteConceptDto;
  @Input() coverageResult: any;

  quantity: number;

  isLoading: boolean = false;
  insuranceEnum = insuranceEnum;
  constructor(private quoteService: StandardQuoteService) {}

  ngOnInit() {
    if (this.quoteService.formData)
      this.quantity = Number(this.quoteService.formData?.model.quantity);
  }

  save() {}

  showAmount(insurance: insurance) {
    if (!insurance) return 0;
    if (!insurance.packageList) return 0;
    return insurance.packageList.amount;
  }
}
