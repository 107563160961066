import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './common/auth.service';
import { animate, style, transition, trigger } from '@angular/animations';

const enterTransition = transition(':enter', [
  style({
    opacity: 0
  }),
  animate(
    '1s ease-in',
    style({
      opacity: 1,
    })
  ),
]);

const leaveTrans = transition(':leave', [
  style({
    opacity: 1,
  }),
  animate(
    '1s ease-out',
    style({
      opacity: 0,
    })
  ),
]);

const fadeIn = trigger('fadeIn', [enterTransition]);

const fadeOut = trigger('fadeOut', [leaveTrans]);
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  animations: [fadeIn, fadeOut],
})
export class AppComponent implements OnInit {
  isLoggedIn = false;
  isMenuOpen = false;
  private lastActivityTime: number = 0;
  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private authService: AuthService
  ) {
    translate.setDefaultLang('es');
    this.resetLastActivityTime();
    this.setActivityListener();
    this.checkInactivity();
  }

  ngOnInit(): void {}

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnDestroy() {
    document.removeEventListener(
      'mousemove',
      this.resetLastActivityTime.bind(this)
    );
    document.removeEventListener(
      'keydown',
      this.resetLastActivityTime.bind(this)
    );
  }

  resetLastActivityTime() {
    this.lastActivityTime = Date.now();
  }

  setActivityListener() {
    document.addEventListener(
      'mousemove',
      this.resetLastActivityTime.bind(this)
    );
    document.addEventListener('keydown', this.resetLastActivityTime.bind(this));
  }

  checkInactivity() {
    setInterval(() => {
      const currentTime = Date.now();
      const inactiveTime = currentTime - this.lastActivityTime;
      const maxInactiveTime = 90 * 60 * 1000;

      if (inactiveTime > maxInactiveTime) {
        this.authService.logout();
      }
    }, 60000);
  }
}
