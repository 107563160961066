import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {defineLocale, esLocale} from 'ngx-bootstrap/chronos';
import {BsLocaleService} from "ngx-bootstrap/datepicker";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {

  @Input() label: string;
  @Input() selectedDate: Date | undefined ;
  @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() hasError: boolean | undefined;

  constructor(    private bsLocaleService: BsLocaleService
  ) {     defineLocale('es', esLocale);
    this.bsLocaleService.use('es');}


  ngOnInit() {
   // this.selectedDate = new Date();
  }

  ngOnChanges() {
    if (this.selectedDate) {
      this.selectedDate = new Date(this.selectedDate);
    }
  }

  onDateChange(newDate: Date) {
    this.selectedDate = new Date(newDate);
    if (!isNaN(this.selectedDate.getTime())) {
      this.selectedDateChange.emit(this.selectedDate); // Emite el evento con la nueva fecha
    } else {
      console.error('Invalid date:', newDate);
    }


  }

  clearDate(event?:any) {
    event?.stopPropagation();
    this.selectedDate = undefined;
    this.selectedDateChange.emit(this.selectedDate); // Emite el evento con la nueva fecha
  }
}
