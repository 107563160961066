import { formatDate } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Permission } from '@app/models/permissions.enum';
import {
  RolesDealer,
  RolesInternal,
  UserType,
} from '@app/models/roles.enum';
import { MenusServices } from '@app/services/menus.service';
import { NotificationService } from '@app/services/notifications.service';
import { log } from '@angular-devkit/build-angular/src/builders/ssr-dev-server';

@Component({
  template: '',
})
export class BaseComponent {
  constructor(
    public route?: ActivatedRoute,
    private menuService?: MenusServices,
    private notification?: NotificationService,
    private element?: ElementRef
  ) {}

  permission = Permission;
  userType = UserType;
  rolesDealer = RolesDealer;
  rolesInternal = RolesInternal;

  msgFailedConnection = [
    'Hubo un error de conexión con nuestro servidor. Por favor, intente nuevamente o solicite soporte.',
  ];
  msgBadRequest =
    'Ha ocurrido un error. Por favor, intente nuevamente o solicite soporte.';

  genders: any[] = [
    { value: 'F', label: 'Femenino' },
    { value: 'M', label: 'Masculino' },
  ];
  sendDataToMenu() {
    this.menuService?.setBreadcrumb(this.route?.snapshot.data);
    return this.route?.snapshot.data['module'];
  }

  passwordValidator(control: AbstractControl) {
    const password = control.value;

    // Comprobar si la contraseña cumple los requisitos
    const uppercaseRegex = /^(?=.*[A-Z])/;
    const lowercaseRegex = /^(?=.*[a-z])/;
    const digitRegex = /^(?=.*\d)/;
    const specialCharRegex = /^(?=.*[!@#$%^&*()_+\-=[\]{}|\\:;"',.<>/?])/;

    const isUppercaseValid = uppercaseRegex.test(password);
    const isLowercaseValid = lowercaseRegex.test(password);
    const isDigitValid = digitRegex.test(password);
    const isSpecialCharValid = specialCharRegex.test(password);

    // Validar la contraseña
    const isValid =
      isUppercaseValid &&
      isLowercaseValid &&
      isDigitValid &&
      isSpecialCharValid;

    if (!isValid) {
      // Devolver un error si no se cumplen los requisitos
      return { invalidPassword: true };
    }

    return null; // La contraseña es válida
  }

  dateOfBirthValidator(
    control: FormControl
  ): { [key: string]: boolean } | null {
    const selectedDate: Date = control.value;
    console.log(selectedDate)

    if (control.pristine) {
      return null; // No se muestra el mensaje hasta que el campo sea tocado
    }

    const today = new Date();
    const birthYear = selectedDate.getFullYear();
    const birthMonth = selectedDate.getMonth();
    const birthDay = selectedDate.getDate();

    const age = today.getFullYear() - birthYear;

    if (
      age > 18 ||
      (age === 18 && today.getMonth() > birthMonth) ||
      (age === 18 && today.getMonth() === birthMonth && today.getDate() >= birthDay)
    ) {
      return {invalidDate: true};
    } else {
      return {invalidDate: false};
    }
  }

  getDate(form: FormGroup) {
    if (
      !form.controls['dateOfBirth'].value ||
      form.controls['dateOfBirth'].value == ''
    ) {
      return '';
    }

    let date = form.controls['dateOfBirth']?.value['_i'];
    let fechaFormateada: Date;

    if (date && date['year']) {
      return (fechaFormateada = new Date(
        parseInt(date['year']),
        parseInt(date['month']),
        parseInt(date['date'])
      ));
    } else {
      const [dia, mes, anio] = date.split('/');
      return (fechaFormateada = new Date(
        parseInt(anio),
        parseInt(mes) - 1,
        parseInt(dia)
      ));
    }
  }

  mobile(): boolean {
    return window.matchMedia('only screen and (max-width: 800px)').matches;
  }

  private markErrors(form: FormGroup) {
    for (const key in form.controls) {
      form.controls[key].markAsDirty();
      form.controls[key].updateValueAndValidity();
    }
  }

  public validForm(form: FormGroup): boolean {
    let isValid = true;
    for (const key in form.controls) {
      if (form.controls[key].invalid) {
        const invalidControl = this.element?.nativeElement.querySelector(
          '[formcontrolname="' + key + '"]'
        );

        if (invalidControl) {
          invalidControl.focus();
        }

        this.markErrors(form);

        return false;
      }
    }

    return true;
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url: string = window.URL.createObjectURL(data);
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  saveAsPdf(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/pdf' });
    const url: string = window.URL.createObjectURL(data);
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  downloadFile(newFile: File, nameFile: string, url: string) {
    const link = document.createElement('a');
    link.setAttribute('download', nameFile);
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(link.href);
  }

  format(date: any, minutes?: boolean) {
    if (minutes) {
      if (date) return formatDate(date, 'dd-MMM-yyyy', 'es-Es')?.toUpperCase();
      return '';
    } else {
      if (date)
        return formatDate(date, 'dd-MMM-yyyy, HH:mm', 'es-Es')?.toUpperCase();
      return '';
    }
  }

  showNotificationError(message: string) {
    this.notification?.showMessage([message], 'error');

    setTimeout(() => {
      this.notification?.clearMessages();
    }, 5000);
  }

  showNotificationSuccess(message: string) {
    this.notification?.showMessage([message], 'success');

    setTimeout(() => {
      this.notification?.clearMessages();
    }, 5000);
  }

  formatInputValue(inputValue: any) {
    const numericValue = parseFloat(inputValue);

    if (!isNaN(numericValue)) {
      return numericValue.toLocaleString('es-MX', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return;
    }
  }

  unformatInputValue(number: string): number {
    const price = number.toString();
    const isWithCommaAndDot = price.match(/,/);

    if (isWithCommaAndDot) {
      const sinCentavos = price.replace('.00', '');
      const sinComas = sinCentavos.replaceAll(',', '');
      return parseFloat(sinComas);
    } else {
      return parseInt(number);
    }
  }

  hideAmountInput(id?: string, applyDelay?: boolean) {
    if (!id) return;

    if (applyDelay) {
      setTimeout(() => {
        (
          document.getElementById(`hidden-${id}`) as HTMLInputElement
        ).style.display = 'block';
        let input = document.getElementById(id) as HTMLInputElement;
        input.style.display = 'none';
      }, 50);
      return;
    }

    (
      document.getElementById(`hidden-${id}`) as HTMLInputElement
    ).style.display = 'block';
    let input = document.getElementById(id) as HTMLInputElement;
    input.style.display = 'none';
  }

  showAmountInput(id: string) {
    if (id == null) return;
    (
      document.getElementById(`hidden-${id}`) as HTMLInputElement
    ).style.display = 'none';
    let input = document.getElementById(id) as HTMLInputElement;
    input.style.display = 'block';
    input.focus();
  }

  transformDescription(description: string): string {
    return (
      description.charAt(0).toUpperCase() + description.slice(1).toLowerCase()
    );
  }

  formatDateMonthAndYear(date: any) {
    if (date) return formatDate(date, 'MMM-yyyy', 'es-Es')?.toUpperCase();
    return '';
  }
  formatDateDayAndMonthAndYear(date: any) {
    if (date) return formatDate(date, 'dd-MMM-yyyy', 'es-Es')?.toUpperCase();
    return '';
  }

  preventInvalidCharacters(event: any) {
    const reg = new RegExp(/[0-9.]/);
    if (!reg.test(event.key)) {
      event.preventDefault();
    }
  }
}
