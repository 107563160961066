import { OnInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { TokenStorageService } from '../../common/token-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@env/environment.local';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.css'],
})
export class FooterComponent implements OnInit {
  @ViewChild('templateConfirmation') template!: TemplateRef<any>;
  private roles: string[] = [];
  isLoggedIn = false;
  isMenuOpen = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  email?: string;
  title = 'DFSM – Portal de Distribuidores';
  mail ="ana.a.lopez@daimlertruck.com";
  environment = environment;
  showModal: boolean = false;

  constructor(
    private tokenStorageService: TokenStorageService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();


    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;
      this.email = user.email;
    }

  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  openModalHelp() {
    this.modalService.component = this.template;
    this.modalService.openModal();
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }
}
