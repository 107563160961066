import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonModule } from '@app/components/app.common.module';
import { QuoteRoutingModule } from './quote-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ListQuoteStandardComponent } from './standard-quote/list-quote-standard/list-quote-standard.component';
import { CustomerComponent } from './standard-quote/client/customer.component';
import { FinancialProductComponent } from './standard-quote/deals-standard-quote/financial-product/financial-product.component';
import { InsuranceFlexComponent } from './standard-quote/insurance/insurance-flex/insurance-flex.component';
import { InsuranceComponent } from './standard-quote/insurance/insurance.component';
import { ResultComponent } from './standard-quote/deals-standard-quote/result/result.component';
import { NewStandardQuoteComponent } from './standard-quote/new-standard-quote.component';
import { ModelsComponent } from './standard-quote/models/models.component';
import { AccessoriesComponent } from './standard-quote/accessories/accessories.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NewDealStandardQuoteComponent } from './standard-quote/deals-standard-quote/new-deal-standard-quote/new-deal-standard-quote.component';
import { DealsStandardQuoteComponent } from './standard-quote/deals-standard-quote/deals-standard-quote.component';
import { RowDealStandardQuoteComponent } from './standard-quote/deals-standard-quote/row-deal-standard-quote/row-deal-standard-quote.component';
import { FinancialServiceStandardComponent } from './standard-quote/financial-service-standard/financial-service-standard.component';
import { CustomerViewComponent } from './standard-quote/read-only/customer-view/customer-view.component';
import { ColumnDealStandardComponent } from './standard-quote/deals-standard-quote/column-deal-standard/column-deal-standard.component';
import { ListSpecialPaymentsGracePeriodComponent } from './standard-quote/deals-standard-quote/list-special-payments-grace-period/list-special-payments-grace-period.component';
import { QuoteResultComponent } from './standard-quote/deals-standard-quote/result/quote-result/quote-result.component';
import { CoverageResultComponent } from './standard-quote/deals-standard-quote/result/coverage-result/coverage-result.component';
import { FinancialServiceViewComponent } from './standard-quote/read-only/financial-service-view/financial-service-view.component';
import { ModelsViewComponent } from './standard-quote/read-only/models-view/models-view.component';
import { AccessoryComponent } from './standard-quote/accessories/accessory/accessory.component';
import { PaymentsStandardQuoteComponent } from './standard-quote/deals-standard-quote/payments-standard-quote/payments-standard-quote.component';
import { ViewDealStandardQuoteComponent } from './standard-quote/deals-standard-quote/view-deal-standard-quote/view-deal-standard-quote.component';
import { AccessoriesListComponent } from './standard-quote/read-only/accessories-list/accessories-list.component';
import { AccessoryViewComponent } from './standard-quote/read-only/accessories-list/accessory-view/accessory-view.component';
import {TippyDirective} from "@ngneat/helipopper";
@NgModule({
  imports: [
    TippyDirective,
    CommonModule,
    FormsModule,
    AppCommonModule,
    QuoteRoutingModule,
    MatTabsModule,
    TranslateModule,
    MatOptionModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatDialogModule,
  ],
  declarations: [
    ListQuoteStandardComponent,
    NewStandardQuoteComponent,
    CustomerComponent,
    FinancialProductComponent,
    InsuranceComponent,
    InsuranceFlexComponent,
    ResultComponent,
    ListQuoteStandardComponent,
    ModelsComponent,
    AccessoriesComponent,
    NewDealStandardQuoteComponent,
    DealsStandardQuoteComponent,
    RowDealStandardQuoteComponent,
    ViewDealStandardQuoteComponent,
    PaymentsStandardQuoteComponent,
    FinancialServiceStandardComponent,
    CustomerViewComponent,
    ColumnDealStandardComponent,
    ListSpecialPaymentsGracePeriodComponent,
    CoverageResultComponent,
    QuoteResultComponent,
    FinancialServiceViewComponent,
    ModelsViewComponent,
    AccessoryComponent,
    AccessoriesListComponent,
    AccessoryViewComponent,
  ],
})
export class QuoteModule {}
