import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailshotComponent } from './mailshot/mailshot.component';
import { MailshotRoutingModule } from './mailshot-routing.module';
import { MailshotStaticComponent } from './mailshot static/mailshot-static.component';
import { AppCommonModule } from '@app/components/app.common.module';
import { ModalMailshotComponent } from './modal-mailshot/modal-mailshot.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetailsMailshotComponent } from './details-mailshot/details-mailshot.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    MailshotComponent,
    MailshotStaticComponent,
    ModalMailshotComponent,
    DetailsMailshotComponent,
  ],
  imports: [
    AppCommonModule,
    CommonModule, 
    MailshotRoutingModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    TranslateModule
  ],
})
export class MailshotModule {}
