import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {Router} from '@angular/router';
import {RegisterService} from './services/register.service';
import {MustMatch} from './must-match.validator';
import {NotificationService} from '../../services/notifications.service';
import {emailDomainValidator} from './emailDomain.validator';
import {Observable} from 'rxjs/internal/Observable';
import {ApiService} from '@app/services/api.base.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {EntitiesServices} from '@app/services/entities.service';
import {BaseComponent} from '@app/components/base/base.component';
import {encodingBase64} from '@app/components/base/encodingB64';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent extends BaseComponent implements OnInit {

  @ViewChild('sendButton', {static: false}) sendButtonRef!: ElementRef;

  form: FormGroup;
  agreeChecked: boolean = false;
  dealers: any[] = [];
  profiles: any[] = [];

  filteredOptions!: Observable<string[]>;

  value: any;
  isLoading = false;
  isLoggedIn = false;
  isLoginFailed = false;
  errors = [];
  errorMessage = [];
  isSuccessful = false;
  isSignUpFailed = false;
  messages!: string[];
  selectedDealerId!: number;
  selectedProfileName!: string;
  submitted: boolean = false;
  hideErrors: boolean = true;
  disabledButton: boolean;

  constructor(
    private registerService: RegisterService,
    private notificationsService: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService,
    private entitiesService: EntitiesServices,
    private el: ElementRef
  ) {
    super();
    this.messages = [];
    this.form = this.fb.group(
      {
        firstName: ['', [Validators.required]],
        mothersLastName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        telephoneNumber: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
        dealerId: ['', [Validators.required, this.includedInOptionsValidator.bind(this)]],
        profileId: ['', [Validators.required]],
        dateOfBirth: ['', [Validators.required, this.validator]],
        gender: ['', [Validators.required]],
        email: ['', [Validators.email, Validators.required, emailDomainValidator]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(60),
            this.passwordValidator,
          ],
        ],
        confirmPassword: [''],
      },
      {
        validators: MustMatch('password', 'confirmPassword'),
      } as AbstractControlOptions
    );
  }



  includedInOptionsValidator(
    control: FormControl
  ): { [key: string]: any } | null {
    const selectedOption = control.value;
    const isValid = this.dealers.some(
      (dealer) => dealer.value === selectedOption
    );
    return isValid ? null : {invalidOption: true};
  }

  get passwordValidation(): { [key: string]: boolean } {
    const passwordControl = this.form.get('password');
    const password = passwordControl ? passwordControl.value : null;

    return {
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      special: /[^A-Za-z0-9]/.test(password),
      length: password.length >= 8,
      validCharacters: /^[A-Za-z0-9!@#$%^&*()_+\-=[\]{}|\\:;"',.<>/?]*$/.test(
        password
      ),
    };
  }

  get f() {
    return this.form.controls;
  }

  public isValidForm(form: FormGroup): boolean {
    let isValid = true;
    for (const key in form.controls) {
      if (form.controls[key].invalid) {
        const invalidControl = this.el?.nativeElement.querySelector(
          '[formcontrolname="' + key + '"]'
        );

        if (invalidControl) {
          invalidControl.focus();
        }

        this.markErrorsInControls(this.form);

        return false;
      }
    }
    if (!isValid) {
      // Mostrar los mensajes de error en el HTML y resaltar los campos inválidos
      this.submitted = true;
      this.hideErrors = false;
    } else {
      // Restablecer la visibilidad de los mensajes de error
      this.submitted = false;
      this.hideErrors = true;
    }
    return true;
  }

  ngOnInit(): void {
    this.entitiesService.listDealers().subscribe({
      next: (response) => {
        this.dealers = response.data.map((d: any)=> {return{label: d.name, value: d.id}}//llama el dato para poder seleccionarlo
      )
      },
      error: (error) => {
      },
    });
    this.entitiesService.listProfiles().subscribe({
      next: (response) => {
        console.log(response);
        this.profiles = response.data.map((i: any)=> {return{label: i.description, value: i.id}}
        )
      },
      error: (error) => {
      },
    });
  }


  displayFn(dealers: any): string {
    return dealers ? dealers.name : '';
  }

  getModel() {
    return {
      firstName: this.form.controls['firstName'].value,
      mothersLastName: this.form.controls['mothersLastName'].value,
      lastName: this.form.controls['lastName'].value,
      telephoneNumber: this.form.controls['telephoneNumber'].value,
      gender: this.form.controls['gender'].value,
      dateOfBirth: this.form.controls['dateOfBirth'].value,
      profileId: this.form.controls['profileId'].value,
      dealerId: this.form.controls['dealerId'].value,
      email: this.form.controls['email'].value,
      password: encodingBase64(this.form.controls['password'].value),
    };
  }

  private markErrorsInControls(form: FormGroup) {
    for (const key in form.controls) {
      form.controls[key].markAsDirty();
      form.controls[key].updateValueAndValidity();
    }
  }

  /*funcion para usar con materials
  onDealerSelected(event: MatAutocompleteSelectedEvent) {
    const selectedDealer = this.dealers.find(
      (dealer) => dealer.name === event.option.value
    );
    this.selectedDealerId = selectedDealer.id;
  }*/

  onSubmit(): void {
    const model = this.getModel();
    console.log(this.form);
    this.isLoading = true;
    this.sendButtonRef.nativeElement.disabled = true;

    if (this.agreeChecked) {
      if (!this.isValidForm(this.form)) {
        this.submitted = true;
        this.markErrorsInControls(this.form);
        this.messages.push(
          'Revisa los campos obligatorios antes de continuar.'
        );
        this.notificationsService.showMessage(this.messages, 'error');
        setTimeout(() => {
          this.messages = [];
          this.notificationsService.clearMessages();
          this.disabledButton = false;
          this.sendButtonRef.nativeElement.disabled = false;
        }, 5000);
        this.isLoading = false;
      }

      if (this.form.valid) {
        this.registerService.register(model).subscribe({
          next: (r) => {
            this.isLoading = false;
            console.log("register response ", r);
            if (r.status) {
              this.router.navigate(['/register-confirmation']).then(() => {
                window.location.reload();
              });
            } else {
              this.errors = r.errors;
              this.messages = this.getMessageError(this.errors);
              this.notificationsService.showMessage(this.messages, 'error');
              setTimeout(() => {
                this.messages = [];
                this.notificationsService.clearMessages();
                this.disabledButton = false;
                this.sendButtonRef.nativeElement.disabled = false;
              }, 5000);
            }
          },

          error: (error) => {
            this.messages = [];
            this.errors = error.error.errors;
            this.messages = this.getMessageError(this.errors);
            this.notificationsService.showMessage(this.messages, 'error');
            setTimeout(() => {
              this.messages = [];
              this.notificationsService.clearMessages();
              this.disabledButton = false;
              this.sendButtonRef.nativeElement.disabled = false;
            }, 5000);
            this.isLoading = false;
          },
        });
      }
    } else if (this.agreeChecked == false) {
      this.messages.push('Revisa los campos obligatorios antes de continuar.');
      this.notificationsService.showMessage(this.messages, 'error');
      setTimeout(() => {
        this.messages = [];
        this.notificationsService.clearMessages();
        this.disabledButton = false;
        this.sendButtonRef.nativeElement.disabled = false;
      }, 5000);
      this.isLoading = false;
    }
  }

  getMessageError(errors: string[]): string[] {
    let messages: string [] = [];
    Object.values(errors).forEach((error) => {
      if (error === 'R01') {
        messages.push('El nombre es un dato obligatorio');
      } else if (error == 'R02') {
        messages.push(
          'El apellido materno y paterno son datos obligatorios'
        );
      } else if (error == 'R03') {
        messages.push(
          'La fecha de nacimiento es un dato obligatorio'
        );
      } else if (error == 'R04') {
        messages.push('El perfil es un dato obligatorio');
      } else if (error == 'R05') {
        messages.push('El email/usuario es un dato obligatorio');
      } else if (error == 'R06') {
        messages.push(
          'El email/usuario ingresado ya está registrado'
        );
      } else if (error == 'R07') {
        messages.push('La contraseña es un dato obligatorio');
      } else if (error == 'R08') {
        messages.push('El distribuidor es un dato obligatorio');
      } else if (error == 'R09') {
        messages.push('El sexo es un dato obligatorio');
      } else if (error == 'R10') {
        messages.push('El teléfono es un dato obligatorio');
      }
    });
    return messages;
  }


  validator(
    control: FormControl
  ): { [key: string]: boolean } | null {
    const selectedDate: Date = control.value;
    if (!selectedDate) {
      return null; // No se muestra el mensaje hasta que el campo sea tocado
    }

    const today = new Date();
    const birthYear = selectedDate.getFullYear();
    const birthMonth = selectedDate.getMonth();
    const birthDay = selectedDate.getDate();

    const age = today.getFullYear() - birthYear;
    if (
      age > 18 ||
      (age == 18 && today.getMonth() > birthMonth) ||
      (age == 18 && today.getMonth() == birthMonth && today.getDate() >= birthDay)
    ) {
      return null;
    } else {
      return {invalidDate: true};
    }
  }
}
