import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenusServices {
  breadcrumb: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() {}

  setBreadcrumb(data: any) {
    this.breadcrumb.next(data);
  }
}
