import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../common/auth.service';

@Component({
  selector: 'app-label-dealer',
  templateUrl: './label-dealer.component.html',
  styleUrls: ['./label-dealer.component.css'],
})
export class LabelDealerComponent implements OnInit {
  dealers: any[] = [];
  selectedDealerName!: string;
  @Input() dealerId!: number;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.dealers = this.authService.currentDealers;
    this.selectedDealerName = this.getNameById(this.dealerId);
  }

  getNameById(id: number): any {
    if (!this.dealers) return;
    const dealer = this.dealers.find((dealer) => dealer.id === this.dealerId);
    return dealer ? dealer.name : '';
  }
}
