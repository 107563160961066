<div id="unit" class="module readOnly" *ngIf="model">
  <h3>Unidad</h3>
  <div>
    <div class="wrapperG3">
      <div>
        <label>Tipo de unidad</label>
        <span class="value">{{ getModel(model.modelType) }}</span>
      </div>
      <div>
        <label>Condición</label>
        <span class="value">{{ getClassification(model.classification) }}</span>
      </div>
      <div>
        <label>Año</label>
        <span class="value">{{ model.year }}</span>
      </div>
    </div>
    <div class="wrapperG2">
      <div>
        <label>Modelo</label>
        <span class="value">{{ model.name }}</span>
      </div>
      <div class="wrapperG4Custom">
        <div>
          <label>Precio</label>
          <span class="value">{{ model.price | currency : "" : "" }}</span>
        </div>
        <div>
          <label>Moneda factura</label>
          <span class="value">{{ model.currency }}</span>
        </div>
        <div>
          <label>Cantidad</label>
          <span class="value">{{ model.quantity }}</span>
        </div>
        <div>
          <label>Total venta</label>
          <span class="value">{{ model.price | currency }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
