import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { ApiService } from '@app/services/api.base.service';
import { environment } from '@env/environment.local';
import { queryParamsMailshot } from './model/mailshot.model';

@Injectable({
  providedIn: 'root'
})
export class MailshotService {

  select = [
    {
      data: [
        {label:'2023',value: 2023},
        {label:'2022',value: 2022},
        {label:'2021',value: 2021},
        {label:'2020',value: 2020},
      ],
      placeholder: 'Buscar año',
      filterType: 'year'
    },
    {
      data: [
        {label:'Todas',value: null},
        {label:'Sin descargar',value: false},
        {label:'Descargadas',value: true},
      ],
      placeholder: 'Selecciona una',
      filterType: 'download'
    },
  ]

  constructor(public api: ApiService) { }
  
  //#region MAILSHOT-SERVICE
  public createMailshot(data:any): Observable<any> { // crear circular
    return this.api.post(`${environment.API_MAILSHOT_SERVICE}/mailshot`,data)
  }

  public listMailshot(data: queryParamsMailshot): Observable<any> {
    const queryParams = [];

    if (data.page) {
      queryParams.push(`page=${data.page}`);
    }
  
    if (data.pageSize) {
      queryParams.push(`pagesize=${data.pageSize}`);
    }
  
    if (data.year) {
      queryParams.push(`year=${data.year}`);
    }
  
    if (data.isDownload || data.isDownload == false) {
      queryParams.push(`downloadedByUser=${data.isDownload}`);
    }
  
    if (data.description) {
      queryParams.push(`filter=${data.description}`);
    }
  
    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
  
    const url = `${environment.API_MAILSHOT_SERVICE}/mailshot/me${queryString}`;
  
    return this.api.get(url);
  }

  public listMailshotAll(data: queryParamsMailshot): Observable<any> {
    const queryParams = [];

  if (data.page) {
    queryParams.push(`page=${data.page}`);
  }

  if (data.pageSize) {
    queryParams.push(`pagesize=${data.pageSize}`);
  }

  if (data.year) {
    queryParams.push(`year=${data.year}`);
  }

  if (data.description) {
    queryParams.push(`filter=${data.description}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

  const url = `${environment.API_MAILSHOT_SERVICE}/mailshot${queryString}`;

  return this.api.get(url);
  }

  public markAsDownloaded(id: number): Observable<any> { //Marcar como visto 
    return this.api.patch(`${environment.API_MAILSHOT_SERVICE}/mailshot/${id}/download/me`);
  }

  public getDetailsMailshot(id: number){
    return this.api.get(`${environment.API_MAILSHOT_SERVICE}/mailshot/${id}`);
  }

  public deleteMailshot(id:any){
    return this.api.delete(`${environment.API_MAILSHOT_SERVICE}/mailshot/${id}`,false);
  }

  public sendNotifications(data: any,id: any){
    return this.api.put(`${environment.API_MAILSHOT_SERVICE}/mailshot/notify/${id}`,data);
  }
  //#endregion


  //#region FILE-SERVICE
  getAttachmentById(id:number){
    return this.api.get(`${environment.API_FILE_SERVICE}/file/${id}`)
  }

  getAllAttachment(){ //obtener todos los archivos
    return this.api.get(`${environment.API_FILE_SERVICE}/file`)
  }

  createAttachment(data: any){ //crear adjunto mandar por form data
    return this.api.post(`${environment.API_FILE_SERVICE}/file`,data)
  }

  getAttachmentByElementId(id: number){ 
   return this.api.get(`${environment.API_FILE_SERVICE}/file/elements/${id}`)
  };

  //#endregion
}
