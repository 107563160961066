export enum RolesDealer {
    seller = 'ROLE_SELLER',
    financial = 'ROLE_FINANCIAL',
    manager = 'ROLE_MANAGER', // Gerente de ventas
    administrative = 'ROLE_ADMINISTRATIVE',
}

export enum RolesInternal{
    administrator = 'ADMINISTRATOR',
    readOnly = 'READ_ONLY',
    analyst = 'ANALYST',
    supervisor = 'SUPERVISOR'
}

export enum UserType{
    dealer = 'DEALER',
    internal = 'INTERNAL'
}

