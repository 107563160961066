import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {
  modelTypeEnum,
  models,
  priceTypeEnum,
} from '../../model/standard-quote.model';
import { StandardQuoteService } from '../../standard-quote-service.service';

@Component({
  selector: 'app-models-view',
  templateUrl: './models-view.component.html',
  styleUrls: ['./models-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelsViewComponent implements OnInit {
  private quoteService = inject(StandardQuoteService);
  model: models | undefined = this.quoteService.formData?.model;

  constructor() {}

  ngOnInit() {}

  getClassification(classification: boolean) {
    if (classification) {
      return 'NUEVO';
    } else if (!classification) {
      return 'USADO';
    } else {
      return '';
    }
  }

  getModel(modelType: modelTypeEnum) {
    if (modelType == modelTypeEnum.accesorios) {
      return 'Accesorios';
    } else if (modelType == modelTypeEnum.autobuses) {
      return 'Autobuses';
    } else if (modelType == modelTypeEnum.camiones) {
      return 'Camiones';
    } else if (modelType == modelTypeEnum.tractos) {
      return 'Tractos';
    }
    return '';
  }

  getPriceType(priceType: priceTypeEnum) {
    return 'Precio valor comercial';
  }
}
