/** @enum: Permisos de roles*/
export enum Permission {
  administracion0 = 'administracion0',
  configurarCorreos1 = 'configurarCorreos1',
  paginasRubro1 = 'paginasRubro1',
  usuarios1 = 'usuarios1',
  cargas0 = 'cargas0',
  perfiles1 = 'perfiles1',
  circulares0 = 'circulares0',
  cotizador0 = 'cotizador0',
  descargas0 = 'descargas0',
  directorio0 = 'directorio0',
  efiles0 = 'efiles0',
  credito1 = 'credito1',
  operaciones1 = 'operaciones1',
  remarketing1 = 'remarketing1',
  expedientesAutos0 = 'expedientesAutos0',
  pbl0 = 'pbl0',
  planPiso0 = 'planPiso0',
  estadoCuenta1 = 'estadoCuenta1',
  inventario1 = 'inventario1',
  tasas1 = 'tasas1',
  transferencias1 = 'transferencias1',
  propuestaValor0 = 'propuestaValor0',
  bonos1 = 'bonos1',
  desembolsos1 = 'desembolsos1',
  incentivos1 = 'incentivos1',
  pagos1 = 'pagos1',
  salir0 = 'salir0',
  seguros0 = 'seguros0',
  subastas0 = 'subastas0',
  unidadesReposeidas0 = 'unidadesReposeidas0',
  ventasSeguroContado0 = 'ventasSeguroContado0',
  facturaelectronica1 = 'facturaelectronica1',
  cotizadorviano1 = 'cotizadorviano1',
  cotizadorvc1 = 'cotizadorvc1',
  expedientesAutosViano = 'expedientesAutosViano',
  mbfRewards = 'mbfRewards',
  fap0 = 'fap0',
  series0 = 'series0',
  dealercontact0 = 'dealercontact0',
  remarketing0 = 'remarketing0',
  faqrmk0 = 'faqrmk0',
  segurosContadoYFinanciados1 = 'segurosContadoYFinanciados1',
  ventasSegurosContadoYFinanciados1 = 'ventasSegurosContadoYFinanciados1',
  carrocerias0 = 'carrocerias0',
  ltv = 'ltv',
  loyalty0 = 'loyalty0',
  beneficios1 = 'beneficios1',
  certificados1 = 'certificados1',
  estadisticas1 = 'estadisticas1',
  parametros1 = 'parametros1',
  aprobarCertificados1 = 'aprobarCertificados1',
  certificadoManual1 = 'certificadoManual1',
  incentivoSeguros0 = 'incentivoSeguros0',
  consultarIncentivos1 = 'consultarIncentivos1',
  genesys0 = 'genesys0',
  gen_solic_cred1 = 'gen_solic_cred1',
  gen_consul_cred1 = 'gen_consul_cred1',
  adm_crit_preaprob1 = 'adm_crit_preaprob1',
  gen_solic_exp1 = 'gen_solic_exp1',
  mon_exp_gen1 = 'mon_exp_gen1',
  solic_buro_credito1 = 'solic_buro_credito1',
  renovaciones1 = 'renovaciones1',
  admDirectorioDealers1 = 'admDirectorioDealers1',
  sol_exp_dist1 = 'sol_exp_dist1',
  reg_solic_dist1 = 'reg_solic_dist1',
  genesysDealerCredit0 = 'genesysDealerCredit0',
  reg_solic_dist_autos1 = 'reg_solic_dist_autos1',
  video_tutoriales1 = 'video_tutoriales1',
  goContract1 = 'goContract1',
  gen_cons_pend1 = 'gen_cons_pend1',
  operacionesTAB = 'operacionesTAB',
  dealercontactAutos0 = 'dealercontactAutos0',
  efilesAutos0 = 'efilesAutos0',
  efilesAutosDetalle1 = 'efilesAutosDetalle1',
  efilesAutosBandeja1 = 'efilesAutosBandeja1',
  efilesAutosAnalistas1 = 'efilesAutosAnalistas1',
  reporteTiempos1 = 'reporteTiempos1',
  gen_invest_credito1 = 'gen_invest_credito1',
  smConsultaPago = 'smConsultaPago',
  smRegistroPago = 'smRegistroPago',
  smConsultaPagosEmpleado = 'smConsultaPagosEmpleado',
  smSeleccionUnidades2 = 'smSeleccionUnidades2',
  smSeleccionUnidadesDetalle = 'smSeleccionUnidadesDetalle',
  smRegistroPagoResumen = 'smRegistroPagoResumen',
  smRegistroPagoResumenDetalle = 'smRegistroPagoResumenDetalle',
  smRegistroPago2 = 'smRegistroPago2',
  tableau = 'tableau',
  emailActivityLoyalty = 'emailActivityLoyalty',
  loyaltyCampaign1 = 'loyaltyCampaign1',
  videoCampaign0 = 'videoCampaign0',
  birthdayCampaign1 = 'birthdayCampaign1',
  endContractCampaign1 = 'endContractCampaign1',
  cotizadorAbierto = 'Cot_abierto1',
  cotizadorStandard = 'contizadorStandard',
  procotiza = 'Cot_anterior1',
}
