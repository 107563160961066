import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'confirmation-dialog',
  template: '<div class="modal" style="max-width: 620px">' +
            ' <a class="close" (click)="close()" >Cerrar</a> '+
            ' <h1>Eliminar propuestas</h1> '+
            ' <p> Está editando datos sensibles, si continúa con los cambios todas las propuestas generadas anteriormente serán eliminadas. </p> ' +
            ' <div class="modalActions index"> '+
            '   <button (click)="close()" class="secondary">Cancelar</button> '+
            '   <button (click)="confirm()" class="primary">Eliminar</button> '+
            ' </div> '+
            '</div>',
  styleUrls: ['../new-standard-quote.component.css'],
})

export class ConfirmationDialog {

  constructor(private dialogRef: MatDialogRef<ConfirmationDialog>) { }


  close() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(true);
  }

}
