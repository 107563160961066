import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {

  @Input() data: any;
  @Output() textSearch: EventEmitter<any> = new EventEmitter();
  @Input() showIcon?: boolean;
  @Input() resetInput?: boolean;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['resetInput']){
      this.reset();
    }
  }

  ngOnInit() {
  }

  search(){
    if(this.data.value != '') this.textSearch.emit(this.data.value);
  }

  searchChange(){
    if(!this.showIcon) {
      if(this.data.value.length >= 3) this.textSearch.emit(this.data.value);
    }
  }

  reset(){
    this.data.value = '';
    this.textSearch.emit(this.data.value);
  }

}
