import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternalNotificationsComponent } from './internal-notifications/internal-notifications.component';
import { AppCommonModule } from '@app/components/app.common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InternalNotificationsRoutingModule } from './internal-notifications-routing.module';
import { ViewInternalNotificationsComponent } from './view-internal-notifications/view-internal-notifications.component';
import { InternalNotificationsStaticComponent } from './internal-notifications-static/internal-notifications-static.component';

@NgModule({
  declarations: [
    InternalNotificationsComponent,
    ViewInternalNotificationsComponent,
    InternalNotificationsStaticComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    InternalNotificationsRoutingModule,
  ],
})
export class InternalNotificationsModule {}
