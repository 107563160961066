<main class="main" id="circulares">
  <!-- INTRO -->
  <!-- <app-img-header [title]="Circulares de marketing" alt="Daimler Truck" image="/assets/images/marketing/intro"></app-img-header> -->

  <!-- INTRO -->
  <app-img-header
    [title]="'Title mailshot' | translate"
    alt="Daimler Truck"
    image="/assets/images/marketing/intro.jpg"
    [contentTemplate]="template"
  ></app-img-header>

  <!-- LISTADO -->
  <div id="detail">
    <app-preloader *ngIf="isLoading"></app-preloader>
    <app-notifications></app-notifications>
    <!-- LISTADO -->
    <div class="pageWidth">
      <div class="wrapperCustomActions">
        <div class="actionsWrapper">
          <button
            class="tertiary"
            [disabled]="isDisabled"
            (click)="openModal()"
          >
            <i class="icon"></i>
            {{ "New mailshot" | translate }}
          </button>
        </div>
      </div>

      <div *ngIf="!hasData" class="noData">
        {{ "No data" | translate }}
      </div>

      <div
        *ngIf="!isMobile && hasData"
        [ngClass]="
          role != rolesInternal.administrator
            ? 'customTable circulares'
            : 'customTable circulares admin'
        "
      >
        <div class="header">
          <div class="cell titulo">Título</div>
          <div class="cell fecha">Fecha</div>
          <div class="cell adjunto">Adjunto</div>
          <div class="cell destinatarios">Destinatarios</div>
          <div class="cell descargas">Descargas</div>
          <div class="cell pendientes">Pendientes</div>
          <div class="cell acciones"></div>
          <!-- Componente con acciones generales de la tabla-->
          <!-- Incluir después del último div.cell del header -->
          <!-- Al hacer clic en a.control incluir la clase active en div.tableActions -->
        </div>

        <div *ngFor="let item of data" [ngClass]="getTableRowClass(item)">
          <div class="cell titulo">
            {{ item.name }}
          </div>
          <div class="cell fecha">{{ format(item.date) }}</div>
          <div class="cell adjunto">
            <a
              (click)="
                downloadAttachment(item.id, item.name, item.date, item.mimeType)
              "
              class="download"
              tp="Descargar"
              >{{ getType(item.mimeType) }} ({{ item.fileSize | fileSize }})</a
            >
          </div>
          <div class="cell destinatarios">
            {{ item.recipients }}
          </div>
          <div class="cell descargas">
            {{ item.downloads }}
          </div>
          <div class="cell pendientes">
            {{ item.downloadPending }}
          </div>
          <div class="cell acciones">
            <a
              [routerLink]="['/mailshot/admin-mailshot/details/' + item.id]"
              tp="Ver circular"
              class="view"
            >
              Ver
            </a>

            <a
              (click)="deleteMailshot(item.id)"
              tp="Eliminar circular"
              class="delete"
            >
              Eliminar
            </a>

            <!-- <a
              appTooltip="Editar usuario"
              *ngIf="profile.id != 5"
              href="/users/admin-users/edit-users/{{ item.id }}"
              class="edit"
              >Editar</a
            > -->

            <!-- <a [routerLink]="['/mailshot/admin-mailshot/details/' + item.id]">
              <i class="icon customIcon">
                <img src="/assets/images/icons/eye.svg" class="normal" />
                <img src="/assets/images/icons/eye_hover.svg" class="hover" />
              </i>
            </a> -->
          </div>
        </div>
      </div>

      <div *ngIf="isMobile && hasData">
        <app-mailshot-mobile
          [listMailshot]="data"
          [role]="role"
          [module]="module"
          [hasData]="hasData"
          (download)="downloadMobile($event)"
        ></app-mailshot-mobile>
      </div>

      <div *ngIf="hasData">
        <app-paginator
          [total]="total"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          (changePage)="handlePage($event)"
        ></app-paginator>
      </div>

      <div class="actionsWrapper">
        <button class="tertiary" [disabled]="isDisabled" (click)="openModal()">
          <i class="icon"></i>
          {{ "New mailshot" | translate }}
        </button>
      </div>
    </div>
  </div>
</main>
<ng-template #template>
  <div class="wrapperG3">
    <app-input
      [data]="input[0]"
      (textSearch)="filter($event, 'description')"
      [showIcon]="true"
    ></app-input>
    <app-select
      [options]="select[0].data"
      [placeholder]="'Search year' | translate"
      (optionSelected)="filter($event, 'year')"
    ></app-select>
    <app-select-search
      *ngIf="role != rolesInternal.administrator"
      [options]="select[1].data"
      [placeholder]="'Select one' | translate"
      (searchSelected)="filter($event, 'download')"
    ></app-select-search>
  </div>
</ng-template>
